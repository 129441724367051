<div class="uf-box">
    <div class="uf-app-bar accent">
        <div class="title">
            Details
        </div>
    </div>
    <div [formGroup]="form" class="uf-grid pad">
        <uf-data-display-list [items]="detailsKeyValue" class="small-label col-12" />
        <uf-text [formControlName]="controlKeys.Title" label="Title" class="col-12" />
        <uf-text [formControlName]="controlKeys.ConsoleName" [label]="consoleNameLabel" class="col-12" />
        <uf-text [formControlName]="controlKeys.Identifier" label="Identifier" class="col-12" />
        <uf-textarea [formControlName]="controlKeys.Description" label="Description" class="col-12">
            <uf-help
                content="Description is displayed in the Console only. Add notes to remember table set up and purpose." />
        </uf-textarea>
        <uf-markdown-editor [formControlName]="controlKeys.Help" label="Help Text" class="col-12">
            <uf-help content="Help Text is displayed to end users." />
        </uf-markdown-editor>
        <uf-checkbox *ngIf="form.get(controlKeys.ShowCount)?.enabled" [formControlName]="controlKeys.ShowCount"
            label="Show Count" class="col-12" />
        <uf-checkbox *ngIf="form.get(controlKeys.HideExport)?.enabled" [formControlName]="controlKeys.HideExport"
            label="Export" class="col-12" />
        <ng-container *ngIf="form.get(controlKeys.ExportVisibleTo); let exportVisibleToControl">
            <uf-chips
                *ngIf="form.get(controlKeys.HideExport)?.enabled && form.get(controlKeys.HideExport)?.value === true"
                [control]="exportVisibleToControl | asUfControl" [options]="filteredRoles"
                (searchChange)="filterRoles($event)" label="Export roles" class="col-12" />
        </ng-container>
    </div>
</div>