import { Component, HostBinding, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb, TabComponent, TabsComponent } from '@unifii/library/common';
import { UfError, isUfError } from '@unifii/sdk';
import { Observable } from 'rxjs';

import { UcTable } from 'client';
import { EditData, SaveAndApprove, SaveAndClose } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { TitleService } from 'services/title.service';

import { TableInfo, tableResolver } from './table-resolver';

@Component({
    selector: 'uc-table',
    templateUrl: './table.html',
})
export class TableComponent implements EditData, OnInit {

    @HostBinding('class.stretch-component') stretchClass = true;

    info: TableInfo;

    protected error?: UfError;

    private breadcrumbs: Breadcrumb[];
    private route = inject(ActivatedRoute);
    private breadcrumbService = inject(BreadcrumbService);
    private builderHeaderService = inject(BuilderHeaderService);
    private titleService = inject(TitleService);
    private _tabsComponent: TabsComponent | undefined;

    get edited() {
        return !!this.builderHeaderService.config.edited;
    }

    set edited(v: boolean) {
        this.builderHeaderService.config.edited = v;
    }

    @ViewChild(TabsComponent, { static: false }) set tabsComponent(v: TabsComponent | undefined) {
        this._tabsComponent = v;
        if (v) {
            this.updateTitle();
        }
    }

    get tabsComponent() {
        return this._tabsComponent;
    }

    ngOnInit() {
        const resolverData = this.route.snapshot.data.info as Exclude<Awaited<ReturnType<typeof tableResolver>>, Observable<unknown>>;

        if (isUfError(resolverData)) {
            this.error = resolverData;

            return;
        } else {
            this.info = resolverData;
        }

        this.builderHeaderService.init();
        this.buildHeaderConfiguration(resolverData.table);
    }

    updateTable(table: UcTable) {
        this.info.table = table;

        this.buildHeaderConfiguration(table);
    }

    protected updateTitle() {
        const currentTab = this.tabsComponent?.tabs.find((tab: TabComponent) => tab.active);

        if (currentTab) {
            this.titleService.updateTitle(`${currentTab.label} | ${this.info.table.consoleName ?? this.info.table.title}`, true);
        }
    }

    private buildHeaderConfiguration(table: UcTable) {
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [table.consoleName || 'New']);
        this.builderHeaderService.buildConfig({
            ...table,
            edited: this.edited,
            breadcrumbs: this.breadcrumbs,
            saveOptions: [SaveAndClose, SaveAndApprove],
            cancelRoute: ['../'],
        });
    }

}
