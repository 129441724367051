<uf-panel class="content-pane">
    <div class="uf-container-xl">
        <div class="uf-grid row-gap-lg pad-top">
            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Details
                    </div>
                    <button *ngIf="isPublisher && schemaSetting.publishState !== publishStates.Draft"
                        (click)="updateSettingsPublishState(publishStates.Draft)" class="uf-button secondary"
                        type="button">
                        Unapprove
                    </button>
                    <button *ngIf="isPublisher && schemaSetting.publishState === publishStates.Draft"
                        (click)="updateSettingsPublishState(publishStates.Approved)" class="uf-button secondary"
                        type="button">
                        Approve
                    </button>
                </div>

                <div class="uf-grid pad">
                    <uf-data-display-list class="col-12">
                        <ng-container>
                            <dt>Form Data Repository</dt>
                            <dd>{{schema.bucket}}</dd>
                        </ng-container>
                        <ng-container>
                            <dt>Forms</dt>
                            <dd>{{ forms }}</dd>
                        </ng-container>
                        <ng-container>
                            <dt>Has Rolling Version</dt>
                            <dd>{{schema.hasRollingVersion ? 'True' : 'False'}}</dd>
                        </ng-container>
                    </uf-data-display-list>
                </div>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Schema
                        <uf-drop-menu [options]="menuOptions" (selected)="onSort($event)" title="Sort By"
                            class="uf-action gap-sm-bottom gap-sm-top" icon="sort" />
                    </div>
                </div>

                <div class="uf-grid">
                    <table [class.clickable]="!readOnly" class="col-12 small uf-table">
                        <thead>
                            <tr>
                                <th>Identifier</th>
                                <th>Label</th>
                                <th>Short Label</th>
                                <th>Type</th>
                                <th>Searchable</th>
                                <ng-template ufFeatureFlag="reporting">
                                    <th>Reportable</th>
                                </ng-template>

                            </tr>
                        </thead>
                        <tbody [root]="entries" ufDirectoryRoot childrenProperty="fields">
                            <tr *ngFor="let entry of entries | flatten: 'fields'" [item]="entry"
                                (click)="!readOnly && edit(entry)" ufDirectory>
                                <td class="no-padding">
                                    <uf-directory-indicator [icon]="entry.icon"
                                        class="light"><code>{{entry.identifier}}</code></uf-directory-indicator>
                                </td>
                                <td>{{ entry.label }}</td>
                                <td>{{ entry.shortLabel }}</td>
                                <td>
                                    {{ entry.type }}
                                </td>
                                <td>
                                    <span
                                        *ngIf=" formControl.get([entry.path])?.get(formKeys.IsSearchable) as control; else notCompatible"
                                        [class.success]="control.value" class="uf-lozenge">{{control.value ? 'True' :
                                        'False' }}</span>
                                </td>
                                <ng-template ufFeatureFlag="reporting">
                                    <td>
                                        <span
                                            *ngIf="formControl.get([entry.path])?.get(formKeys.IsReportable) as control; else notCompatible"
                                            [class.success]="control.value" class="uf-lozenge">{{control.value ? 'True'
                                            : 'False' }}
                                        </span>
                                    </td>
                                </ng-template>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <uf-blockquote *ngIf="error" [content]="error.message" class="error" icon="errorSolid" />
            </div>
        </div>
    </div>
</uf-panel>

<ng-template #notCompatible>
    <span class="light">-</span>
</ng-template>