import { TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { RequestAnalyticsOrigin } from '@unifii/sdk';

import { ApiKey, UcAPIKeys } from 'client';

export class APIKeysDataSource extends TableDataSource<ApiKey> {

    filtered = false;
    sorted = false;

    constructor(private ucAPIKeys: UcAPIKeys) {
        super();
    }

    load(options?: TableDataSourceQueryOptions) {
        this.ucAPIKeys.get({
            params: { offset: options?.offset, limit: options?.limit },
            analytics: { origin: RequestAnalyticsOrigin.Table },
        }).then((items) => {
            this.stream.next({ data: items });
        }, (error) => {
            this.stream.next({ error });
        });
    }

}
