import { Directive, HostListener } from '@angular/core';

import { DragItemDirective } from './drag-item.directive';

// This directive registers itself with DragItem as a valid target
// DragItem on dragstart checks for valid targets if any
@Directive({
    selector: '[dragHandle]',
})
export class DragHandleDirective {

    constructor(private dragItemDirective: DragItemDirective) {
        dragItemDirective.hasHandle = true;
    }

    @HostListener('mousedown', ['$event'])
    mouseDown() {
        this.dragItemDirective.handleUsed = true;
    }

}
