import { Component, HostBinding, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { ModalService, ToastService } from '@unifii/library/common';
import { ensureUfError } from '@unifii/sdk';

import { ApiKey, UcAPIKeys } from 'client';

import { APIKeysTableManager } from './api-keys-table-manager';
import { NewApiKeyModalComponent } from './new-api-key-modal.component';

@Component({
    selector: 'uc-api-keys',
    template: `
        <div ufMasterDetail>
            <uf-table-container (addItem)="addItem()" class="accent list-md pad-none" />
        </div>
        <router-outlet />
    `,
    providers: [
        { provide: TableContainerManager, useClass: APIKeysTableManager },
    ],
})
export class APIKeysComponent {

    @HostBinding('class.stretch-component') stretchComponentClass = true;

    newApiKey: ApiKey | null;

    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private modalService = inject(ModalService);
    private toastService = inject(ToastService);
    private ucAPIKeys = inject(UcAPIKeys);
    private tableManager = inject<APIKeysTableManager>(TableContainerManager);

    protected async addItem() {
        const apiKeyItem = await this.modalService.openMedium(NewApiKeyModalComponent);

        if (!apiKeyItem) {
            return;
        }

        try {
            const apiKey: ApiKey = {
                consoleName: apiKeyItem.consoleName,
                createdBy: undefined as any,
                createdAt: undefined as any,
            };

            if (apiKeyItem.enableOAuth) {
                apiKey.enableOAuth = apiKeyItem.enableOAuth;
            }

            this.newApiKey = await this.ucAPIKeys.save(apiKey);
            this.toastService.success('API Key added');
            this.tableManager.reload.next();
            void this.router.navigate([this.newApiKey.key], { relativeTo: this.route });

        } catch (error) {
            this.toastService.error(ensureUfError(error).message);
        }
    }

}
