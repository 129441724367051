"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttachmentsClient = void 0;
const constants_1 = require("../constants");
/**
 * Client for v0/projects/:id/attachments
*/
class AttachmentsClient {
    constructor(client, options) {
        this.client = client;
        this.options = options;
    }
    /**
     * Generate the attachment download URL
     * @param attachmentId
     * @param options
     */
    async getAttachmentUrl(attachmentId, options) {
        const response = await this.client.get(this.url(attachmentId), options);
        return response.url;
    }
    url(...extra) {
        const parts = [constants_1.APIPath.Projects, this.options.projectId];
        if (this.options.preview) {
            parts.push(constants_1.APIPath.Preview);
        }
        parts.push(constants_1.APIPath.Attachments);
        return this.client.buildUrl(...parts, ...extra);
    }
}
exports.AttachmentsClient = AttachmentsClient;
