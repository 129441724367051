import { AfterViewInit, ChangeDetectorRef, Component, inject } from '@angular/core';
import { CompoundType, isStringNotEmpty } from '@unifii/sdk';

import { UcProject } from 'client';
import { ContentList, ModalSearchType } from 'components/content/content-list';
import { LinkSearchComponent, LinkSearchConfig, LinkSearchType } from 'components/content/modals/link-search.component';

@Component({
    selector: 'uc-link-list',
    templateUrl: './link-list.html',
    styleUrls: ['./group-input.less'],
    providers: [{ provide: ModalSearchType, useValue: LinkSearchComponent }],
})
export class LinkListComponent extends ContentList implements AfterViewInit {

    protected searchConfig: LinkSearchConfig;

    private ucProject = inject(UcProject);
    private cdr = inject(ChangeDetectorRef);

    override ngAfterViewInit() {
        super.ngAfterViewInit();

        let name: string | null = null;

        if (this.builderService.collections && this.configuredField.definitionIdentifier) {
            name = this.builderService.collections.find((c) => c.identifier === this.configuredField.definitionIdentifier)?.name;
        }

        this.searchConfig = {
            title: 'Add ' + name + ' Item',
            multiSelect: true,
            ucProject: this.ucProject,
            type: LinkSearchType.Compound,
            compoundType: CompoundType.Collection,
            types: [this.configuredField.definitionIdentifier as string],
            ignore: this.getIgnoreList(),
        };

        this.cdr.detectChanges();
    }

    get collectionLabel(): string | undefined {

        if (this.configuredField.label) {
            return this.configuredField.label;
        }

        if (this.builderService.collections && this.configuredField.definitionIdentifier) {
            const label = this.builderService.collections.find((c) => c.identifier === this.configuredField.definitionIdentifier).consoleName;

            return isStringNotEmpty(label) ? label : undefined;
        }

        return undefined;
    }

    protected getLink(link: any) {
        if (link?.definitionIdentifier) {
            return ['/projects', this.ucProject.options.projectId, 'content', 'collections', link.definitionIdentifier, link.id];
        }

        return [''];
    }

    protected getSearchConfig(): LinkSearchConfig {
        this.searchConfig.ignore = this.getIgnoreList();

        return this.searchConfig;
    }

    protected override convertResultToContent(item: any): any {
        return {
            id: item.id,
            type: item.definitionIdentifier,
            definitionIdentifier: item.definitionIdentifier,
            _consoleName: item._consoleName,
        };
    }

    private getIgnoreList(): string[] {
        if (!Array.isArray(this.content)) {
            return [];
        }

        return this.content.map((item) => item.id);
    }

}
