<div *ngIf="form && !error" class="header-pane uf-app-bar transparent">
    <uc-builder-header />
</div>

<uf-panel *ngIf="!error" class="content-pane">
    <div class="uf-container-lg">

        <uf-skeleton *ngIf="!form" />

        <div *ngIf="form" [formGroup]="form">
            <div class="uf-box gap-top">
                <div class="uf-app-bar flat accent">
                    <div class="title">Details</div>
                </div>
                <div class="uf-grid pad">
                    <uf-text [formControlName]="controlKeys.ConsoleName" [label]="consoleNameLabel" class="col-12">
                        <span class="suffix">*</span>
                    </uf-text>
                </div>
            </div>

            <div class="uf-box gap-top">
                <div class="uf-app-bar flat accent">
                    <div class="title">Source</div>
                </div>
                <div class="uf-grid pad">
                    <uf-select [options]="sourceTypes" [formControlName]="controlKeys.SourceType" label="Source"
                        placeholder="Select Source" valueProperty="identifier" class="col-12">
                        <span class="suffix">*</span>
                    </uf-select>
                    <uf-autocomplete *ngIf="form.get(controlKeys.Bucket)?.enabled"
                        [formControlName]="controlKeys.Bucket" [options]="buckets"
                        (searchChange)="searchBuckets($event)" (valueChange)="bucketChange($event)"
                        label="Form Data Repository" placeholder="Search form data repository" nameProperty="name"
                        class="col-12">
                        <span class="suffix">*</span>
                    </uf-autocomplete>
                </div>
            </div>

            <div class="uf-box gap-top">
                <div class="uf-app-bar flat accent">
                    <div class="title">Target</div>
                    <a *ngIf="featureLink" [routerLink]="featureLink" target="_blank" title="Open"
                        class="uf-action tertiary">
                        <uf-icon name="open" />
                    </a>
                </div>
                <div class="uf-grid pad">
                    <uf-autocomplete [options]="integrations" [formControlName]="controlKeys.Integration"
                        (searchChange)="searchIntegration($event)" (valueChange)="integrationChange($event)"
                        placeholder="Select an integration" nameProperty="name" label="Integration" class="col-12">
                        <span class="suffix">*</span>
                    </uf-autocomplete>
                    <uf-autocomplete *ngIf="form.get(controlKeys.Feature)?.enabled" [options]="filteredFeatures"
                        [formControlName]="controlKeys.Feature" (searchChange)="searchFeature($event)"
                        (valueChange)="featureChange($event)" placeholder="Select a feature" nameProperty="name"
                        label="Feature" class="col-12">
                        <span class="suffix">*</span>
                    </uf-autocomplete>
                </div>
            </div>

            <div *ngIf="targetFields" class="uf-box gap-top">
                <div class="uf-app-bar flat accent">
                    <div class="title">Data Mapping</div>
                </div>
                <div class="uf-grid pad">
                    <uf-radio [formControlName]="controlKeys.ForwardAllData" [options]="dataMappingOptions" columns="1"
                        valueProperty="value" class="col-12" />
                    <uc-field-mapping-table *ngIf="inputMapControl" [control]="inputMapControl"
                        [targetFields]="targetFields" [sourceFields]="sourceFields" class="col-12" />
                </div>
            </div>
        </div>
    </div>
</uf-panel>

<uc-error-message [error]="error" />