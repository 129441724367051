import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, ModalService, SharedTermsTranslationKey, StorageWrapper } from '@unifii/library/common';
import { OAuthWithCode, OAuthWithPassword, OAuthWithVirtualMfa, UfError, UfRequestError, ensureUfError, getErrorType, isDictionary, isOAuthWithCode, isOAuthWithPassword, isOAuthWithVirtualMfa } from '@unifii/sdk';
import { StatusCodes } from 'http-status-codes';

import { MyAccount, UcClient } from 'client';
import { PREVIEW_FORM_STORAGE_KEY } from 'constant';

import { ContextService } from './context.service';
import { TenantSettingsService } from './tenant-settings.service';

export enum MfaStatus {
    MfaSetupRequired = 'MfaSetupRequired',
    MfaVerifyRequired = 'MfaVerifyRequired',
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    private context = inject(ContextService);
    private client = inject(UcClient);
    private router= inject(Router);
    private tenantSettings = inject(TenantSettingsService);
    private modalService = inject(ModalService);
    private storage = inject(StorageWrapper);
    private translateService = inject(TranslateService);

    async login(info: OAuthWithPassword | OAuthWithCode | OAuthWithVirtualMfa): Promise<void> {
        try {

            if (isOAuthWithPassword(info)) {
                const response = await this.client.authenticateWithPassword(info);

                if (response.mfa_challenge) {
                    throw this.getMfaError(MfaStatus.MfaVerifyRequired);
                }

            } else if (isOAuthWithCode(info)) {
                await this.client.authenticateWithCode(info);
            } else if (isOAuthWithVirtualMfa(info)) {
                await this.client.authenticateWithVirtualMfa(info);
            } else {
                throw new Error('Insufficient login information');
            }

            const account = await this.client.getMyAccount();

            if (!account.roles.length) {
                throw new UfError('At least one role is required');
            }

            this.context.account = account;

            if (account.changePasswordOnNextLogin) {
                throw this.getPasswordChangeError();
            }

            if (await this.isMfaSetupRequired(account)) {
                throw this.getMfaError(MfaStatus.MfaSetupRequired);
            }

        } catch (e) {

            const error = ensureUfError(e);

            if (isDictionary(error.data) && ( !!error.data.passwordChangeRequired || !!error.data.mfaStatus)) {
                throw error;
            }

            throw this.getAuthError(error);
        }
    }

    async logout(askConfirmation?: boolean): Promise<boolean> {

        if (askConfirmation && !await this.modalService.openConfirm({ title: 'Logout', cancelLabel: 'No', confirmLabel: 'Yes' })) {
            return false;
        }

        this.context.clear();
        this.client.deAuthenticate();
        this.storage.removeItem(PREVIEW_FORM_STORAGE_KEY);
        void this.router.navigate(['/login']);

        return true;
    }

    /* returns true if user is required to setup MFA */
    async isMfaSetupRequired(account: MyAccount): Promise<boolean> {

        const { isMfaEnforced } = await this.tenantSettings.sync();

        return isMfaEnforced && !account.isMfaOptional && !account.isMfaEnabled;
    }

    private getPasswordChangeError(): UfRequestError {

        const code = StatusCodes.UNAUTHORIZED;

        return new UfRequestError('Password Change Required', getErrorType(code), { passwordChangeRequired: true }, code);
    }

    private getMfaError(mfaStatus: MfaStatus): UfRequestError {

        const code = StatusCodes.UNAUTHORIZED;

        return new UfRequestError(this.translateService.instant(CommonTranslationKey.MfaRequiredLabel), getErrorType(code), { mfaStatus }, code);
    }

    /* responsible for extracting the message out of the response data rather than returning generic message */
    private getAuthError(error: UfRequestError): UfRequestError {

        if (!isDictionary(error.data)) {
            return error;
        }

        error.message = error.data.error_description || error.data.statusText || this.translateService.instant(SharedTermsTranslationKey.ErrorUnknown);

        return error;

    }

}
