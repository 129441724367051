import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { ModalService } from '@unifii/library/common';

import { UcClient } from 'client';
import { TypeSelectComponent, TypeSelectConfig } from 'components';

import { SystemIntegrationsTableManager } from './system-integrations-table-manager';

@Component({
    selector: 'uc-integrations-table',
    template: `
        <uf-table-container (addItem)="addIntegration()" ufSyncRoute ufMasterDetail class="cards container" />
        <router-outlet />
    `,
    providers: [
        { provide: TableContainerManager, useClass: SystemIntegrationsTableManager },
    ],
})
export class SystemIntegrationsComponent {

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private ucClient: UcClient,
        private modalService: ModalService,
    ) { }

    protected async addIntegration() {

        const availableIntegrations = await this.ucClient.getAvailableIntegrations();

        const config: TypeSelectConfig = {
            title: 'Select Integration Provider',
            types: availableIntegrations.map((i) => ({
                type: i.id,
                label: i.name,
                logo: i.logo || '/assets/svg/icon-integrations.svg',
            })),
        };

        const integration = await this.modalService.openMedium(TypeSelectComponent, config);

        if (integration) {
            void this.router.navigate(['new'], { queryParams: { provider: integration.type }, relativeTo: this.route });
        }
    }

}
