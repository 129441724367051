export * from './node-args.component';
export * from './node-content-info.component';
export * from './node-dashboard-tables.component';
export * from './node-details.component';
export * from './node-roles.component';
export * from './node-settings.component';
export * from './node-variations.component';

import { NodeArgsComponent } from './node-args.component';
import { NodeContentInfoComponent } from './node-content-info.component';
import { NodeDashboardTablesComponent } from './node-dashboard-tables.component';
import { NodeDetailsComponent } from './node-details.component';
import { NodeRolesComponent } from './node-roles.component';
import { NodeSettingsComponent } from './node-settings.component';
import { NodeVariationsComponent } from './node-variations.component';

export const STRUCTURE_NODE_DETAILS_COMPONENTS = [
    NodeArgsComponent,
    NodeContentInfoComponent,
    NodeDashboardTablesComponent,
    NodeDetailsComponent,
    NodeRolesComponent,
    NodeSettingsComponent,
    NodeVariationsComponent,
];
