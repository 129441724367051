import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedTermsTranslationKey } from '@unifii/library/common';
import { TenantClient, ensureUfRequestError, isDictionary, isOptionalType, isString, isValueOfStringEnumType } from '@unifii/sdk';

import { UcClient } from 'client';
import { UrlSegments } from 'constant';
import { AuthenticationService, MfaStatus } from 'services/authentication.service';
import { ContextService } from 'services/context.service';

import { PasswordChangeComponentNavigationState } from './password-change.component';

export interface MfaComponentNavigationState {
    mfaStatus: MfaStatus;
    password?: string;
}

export const isMfaComponentNavigationState = (data: unknown): data is MfaComponentNavigationState =>
    isDictionary(data) &&
    isValueOfStringEnumType(MfaStatus)(data.mfaStatus) &&
    isOptionalType(data.password, isString);

@Component({
    selector: 'uc-mfa',
    templateUrl: 'mfa.html',
    styleUrls: ['mfa.less'],
})
export class MFAComponent implements OnInit {

    protected readonly sharedTermsTK = SharedTermsTranslationKey;
    protected label: string | undefined;
    protected mfaStatus: MfaStatus | undefined;
    protected inProgress = false;
    protected error: string | undefined;

    private client = inject(UcClient);
    private router = inject(Router);
    private context = inject(ContextService);
    private authService = inject(AuthenticationService);
    private route = inject(ActivatedRoute);
    private tenantClient = inject(TenantClient);
    private state: MfaComponentNavigationState = history.state; // type assumed by mfa-guard

    async ngOnInit() {
        if (this.state.mfaStatus === MfaStatus.MfaSetupRequired) {
            await this.setUpMfa();
        }
        this.mfaStatus = this.state.mfaStatus;
    }

    protected setVirtualMfaCode(secret: string):Promise<void> {
        return this.client.setVirtualMfaCode(secret);
    }

    protected async verifyToken(token: string): Promise<void> {
        try {
            await this.authService.login({ mfa_token: token });
            void this.router.navigateByUrl(this.route.snapshot.params.next || '/');
        } catch (e) {
            const error = ensureUfRequestError(e);

            if (isDictionary(error.data) && !!error.data.passwordChangeRequired && this.state.password) {
                const params = this.route.snapshot.params.next ? { next: this.route.snapshot.params.next }: {};

                void this.router.navigate(['/', UrlSegments.PasswordChange, params], { state: { oldPassword: this.state.password } satisfies PasswordChangeComponentNavigationState });

                return;
            }

            this.error = error.message;
        } finally {
            this.inProgress = false;
        }
    }

    protected logout() {
        if (this.inProgress) {
            return;
        }
        void this.authService.logout();
    }

    private async setUpMfa() {
        try {
            const tenantName = (await this.tenantClient.getSettings()).name;

            if (!this.context.account?.username) {
                throw new Error('No username');
            }

            this.label = `(${tenantName}) ${this.context.account.username}`;

        } catch (e) {
            const error = ensureUfRequestError(e);

            this.error = error.message;
        }
    }

}
