<uf-panel *ngIf="!error" class="container">

    <div class="header-pane uf-app-bar transparent">
        <uc-builder-header />
    </div>

    <div class="content-pane scrollable">
        <uf-tabs (selectedChange)="updateTitle()">
            <uf-tab label="Table" path="" />
            <uf-tab *ngIf="!!info.table.id" label="Table Details" path="details-page" />
        </uf-tabs>
    </div>

</uf-panel>

<uc-error-message [error]="error" />