import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, FilterComponentRegistry, FilterEntries } from '@unifii/library/common';

import { UcProject } from 'client';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { InfoFilterFactory } from 'services/table/info-filter-factory';
import { InfoFilterRegistry } from 'services/table/info-filter-registry';
import { FormInfoLoader } from 'services/table/info-loaders';
import { InfoTableManager } from 'services/table/info-table-manager';
import { InfoLoader, InfoType } from 'services/table/models';

const createLoader = (ucProject: UcProject) => new FormInfoLoader(ucProject);

const createFilters = (filterFactory: InfoFilterFactory) => filterFactory.create(InfoType.Form);

@Component({
    selector: 'uc-forms',
    template: `
        <div ufMasterDetail>
            <uf-table-container (addItem)="addItem()" ufSyncRoute class="accent list-md">
                <uf-breadcrumbs [breadcrumbs]="breadcrumbs" title class="large" />
            </uf-table-container>
        </div>
        <router-outlet />
    `,
    providers: [
        { provide: FilterComponentRegistry, useClass: InfoFilterRegistry },
        { provide: FilterEntries, useFactory: createFilters, deps: [InfoFilterFactory] },
        { provide: InfoLoader, useFactory: createLoader, deps: [UcProject] },
        { provide: TableContainerManager, useClass: InfoTableManager },
    ],
    styleUrls: ['./forms.less'],
})
export class FormsComponent implements OnInit {

    @HostBinding('class.stretch-component') class = true;

    protected breadcrumbs: Breadcrumb[] = [];

    private route = inject(ActivatedRoute);
    private breadcrumbService = inject(BreadcrumbService);
    private manager = inject<InfoTableManager>(TableContainerManager);

    ngOnInit() {
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route);
    }

    addItem() {
        void this.manager.addActionCallback();
    }

}
