<div class="grid">
    <uf-text *ngIf="control.controls.title; let titleControl" [control]="titleControl | asUfControl"
        [(value)]="builderService.compound._title" label="Title" class="col-1of1" />

    <uf-text *ngIf="control.controls.consoleName; let consoleNameControl" [control]="consoleNameControl | asUfControl"
        [label]="consoleNameLabel" [(value)]="builderService.compound._consoleName" class="col-1of1" />

    <uf-text *ngIf="control.controls.identifier; let identifierControl" [control]="identifierControl | asUfControl"
        [maxLength]="identifierMaxLength" [(value)]="builderService.definition.identifier" label="identifier"
        class="col-1of1" />
</div>