"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HIERARCHY_ROOT_ID_ALIAS = exports.OFFSET_DATE_TIME_DATA_DISPLAY_FORMAT = exports.OFFSET_DATE_TIME_DATA_FORMAT_NO_MILLISECONDS = exports.OFFSET_DATE_TIME_DATA_FORMAT = exports.DATE_TIME_DATA_FORMAT = exports.TIME_DATA_FORMAT = exports.DATE_DATA_FORMAT = exports.SIGNATURE_DATA_PREFIX = void 0;
exports.SIGNATURE_DATA_PREFIX = 'data:image/png;base64,';
exports.DATE_DATA_FORMAT = 'yyyy-MM-dd';
exports.TIME_DATA_FORMAT = 'HH:mm:ss';
exports.DATE_TIME_DATA_FORMAT = `${exports.DATE_DATA_FORMAT}'T'${exports.TIME_DATA_FORMAT}`;
// cspell:disable-next-line
exports.OFFSET_DATE_TIME_DATA_FORMAT = `${exports.DATE_TIME_DATA_FORMAT}.SSSSSSSSSSSxxx`;
exports.OFFSET_DATE_TIME_DATA_FORMAT_NO_MILLISECONDS = `${exports.DATE_TIME_DATA_FORMAT}xxx`;
exports.OFFSET_DATE_TIME_DATA_DISPLAY_FORMAT = `${exports.DATE_TIME_DATA_FORMAT}.SSSxxx`;
exports.HIERARCHY_ROOT_ID_ALIAS = 'root';
