"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCompanyInfo = exports.isManager = exports.isClaimsRecord = exports.isClaim = exports.isContentLinkFormData = exports.isHierarchyUnitFormData = exports.isHierarchyUnitsPath = exports.isHierarchyPath = exports.isHierarchyStep = exports.isFileData = exports.isRepeat = exports.isStringArray = exports.isAddress = exports.isGeoLocation = exports.isCostModelFormat = exports.isChoice = exports.isDataSeed = exports.isZonedDateTime = exports.isOffsetDateTime = exports.isDateTime = exports.isTime = exports.isDate = exports.isSignature = exports.isPhone = exports.isWebsite = exports.isEmail = exports.isFieldOption = exports.isOption = void 0;
const constants_1 = require("../constants");
const content_types_1 = require("../content-types");
const models_1 = require("../models");
const date_fns_functions_1 = require("./date-fns-functions");
const type_guard_functions_1 = require("./type-guard-functions");
const addressKeys = ['formattedAddress', 'room', 'venue', 'address1', 'address2', 'suburb', 'city', 'postcode', 'state', 'country'];
const isOption = (value) => (0, type_guard_functions_1.isDictionary)(value) &&
    (0, type_guard_functions_1.isString)(value.name) &&
    (0, type_guard_functions_1.isString)(value.identifier);
exports.isOption = isOption;
const isFieldOption = (value) => (0, type_guard_functions_1.isDictionary)(value) &&
    (0, type_guard_functions_1.isOptionalType)(value.content, type_guard_functions_1.isString) &&
    (0, exports.isOption)(value);
exports.isFieldOption = isFieldOption;
const isEmail = (value) => (0, type_guard_functions_1.isString)(value) &&
    models_1.REGEXP_EMAIL.test(value);
exports.isEmail = isEmail;
const isWebsite = (value) => (0, type_guard_functions_1.isString)(value) &&
    models_1.REGEXP_WEBSITE.test(value);
exports.isWebsite = isWebsite;
const isPhone = (value) => (0, type_guard_functions_1.isString)(value) &&
    models_1.REGEXP_TELEPHONE.test(value);
exports.isPhone = isPhone;
const isSignature = (value) => (0, type_guard_functions_1.isString)(value) &&
    value.startsWith(constants_1.SIGNATURE_DATA_PREFIX);
exports.isSignature = isSignature;
const isDate = (value) => (0, type_guard_functions_1.isString)(value) &&
    (0, date_fns_functions_1.isValidDateFormat)(value, constants_1.DATE_DATA_FORMAT);
exports.isDate = isDate;
const isTime = (value) => (0, type_guard_functions_1.isString)(value) &&
    (0, date_fns_functions_1.isValidDateFormat)(value, constants_1.TIME_DATA_FORMAT);
exports.isTime = isTime;
const isDateTime = (value) => (0, type_guard_functions_1.isString)(value) &&
    (0, date_fns_functions_1.isValidDateFormat)(value, constants_1.DATE_TIME_DATA_FORMAT);
exports.isDateTime = isDateTime;
const isOffsetDateTime = (value) => (0, type_guard_functions_1.isString)(value) &&
    ((0, date_fns_functions_1.isValidDateFormat)(value, constants_1.OFFSET_DATE_TIME_DATA_FORMAT) ||
        (0, date_fns_functions_1.isValidDateFormat)(value, constants_1.OFFSET_DATE_TIME_DATA_FORMAT_NO_MILLISECONDS));
exports.isOffsetDateTime = isOffsetDateTime;
const isZonedDateTime = (value) => (0, type_guard_functions_1.isDictionary)(value) &&
    (0, exports.isOffsetDateTime)(value.value) &&
    (0, type_guard_functions_1.isStringNotEmpty)(value.tz);
exports.isZonedDateTime = isZonedDateTime;
const isDataSeed = (value) => (0, type_guard_functions_1.isDictionary)(value) &&
    ((0, type_guard_functions_1.isStringNotEmpty)(value._id) || (0, type_guard_functions_1.isNumber)(value._id) /** collection identifier*/) &&
    (0, type_guard_functions_1.isString)(value._display);
exports.isDataSeed = isDataSeed;
const isChoice = (value) => (0, type_guard_functions_1.isStringNotEmpty)(value) || (0, exports.isDataSeed)(value);
exports.isChoice = isChoice;
const isCostModelFormat = (value) => (0, type_guard_functions_1.isDictionary)(value) &&
    (0, type_guard_functions_1.isNumber)(value.amount) &&
    (0, type_guard_functions_1.isStringNotEmpty)(value.currency);
exports.isCostModelFormat = isCostModelFormat;
const isGeoLocation = (value) => (0, type_guard_functions_1.isDictionary)(value) &&
    (0, type_guard_functions_1.isNumber)(value.lat) &&
    (0, type_guard_functions_1.isNumber)(value.lng) &&
    (0, type_guard_functions_1.isOptionalType)(value.zoom, type_guard_functions_1.isNumber);
exports.isGeoLocation = isGeoLocation;
/**
 * A valid address can contains valid GeoLocation attributes and must have at least one address attribute.
 */
const isAddress = (value) => {
    if (!(0, type_guard_functions_1.isDictionary)(value)) {
        return false;
    }
    const keys = Object.keys(value);
    const presentAddressKeys = keys.filter((k) => addressKeys.includes(k));
    return !!presentAddressKeys.length &&
        (((0, type_guard_functions_1.isNumber)(value.lat) && (0, type_guard_functions_1.isNumber)(value.lng) && (0, type_guard_functions_1.isOptionalType)(value.zoom, type_guard_functions_1.isNumber)) ||
            (value.lat == null && value.lng == null && value.zoom == null)) &&
        (0, type_guard_functions_1.isOptionalType)(value.formattedAddress, type_guard_functions_1.isStringTrimmedNotEmpty) &&
        (0, type_guard_functions_1.isOptionalType)(value.room, type_guard_functions_1.isStringTrimmedNotEmpty) &&
        (0, type_guard_functions_1.isOptionalType)(value.venue, type_guard_functions_1.isStringTrimmedNotEmpty) &&
        (0, type_guard_functions_1.isOptionalType)(value.address1, type_guard_functions_1.isStringTrimmedNotEmpty) &&
        (0, type_guard_functions_1.isOptionalType)(value.address2, type_guard_functions_1.isStringTrimmedNotEmpty) &&
        (0, type_guard_functions_1.isOptionalType)(value.suburb, type_guard_functions_1.isStringTrimmedNotEmpty) &&
        (0, type_guard_functions_1.isOptionalType)(value.city, type_guard_functions_1.isStringTrimmedNotEmpty) &&
        (0, type_guard_functions_1.isOptionalType)(value.postcode, type_guard_functions_1.isStringTrimmedNotEmpty) &&
        (0, type_guard_functions_1.isOptionalType)(value.state, type_guard_functions_1.isStringTrimmedNotEmpty) &&
        (0, type_guard_functions_1.isOptionalType)(value.country, type_guard_functions_1.isStringTrimmedNotEmpty);
};
exports.isAddress = isAddress;
const isStringArray = (value) => (0, type_guard_functions_1.isArrayOfType)(value, type_guard_functions_1.isString);
exports.isStringArray = isStringArray;
const isRepeat = (value) => (0, type_guard_functions_1.isArrayOfType)(value, type_guard_functions_1.isDictionary);
exports.isRepeat = isRepeat;
const isFileData = (value) => (0, type_guard_functions_1.isDictionary)(value) &&
    (0, type_guard_functions_1.isString)(value.name) &&
    (0, type_guard_functions_1.isOptionalType)(value.id, type_guard_functions_1.isString);
exports.isFileData = isFileData;
const isHierarchyStep = (value) => (0, type_guard_functions_1.isDictionary)(value) &&
    Object.keys(value).length === 2 &&
    (0, type_guard_functions_1.isStringNotEmpty)(value.id) &&
    (0, type_guard_functions_1.isString)(value.label);
exports.isHierarchyStep = isHierarchyStep;
const isHierarchyPath = (value) => (0, type_guard_functions_1.isArrayOfType)(value, exports.isHierarchyStep) && !!value.length;
exports.isHierarchyPath = isHierarchyPath;
const isHierarchyUnitsPath = (value) => (0, type_guard_functions_1.isArrayOfType)(value, exports.isHierarchyPath) && !!value.length;
exports.isHierarchyUnitsPath = isHierarchyUnitsPath;
const isHierarchyUnitFormData = (value) => (0, type_guard_functions_1.isDictionary)(value) &&
    (0, type_guard_functions_1.isStringNotEmpty)(value.id) &&
    (0, type_guard_functions_1.isString)(value.label) &&
    (0, exports.isHierarchyPath)(value.path);
exports.isHierarchyUnitFormData = isHierarchyUnitFormData;
const isContentLinkFormData = (value) => (0, type_guard_functions_1.isDictionary)(value) &&
    (0, type_guard_functions_1.isValueOfStringEnumType)(content_types_1.LinkContentType)(value.type) &&
    (0, type_guard_functions_1.isString)(value.id) &&
    (0, type_guard_functions_1.isString)(value.label) &&
    (0, type_guard_functions_1.isOptionalType)(value.fileType, type_guard_functions_1.isString) &&
    (0, type_guard_functions_1.isOptionalType)(value.useViewer, type_guard_functions_1.isBoolean);
exports.isContentLinkFormData = isContentLinkFormData;
const isClaim = (value) => (0, type_guard_functions_1.isDictionary)(value) &&
    Object.keys(value).length === 2 &&
    (0, type_guard_functions_1.isStringNotEmpty)(value.type) &&
    (0, type_guard_functions_1.isString)(value.value);
exports.isClaim = isClaim;
const isClaimsRecord = (value) => (0, type_guard_functions_1.isDictionaryOfType)(value, (v) => (0, type_guard_functions_1.isString)(v) || (0, type_guard_functions_1.isArrayOfType)(v, type_guard_functions_1.isString));
exports.isClaimsRecord = isClaimsRecord;
const isManager = (value) => (0, type_guard_functions_1.isDictionary)(value) &&
    (0, type_guard_functions_1.isString)(value.id) &&
    (0, type_guard_functions_1.isString)(value.username) &&
    (0, type_guard_functions_1.isOptionalType)(value.firstName, type_guard_functions_1.isString) &&
    (0, type_guard_functions_1.isOptionalType)(value.lastName, type_guard_functions_1.isString) &&
    (0, type_guard_functions_1.isOptionalType)(value.email, type_guard_functions_1.isString) &&
    (0, type_guard_functions_1.isOptionalType)(value.phone, type_guard_functions_1.isString);
exports.isManager = isManager;
const isCompanyInfo = (value) => (0, type_guard_functions_1.isDictionary)(value) &&
    (0, type_guard_functions_1.isString)(value.id) &&
    (0, type_guard_functions_1.isString)(value.name);
exports.isCompanyInfo = isCompanyInfo;
