import * as adjacencyGraphs$1 from './adjacencyGraphs.json.esm.js';
import passwords from './passwords.json.esm.js';
import diceware from './diceware.json.esm.js';

const dictionary = {
  passwords,
  diceware
};
// reinit the variable because the typescript compile will otherwise just reexport the json file which will break as it's a json file
const adjacencyGraphs = adjacencyGraphs$1;

export { adjacencyGraphs, dictionary };

