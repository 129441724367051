import { Injectable, inject } from '@angular/core';
import { ClientDeleteOptions, ClientGetOptions, ClientPostOptions, ClientPutOptions, HierarchyChildrenSearchOptions, HierarchySearchOptions, HierarchyUnit, HierarchyUnitExtended, HierarchyUnitWithChildCount, HierarchyUnitWithPath, amendOptionsParams } from '@unifii/sdk';

import { UcClient } from './uc-client';

@Injectable({ providedIn: 'root' })
export class UcHierarchy {

    private client = inject(UcClient);

    getUnit(id: string, options?: ClientGetOptions): Promise<HierarchyUnitExtended> {
        return this.client.get(this.url(id), options) as Promise<HierarchyUnitExtended>;
    }

    async getUnits(ids: string[], options?: ClientGetOptions): Promise<HierarchyUnitWithPath[]> {

        const results: HierarchyUnitWithPath[] = [];
        const pageSize = 50;

        // Split ids into batches of pageSize
        const pages = [];

        for (let i = 0; i < ids.length; i += pageSize) {
            pages.push(ids.slice(i, i + pageSize));
        }

        // Process each batch
        for (const batchIds of pages) {
            const batch = await this.client.get(this.url(), amendOptionsParams({ ids: batchIds.join(',') }, options));

            results.push(...batch);
        }

        return results;
    }

    getUnitChildren(parentId: string, params?: HierarchyChildrenSearchOptions, options?: ClientGetOptions): Promise<HierarchyUnitWithChildCount[]> {
        return this.client.get(
            this.url(parentId, 'children'),
            amendOptionsParams(params as Record<string, unknown> | undefined ?? {}, options),
        ) as Promise<HierarchyUnitWithChildCount[]>;
    }

    search(params?: HierarchySearchOptions, options?: ClientGetOptions): Promise<HierarchyUnitWithPath[]> {
        return this.client.get(
            this.url(),
            amendOptionsParams(params as Record<string, unknown> | undefined ?? {}, options),
        ) as Promise<HierarchyUnitWithPath[]>;
    }

    update(unit: HierarchyUnit, options?: ClientPutOptions): Promise<HierarchyUnitExtended> {
        return this.client.put(this.url(unit.id), unit, options) as Promise<HierarchyUnitExtended>;
    }

    add(unit: HierarchyUnit, options?: ClientPostOptions): Promise<HierarchyUnitExtended> {
        return this.client.post(this.url(), { ...options, body: unit }) as Promise<HierarchyUnitExtended>;
    }

    remove(id: string, options?: ClientDeleteOptions): Promise<void> {
        return this.client.delete(this.url(id), options) as Promise<void>;
    }

    private url(...extra: string[]): string {
        const urlParts = ['units'].concat(extra);

        return this.client.buildUrl(urlParts);
    }

}
