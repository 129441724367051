import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService, ToastService, UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { ErrorType, UfRequestError, ensureUfRequestError } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { SmtpInfo, UcSmtp } from 'client';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { EditData } from 'components/common/edit-data';
import { reloadCurrentRoute } from 'pages/utils';
import { DialogsService } from 'services/dialogs.service';
import { TitleService } from 'services/title.service';

import { SystemSmtpTestModalComponent } from './system-smtp-test-modal.component';
import { TenantSettingsComponent } from './tenant-settings.component';

@Component({
    templateUrl: './system-smtp.html',
    styleUrls: ['./system-smtp.less'],
})
export class SystemSmtpComponent implements OnInit, OnDestroy, EditData {

    @ViewChild('headerButtons', { static: true }) protected headerButtonsTemplate: TemplateRef<any>;

    protected error: UfRequestError | null = null;
    protected loading = true;
    protected canDelete: boolean;
    protected form: UfControlGroup;
    protected valueChangesSubscription: Subscription | null;

    private subscriptions = new Subscription();
    private smtpInfo: SmtpInfo | undefined;

    private router = inject(Router);
    private ucSmtp = inject(UcSmtp);
    private toast = inject(ToastService);
    private modalService = inject(ModalService);
    private dialogs = inject(DialogsService);
    private titleService = inject(TitleService);
    private parent = inject(TenantSettingsComponent);

    private builderHeaderService = inject(BuilderHeaderService);

    set edited(v: boolean) {
        this.builderHeaderService.config.edited = v;
    }

    get edited() {
        return this.builderHeaderService.config.edited ?? false;
    }

    async ngOnInit() {
        this.titleService.updateTitle('SMTP');
        this.form = new UfControlGroup({
            smtpHost: new UfControl(ValidatorFunctions.required('Host is required')),
            smtpPort: new UfControl(),
            smtpSystemName: new UfControl(),
            smtpSystemEmail: new UfControl(
                ValidatorFunctions.compose([
                    ValidatorFunctions.email('Invalid email'),
                    ValidatorFunctions.required('System Email is required'),
                ]),
            ),
            smtpUsername: new UfControl(ValidatorFunctions.required('Username is required')),
            smtpPassword: new UfControl(ValidatorFunctions.required('Password is required')),
        });

        this.parent.templateRef = this.headerButtonsTemplate;
        await this.load();
        this.buildHeaderConfig();
    }

    ngOnDestroy() {
        this.valueChangesSubscription?.unsubscribe();
        this.subscriptions.unsubscribe();
    }

    async load() {
        this.error = null;
        this.edited = false;
        this.loading = true;

        this.valueChangesSubscription?.unsubscribe();

        try {
            this.smtpInfo = await this.ucSmtp.get();

            this.form.patchValue(this.smtpInfo);
            this.canDelete = true;
        } catch (e) {
            const error = ensureUfRequestError(e);

            if (error.type === ErrorType.NotFound) { // 404 means SMTP is just not set up yet
                this.canDelete = false;
            } else {
                this.error = error;
                this.toast.error('Loading error');
                console.error(e);
            }
        }
        this.loading = false;
        this.valueChangesSubscription = this.form.valueChanges.subscribe(() => { this.edited = true; });
    }

    async save() {
        this.error = null;

        this.form.setSubmitted();

        if (!this.form.valid) {
            return;
        }

        // TODO check uf-password component and why it removes value from root control
        if (!this.form.value.smtpPassword && this.form.controls.smtpPassword?.value) {
            this.form.value.smtpPassword = this.form.controls.smtpPassword.value;
        }

        try {
            await this.ucSmtp.save(this.form.value as SmtpInfo);
            this.refreshPageAndShowMessage('Saved successfully');
        } catch (e) {
            this.error = ensureUfRequestError(e);
            this.toast.error('Error saving');
            console.error(e);
        }
    }

    async delete() {
        this.error = null;

        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        try {
            await this.ucSmtp.delete();
            this.refreshPageAndShowMessage('Deleted successfully');
        } catch (e) {
            this.error = ensureUfRequestError(e);
            this.toast.error('Error deleting');
            console.error(e);
        }
    }

    async sendTest() {
        this.error = null;

        this.form.markAllAsTouched();

        const email = await this.modalService.openFit(SystemSmtpTestModalComponent, null);

        if (!this.form.valid || !email) {
            return;
        }

        // TODO check uf-password component and why it removes value from root control
        if (!this.form.value.smtpPassword && this.form.controls.smtpPassword?.value) {
            this.form.value.smtpPassword = this.form.controls.smtpPassword?.value;
        }

        try {
            await this.ucSmtp.test(this.form.value as SmtpInfo, email as string);
            this.toast.success('Test email sent');
        } catch (e) {
            this.error = ensureUfRequestError(e);
            this.toast.error('Error sending test');
            console.error(e);
        }
    }

    private buildHeaderConfig() {
        this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe(() => this.save()));
        this.builderHeaderService.updateConfig({
            lastModifiedAt: this.smtpInfo?.lastModifiedAt,
            lastModifiedBy: this.smtpInfo?.lastModifiedBy,
            hideSaveButton: false,
            cancelRoute: ['..'],
        });
        this.subscriptions.add(this.form.statusChanges.subscribe(() => { this.builderHeaderService.config.edited = !this.form.pristine; }));
    }

    private refreshPageAndShowMessage(message: string) {
        this.edited = false;
        this.toast.success(message);
        reloadCurrentRoute(this.router);
    }

}
