"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormDataOptions = exports.ProjectContentOptions = exports.ConnectionOptions = void 0;
/** InjectionToken like */
class ConnectionOptions {
}
exports.ConnectionOptions = ConnectionOptions;
/** InjectionToken like */
class ProjectContentOptions {
}
exports.ProjectContentOptions = ProjectContentOptions;
/** InjectionToken like */
class FormDataOptions {
}
exports.FormDataOptions = FormDataOptions;
