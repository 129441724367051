"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAbortError = exports.isUfRequestError = exports.isUfError = exports.getUfRequestErrorByStatusCode = exports.ensureUfRequestError = exports.ensureUfError = exports.ensureError = void 0;
const http_status_codes_1 = require("http-status-codes");
const client_models_1 = require("../client-models");
const constants_1 = require("../constants");
const models_1 = require("../models");
const http_functions_1 = require("./http-functions");
const type_guard_functions_1 = require("./type-guard-functions");
/** Guarantee that a caught runtime error is of type Error */
const ensureError = (value, fallbackMessage) => {
    if (value instanceof Error) {
        return value;
    }
    if (fallbackMessage) {
        return new Error(fallbackMessage);
    }
    try {
        return new Error(`This value was thrown as is, not through an Error: ${JSON.stringify(value)}`);
    }
    catch {
        return new Error('[Unable to stringify the thrown value]');
    }
};
exports.ensureError = ensureError;
const ensureUfError = (value, fallbackMessage) => {
    if ((0, exports.isUfError)(value)) {
        return value;
    }
    const error = (0, exports.ensureError)(value, fallbackMessage);
    const data = (0, type_guard_functions_1.isDictionary)(value) ? value.data : undefined;
    const numberType = (0, type_guard_functions_1.isDictionary)(value) && ((0, type_guard_functions_1.isNumber)(value.type) || (0, type_guard_functions_1.isString)(value.type) && !isNaN(parseInt(value.type))) ?
        parseInt(value.type) :
        undefined;
    const errorType = (0, type_guard_functions_1.isValueOfNumberEnumType)(client_models_1.ErrorType)(numberType) ? numberType : undefined;
    const ufError = new models_1.UfError(error.message, errorType, data);
    ufError.name = error.name;
    ufError.stack = error.stack;
    return ufError;
};
exports.ensureUfError = ensureUfError;
const ensureUfRequestError = (value, fallbackMessage) => {
    if ((0, exports.isUfRequestError)(value)) {
        return value;
    }
    const ufError = (0, exports.ensureUfError)(value, fallbackMessage);
    const numberCode = (0, type_guard_functions_1.isDictionary)(value) && ((0, type_guard_functions_1.isNumber)(value.code) || (0, type_guard_functions_1.isString)(value.code) && !isNaN(parseInt(value.code))) ?
        parseInt(value.code) :
        undefined;
    const statusCode = (0, type_guard_functions_1.isValueOfNumberEnumType)(http_status_codes_1.StatusCodes)(numberCode) ? numberCode : undefined;
    const ufRequestError = new models_1.UfRequestError(ufError.message, ufError.type, ufError.data, statusCode);
    ufRequestError.name = ufError.name;
    ufRequestError.stack = ufError.stack;
    return ufRequestError;
};
exports.ensureUfRequestError = ensureUfRequestError;
const getUfRequestErrorByStatusCode = (statusCode, message) => new models_1.UfRequestError(message ?? (0, http_status_codes_1.getReasonPhrase)(statusCode), (0, http_functions_1.getErrorType)(statusCode), undefined, statusCode);
exports.getUfRequestErrorByStatusCode = getUfRequestErrorByStatusCode;
const isUfError = (value) => {
    if (!(value instanceof Error)) {
        return false;
    }
    const unknownValue = value;
    return (0, type_guard_functions_1.isDictionary)(unknownValue) &&
        (0, type_guard_functions_1.isNumber)(unknownValue.type) &&
        (0, type_guard_functions_1.isOptionalType)(unknownValue.data, type_guard_functions_1.isObject);
};
exports.isUfError = isUfError;
const isUfRequestError = (value) => {
    if (!(0, exports.isUfError)(value)) {
        return false;
    }
    const unknownValue = value;
    return (0, type_guard_functions_1.isDictionary)(unknownValue) &&
        (0, type_guard_functions_1.isOptionalType)(parseInt(unknownValue.code), (0, type_guard_functions_1.isValueOfNumberEnumType)(http_status_codes_1.StatusCodes));
};
exports.isUfRequestError = isUfRequestError;
const isAbortError = (value) => value instanceof DOMException && value.name === constants_1.DOMExceptionAbortErrorName;
exports.isAbortError = isAbortError;
