import { Injectable, inject } from '@angular/core';
import { ClientDeleteOptions, ClientGetOptions, ClientPostOptions, ClientPutOptions, amendOptionsParams } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';
import { ContextService } from 'services/context.service';

import { ActivityType, NewWorkflowRule, WorkflowActivityInfo, WorkflowRule } from './models';
import { UcClient } from './uc-client';

export interface ActivitiesParams {
    type?: ActivityType;
    q?: string;
    offset?: number;
    limit?: number;
    sort?: string;
}

@Injectable({ providedIn: 'root' })
export class UcWorkflow {

    private readonly id = 'default';
    private client = inject(UcClient);
    private context = inject(ContextService);

    getRules(options?: ClientGetOptions): Promise<WorkflowRule[]> {
        return this.client.get(this.url('rules'), amendOptionsParams(DefaultPaginationParams, options))
            .then((workflowRules: WorkflowRule[]) => workflowRules.map(this.fixRuleActivities.bind(this)));
    }

    addRule(rule: NewWorkflowRule, options?: ClientPostOptions): Promise<WorkflowRule> {
        return this.client.post(this.url('rules'), { ...options, body: rule }) as Promise<WorkflowRule>;
    }

    updateRule(rule: WorkflowRule, options?: ClientPutOptions): Promise<WorkflowRule> {
        return this.client.put(this.url('rules', rule.id), rule, options) as Promise<WorkflowRule>;
    }

    getRule(id: string, options?: ClientGetOptions): Promise<WorkflowRule> {
        return this.client.get(this.url('rules', id), options).then(this.fixRuleActivities.bind(this));
    }

    deleteRule(id: string, options?: ClientDeleteOptions): Promise<void> {
        return this.client.delete(this.url('rules', id), options) as Promise<void>;
    }

    getActivities<T extends WorkflowActivityInfo>(options?: ClientGetOptions): Promise<T[]> {
        return this.client.get(this.url('activities'), amendOptionsParams(DefaultPaginationParams, options)) as Promise<T[]>;
    }

    addActivity<T extends WorkflowActivityInfo>(activity: T, options?: ClientPostOptions): Promise<T> {
        return this.client.post(this.url('activities'), { ...options, body: activity }) as Promise<T>;
    }

    updateActivity<T extends WorkflowActivityInfo>(activity: T, options?: ClientPutOptions): Promise<T> {
        return this.client.put(this.url('activities', activity.id.toString()), activity, options) as Promise<T>;
    }

    getActivity<T extends WorkflowActivityInfo>(id: string, options?: ClientGetOptions): Promise<T> {
        return this.client.get(this.url('activities', id), options) as Promise<T>;
    }

    deleteActivity(id: string, force?: boolean, options?: ClientDeleteOptions): Promise<void> {
        return this.client.delete(this.url('activities', id), amendOptionsParams({ force }, options)) as Promise<void>;
    }

    private url(...extra: string[]): string {
        const projectId = this.context.project?.id;

        return this.client.buildUrl(['projects', projectId, 'workflows', this.id, ...extra]);
    }

    private fixRuleActivities(workflowRule: WorkflowRule): WorkflowRule {
        // This fix here is because there are a case which hasn't been found where activities are getting null. - UNIFII-7798
        // We'll be monitoring - if it happens again we can create a ticket to investigate further, otherwise we can remove this change in
        // a couple of releases as it's not happening anymore
        // eslint-disable-next-line disable-autofix/@typescript-eslint/no-unnecessary-condition
        workflowRule.activities = workflowRule.activities ?? [];

        return workflowRule;
    }

}
