"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MeClient = void 0;
const constants_1 = require("../constants");
/** Client for v0/me APIs */
class MeClient {
    constructor(client) {
        this.client = client;
    }
    // TODO remove token
    get(token) {
        const options = token ? { params: { token }, anonymous: true } : undefined;
        return this.client.get(this.url(), options);
    }
    /** Update password (authenticated context) */
    updatePassword(passwordDetails) {
        /** Patch method is being used exclusively for updating password details */
        return this.client.patch(this.url(), passwordDetails);
    }
    // TODO remove token
    /** Update password (anonymous context) */
    resetPassword(passwordDetails, token) {
        const options = token ? { params: { token }, anonymous: true } : undefined;
        return this.client.patch(this.url(constants_1.APIPath.ResetPassword), passwordDetails, options);
    }
    /** Set user virtual MFA token secret */
    setVirtualMfaCode(secret) {
        return this.client.put(this.url(constants_1.APIPath.MeVirtualMfaCode), { secret });
    }
    // TODO remove token
    getPermissions(token) {
        const options = token ? { params: { token }, anonymous: true } : undefined;
        return this.client.get(this.url(constants_1.APIPath.MePermissions), options);
    }
    update(user, token) {
        const options = token ? { params: { token }, anonymous: true } : undefined;
        return this.client.put(this.url(), user, options);
    }
    url(...extra) {
        return this.client.buildUrl(constants_1.APIPath.Me, ...extra);
    }
}
exports.MeClient = MeClient;
