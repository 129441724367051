"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HierarchyClient = void 0;
const constants_1 = require("../constants");
/** Client for v0/units APIs */
class HierarchyClient {
    constructor(client) {
        this.client = client;
    }
    search(params, options) {
        return this.client.get(this.url(), { params: params, ...options });
    }
    getUnit(id, options) {
        return this.client.get(this.url(id), options);
    }
    getUnits(ids, options) {
        const paramsWithIds = { ...options?.params, ids: ids.join(',') };
        return this.client.get(this.url(), { ...options, params: paramsWithIds });
    }
    getUnitChildren(parentId, params, options) {
        return this.client.get(this.url(parentId, constants_1.APIPath.HierarchyChildren), { params, ...options });
    }
    url(...extra) {
        return this.client.buildUrl(constants_1.APIPath.HierarchyUnits, ...extra);
    }
}
exports.HierarchyClient = HierarchyClient;
