"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.astNodeErrorsCheck = exports.mergeAstNodes = exports.astNodeIterator = exports.stringifyQuery = void 0;
const constants_1 = require("../constants");
const models_1 = require("../models");
const stringifyQuery = (query) => query ? query.stringify().replace(/'/g, '%27') : undefined;
exports.stringifyQuery = stringifyQuery;
/** Iterate over an AstNode tree
 *
 * @param node top node to start the iteration from
 * @param parent reference to the parent of the @param node
 * @param options options to determine the behavior of this iterator, @see AstNodeIteratorOptions
 * @return iterable of @see AstNodeIteratorEntry entries
 */
function* astNodeIterator(node, parent, options = { order: 'postOrder' }) {
    const dive = node.args && (!options.canDive || options.canDive(node, parent));
    const emit = !options.canEmit || options.canEmit(node, parent);
    if (options.order === 'postOrder') {
        if (dive && node.args) {
            for (const arg of node.args) {
                yield* astNodeIterator(arg, node, options);
            }
        }
        if (emit) {
            yield { node, parent };
        }
    }
    else {
        if (emit) {
            yield { node, parent };
        }
        if (dive && node.args) {
            for (const arg of node.args) {
                yield* astNodeIterator(arg, node, options);
            }
        }
    }
}
exports.astNodeIterator = astNodeIterator;
/** Merge multiple AstNode in a single one, flattening the operators when possible */
const mergeAstNodes = (combinator, ...astNodes) => {
    const args = [];
    for (const astNode of astNodes) {
        if (!astNode) {
            continue;
        }
        const canFlatten = astNode.type === models_1.NodeType.Combinator && astNode.op === combinator;
        args.push(...(canFlatten ? (astNode.args ?? []) : [astNode]));
    }
    if (!args.length) {
        return;
    }
    return {
        type: models_1.NodeType.Combinator,
        op: combinator,
        args,
    };
};
exports.mergeAstNodes = mergeAstNodes;
// eslint-disable-next-line complexity
const astNodeErrorsCheck = (node, allowExpressionNodeType = true) => {
    const errors = [];
    const operatorNodeArgsTypes = [models_1.NodeType.Expression, models_1.NodeType.Identifier, models_1.NodeType.Value];
    // eslint-disable-next-line disable-autofix/@typescript-eslint/no-unnecessary-condition
    if (node.type == null) {
        errors.push(`type not defined`);
    }
    if (operatorNodeArgsTypes.includes(node.type)) {
        if (node.args) {
            errors.push(`args not allowed for type ${nodeTypeString[node.type]}`);
        }
        if (node.op) {
            errors.push(`op not allowed for type ${nodeTypeString[node.type]}`);
        }
        if (!node.value) {
            errors.push(`value not defined for type ${nodeTypeString[node.type]}`);
        }
    }
    else {
        if (!node.op) {
            errors.push(`op required for type ${nodeTypeString[node.type]}`);
        }
        if (!node.args?.length) {
            errors.push(`args are required for ${nodeTypeString[node.type]}`);
        }
        if (node.value) {
            errors.push(`value not allowed for type ${nodeTypeString[node.type]}`);
        }
    }
    switch (node.type) {
        case models_1.NodeType.Combinator:
            if (node.op && !constants_1.QueryCombinatorOperators.includes(node.op)) {
                errors.push(`op ${node.op} not valid for type ${nodeTypeString[node.type]}`);
            }
            if (node.op === models_1.QueryOperators.Not && node.args?.length !== 1) {
                errors.push(`op ${node.op} require 1 arg for type ${nodeTypeString[node.type]}`);
            }
            break;
        case models_1.NodeType.Operator:
            if (node.op && !constants_1.QueryOperatorOperators.includes(node.op)) {
                errors.push(`op ${node.op} not valid for type ${nodeTypeString[node.type]}`);
            }
            if (!constants_1.QueryIdentifierArgsOnlyOperators.includes(node.op) && node.args?.length !== 2) {
                errors.push(`op ${node.op} require 2 args for type  ${nodeTypeString[node.type]}`);
            }
            if ([models_1.QueryOperators.Has, models_1.QueryOperators.Hasnt].includes(node.op) && node.args?.length !== 1) {
                errors.push(`op ${node.op} require 1 arg for type ${nodeTypeString[node.type]}`);
            }
            errors.push(...(node.args ?? [])
                .filter((arg) => !operatorNodeArgsTypes.includes(arg.type))
                .map((arg) => `type ${nodeTypeString[arg.type]} not allowed as arg of ${nodeTypeString[node.type]} node`));
            if (constants_1.QueryIdentifierArgsOnlyOperators.includes(node.op)) {
                errors.push(...(node.args ?? [])
                    .filter((arg) => arg.type !== models_1.NodeType.Identifier)
                    .map((arg) => `type ${nodeTypeString[arg.type]} not allowed as arg of op ${node.op}`));
            }
            else {
                if (!node.args?.find((arg) => arg.type === models_1.NodeType.Identifier)) {
                    errors.push(`op ${node.op} require an arg of type ${nodeTypeString[models_1.NodeType.Identifier]}`);
                }
                if (!node.args?.find((arg) => arg.type === models_1.NodeType.Value) && (!allowExpressionNodeType || !node.args?.find((arg) => arg.type === models_1.NodeType.Expression))) {
                    errors.push(`op ${node.op} require an arg of type ${nodeTypeString[models_1.NodeType.Value]} or ${nodeTypeString[models_1.NodeType.Expression]}`);
                }
            }
            break;
        case models_1.NodeType.Expression:
            if (!allowExpressionNodeType) {
                errors.push(`type ${nodeTypeString[node.type]} not allowed`);
            }
    }
    for (const arg of node.args ?? []) {
        errors.push(...((0, exports.astNodeErrorsCheck)(arg, allowExpressionNodeType) ?? []));
    }
    return errors.length ? errors : undefined;
};
exports.astNodeErrorsCheck = astNodeErrorsCheck;
const nodeTypeString = {
    [models_1.NodeType.Combinator]: 'Combinator',
    [models_1.NodeType.Expression]: 'Expression',
    [models_1.NodeType.Identifier]: 'Identifier',
    [models_1.NodeType.Operator]: 'Operator',
    [models_1.NodeType.Value]: 'Value',
};
