import { Injectable, inject } from '@angular/core';
import { Compound, ContentType, Definition, Dictionary, ImageProfile, NodeType, Page, PublishedContent, Query, Schema, Table, buildImageUrl, hasLengthAtLeast } from '@unifii/sdk';

import { StructureService, UcFormBucketClient, UcProject } from 'client';

@Injectable()
export class PreviewContentService implements PublishedContent {

    private ucProject = inject(UcProject);
    private ucFormBucketClient = inject(UcFormBucketClient);
    private structureService = inject(StructureService);

    getView(identifier: string): Promise<Compound> {
        return this.ucProject.getView(+identifier);
    }

    getViewDefinition(identifier: string): Promise<Definition> {
        return this.ucProject.getViewDefinition(identifier) as Promise<Definition>;
    }

    getPage(identifier: string): Promise<Page> {
        return this.ucProject.getPage(+identifier);
    }

    getCollectionDefinition(identifier: string): Promise<Definition> {
        return this.ucProject.collection(identifier).getDefinition() as Promise<Definition>;
    }

    getCollections(): Promise<Definition[]> {
        throw new Error('not implemented');
    }

    queryCollection(identifier: string, query?: Query): Promise<Compound[]> {
        const ids = this.getIds(query);

        return this.ucProject.collection(identifier).get({ limit: 200, full: true, ids: ids?.join(',') });
    }

    getCollectionItem(identifier: string, id: string): Promise<Compound> {
        return this.ucProject.collection(identifier).getItem(+id);
    }

    getBucket(identifier: string): Promise<Schema> {
        return this.ucFormBucketClient.get(identifier);
    }

    queryForms(): Promise<Definition[]> {
        throw new Error('not implemented');
    }

    getForm(identifier: string): Promise<Definition> {
        return this.ucProject.getForm(identifier) as Promise<Definition>;
    }

    queryTables(): Promise<Table[]> {
        throw new Error('not implemented');
    }

    queryPages(): Promise<Page[]> {
        throw new Error('not implemented.');
    }

    getTable(id: string): Promise<Table> {
        return this.ucProject.getTable(id);
    }

    getStructure() {
        return this.structureService.get();
    }

    getIdentifiers(): Promise<Dictionary<{ type: ContentType }>> {
        throw new Error('not implemented');
    }

    getAssetUrl(): Promise<string> {
        throw new Error('Method not implemented.');
    }

    buildImageUrl(imageProfile: ImageProfile, options: { width?: number }): string | undefined {
        return buildImageUrl(imageProfile, options);
    }

    private getIds(query: Query | undefined): string[] | undefined {

        if (query && query.args.length === 1 && hasLengthAtLeast(query.args, 1)) {
            const n = query.args[0];

            if (n.op === 'in' && n.args[0]?.type === NodeType.Identifier && n.args[0].value === 'id') {
                return n.args[1]?.value as string[] | undefined;
            }
        }

        return;
    }

}

