"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toHexString = exports.decrypt = exports.encrypt = exports.hashBody = exports.hmac = exports.isUUID = exports.generateUUID = void 0;
const base64_js_1 = require("base64-js");
const uuid_1 = require("uuid");
const generateUUID = () => (0, uuid_1.v4)();
exports.generateUUID = generateUUID;
const isUUID = (value) => /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/g.test(value);
exports.isUUID = isUUID;
/** Returned signed msg by secret */
const hmac = async (msg, secret) => {
    const subtle = getSubtle();
    const enc = new TextEncoder();
    const keyBuf = enc.encode(secret);
    const buf = enc.encode(msg);
    const key = await subtle.importKey('raw', keyBuf, { name: 'HMAC', hash: { name: 'SHA-256' } }, true, ['sign']);
    const signed = await subtle.sign({ name: 'HMAC', hash: 'SHA-256' }, key, buf);
    return (0, base64_js_1.fromByteArray)(new Uint8Array(signed));
};
exports.hmac = hmac;
const hashBody = async (body) => {
    if (body == null || body === '') {
        return '';
    }
    const subtle = getSubtle();
    const buf = new TextEncoder().encode(body);
    const hash = await subtle.digest('SHA-256', buf);
    return (0, base64_js_1.fromByteArray)(new Uint8Array(hash));
};
exports.hashBody = hashBody;
const encrypt = async (key, message) => {
    let cryptoKey = await importKey(key ?? undefined);
    cryptoKey = await deriveKey(cryptoKey, key ?? undefined);
    const enc = new TextEncoder();
    const encoded = enc.encode(message);
    const subtle = getSubtle();
    const crypto = getCrypto();
    const iv = crypto.getRandomValues(new Uint8Array(12));
    const buffer = await subtle.encrypt({
        name: 'AES-GCM',
        iv,
    }, cryptoKey, encoded);
    const merged = appendBuffer(iv, buffer);
    return {
        byteString: (0, base64_js_1.fromByteArray)(merged),
        cryptoKey,
    };
};
exports.encrypt = encrypt;
const decrypt = async ({ key, cryptoKey, byteString }) => {
    if (!cryptoKey) {
        cryptoKey = await createCryptoKey(key);
    }
    const subtle = getSubtle();
    const byteArray = (0, base64_js_1.toByteArray)(byteString);
    const iv = byteArray.slice(0, 12);
    const cipherText = byteArray.slice(12, byteArray.length);
    const decrypted = (await subtle.decrypt({
        name: 'AES-GCM',
        iv,
    }, cryptoKey, cipherText));
    const dec = new TextDecoder();
    return dec.decode(decrypted);
};
exports.decrypt = decrypt;
const toHexString = async (input) => {
    const arr = new TextEncoder().encode(input);
    const arrBuffer = await crypto.subtle.digest('SHA-1', arr);
    const byteArray = new Uint8Array(arrBuffer);
    const hexArray = [...byteArray].map((value) => {
        const hexCode = value.toString(16);
        return hexCode.padStart(2, '0');
    });
    return hexArray.join('');
};
exports.toHexString = toHexString;
const importKey = (key) => {
    const enc = new TextEncoder();
    const buffer = enc.encode(key);
    const subtle = getSubtle();
    return subtle.importKey('raw', buffer, 'PBKDF2', false, ['deriveBits', 'deriveKey']);
};
const deriveKey = (keyMaterial, key) => {
    const enc = new TextEncoder();
    const salt = enc.encode(key);
    const subtle = getSubtle();
    return subtle.deriveKey({
        'name': 'PBKDF2',
        salt,
        'iterations': 100000,
        'hash': 'SHA-256',
    }, keyMaterial, { 'name': 'AES-GCM', 'length': 256 }, true, ['encrypt', 'decrypt']);
};
const appendBuffer = (buffer1, buffer2) => {
    const merged = new Uint8Array((buffer1.byteLength + buffer2.byteLength));
    merged.set(new Uint8Array(buffer1), 0);
    merged.set(new Uint8Array(buffer2), buffer1.byteLength);
    return merged;
};
const createCryptoKey = async (key) => {
    const cryptoKey = await importKey(key);
    return deriveKey(cryptoKey, key);
};
const isLatestNode = () => {
    if (typeof process === 'undefined') {
        return false;
    }
    const nodeVersion = process.versions.node;
    if (!nodeVersion) {
        return false;
    }
    const versionMajor = nodeVersion.split('.')[0];
    return versionMajor ? parseInt(versionMajor) >= 15 : false;
};
/** Look up available crypto */
const getCrypto = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (isLatestNode() && global.webcrypto) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return global.webcrypto;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, disable-autofix/@typescript-eslint/no-unnecessary-condition
    if (typeof global !== 'undefined' && global != null && global.WebCrypto != null) {
        // Node crypto
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        return new global.WebCrypto();
    }
    return crypto;
};
/** Look up for an available SubtleCrypto */
const getSubtle = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (isLatestNode() && global.subtle) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return global.subtle;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, disable-autofix/@typescript-eslint/no-unnecessary-condition
    if (typeof global !== 'undefined' && global != null && global.WebCrypto != null) {
        // Node crypto
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
        const webcrypto = new global.WebCrypto();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return webcrypto.subtle;
    }
    // yay, Safari! iOS 11 has a non prefixed version
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, disable-autofix/@typescript-eslint/no-unnecessary-condition
    return crypto.subtle ?? crypto.webkitSubtle;
};
