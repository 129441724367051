import { Component, Input, inject } from '@angular/core';
import { AngularRouterLink, DataDisplayListItem, DataDisplayService, UfControlGroup } from '@unifii/library/common';
import { CompoundType, StructureNodeType, StructureNodeVariation } from '@unifii/sdk';

import { UcCompound, UcDefinition, UcMedia, UcPage, UcProject, UcTable, UcView } from 'client';
import { ConsoleNameLabel } from 'constant';
import { FieldReferenceHelper } from 'helpers/field-reference-helper';
import { TableSourceTypeLabelPipe } from 'pipes/table-source-type-label.pipe';

import { StructureEditorService } from '../structure-editor.service';
import { canCopyStructureNode, isNodeContentPublished } from '../structure-functions';
import { StructureEditorNodeOrRoot, isStructureEditorNodeOrRoot } from '../structure-model';

@Component({
    selector: 'uc-node-content-info',
    templateUrl: './node-content-info.html',
})
export class NodeContentInfoComponent {

    protected ready: boolean;
    protected contentInfo: DataDisplayListItem[] | null;
    protected contentLink: AngularRouterLink | null;
    protected contentTypeLabel: string;
    protected unpublishedWarningMessage: string | null;
    protected canCopy = false;

    private _control: UfControlGroup;
    private project = inject(UcProject);
    private media = inject(UcMedia);
    private tableSourceTypeLabel = inject(TableSourceTypeLabelPipe);
    private dataDisplayService = inject(DataDisplayService);
    private service = inject(StructureEditorService);

    @Input({ required: true })
    set control(v: UfControlGroup) {
        this._control = v;
        const node = this.node;

        this.canCopy = !v.disabled && isStructureEditorNodeOrRoot(node) && canCopyStructureNode(node);

        // Reset
        void this.loadContentInfo();
    }

    get control() {
        return this._control;
    }

    protected get node(): StructureEditorNodeOrRoot | StructureNodeVariation {
        return this.control.getRawValue() as StructureEditorNodeOrRoot | StructureNodeVariation;
    }

    protected copy() {
        if (isStructureEditorNodeOrRoot(this.node)) {
            this.service.copyAndInsert(this.node, this.control);
        }
    }

    // eslint-disable-next-line complexity
    private async loadContentInfo() {

        this.ready = false;

        this.contentTypeLabel = FieldReferenceHelper.getFieldReference(this.node, CompoundType.Structure).label;
        this.contentLink = null;
        this.unpublishedWarningMessage = null;

        const content = await this.getContentByNode(this.node);

        this.contentInfo = [];

        // PDF Viewer
        if (this.node.type === StructureNodeType.PdfViewer && this.node.id) {
            const media = await this.media.getDetail(this.node.id);

            this.contentInfo.push({ term: ConsoleNameLabel, data: media.consoleName });
            this.contentInfo.push({ term: 'Uploaded At', data: this.dataDisplayService.displayAsString(media.uploadedAt) });
        }

        if (content) {
            // Collection
            if (this.node.type === StructureNodeType.Collection) {
                this.contentInfo.push({ term: 'Identifier', data: (content as UcDefinition).identifier });
                this.contentInfo.push({ term: ConsoleNameLabel, data: (content as UcDefinition).consoleName });
            }
            // CollectionItem
            if (this.node.type === StructureNodeType.CollectionItem) {
                this.contentInfo.push({ term: 'Collection', data: this.node.definitionLabel });
                this.contentInfo.push({ term: ConsoleNameLabel, data: (content as UcCompound)._consoleName });
            }
            // Page
            if (this.node.type === StructureNodeType.Page) {
                this.contentInfo.push({ term: ConsoleNameLabel, data: (content as UcPage)._consoleName });
                this.contentInfo.push({ term: 'Identifier', data: this.node.definitionIdentifier });
            }
            // View
            if (this.node.type === StructureNodeType.View) {
                this.contentInfo.push({ term: ConsoleNameLabel, data: (content as UcView)._consoleName });
                this.contentInfo.push({ term: 'Identifier', data: this.node.definitionIdentifier });
            }
            // Form
            if (this.node.type === StructureNodeType.Form) {
                this.contentInfo.push({ term: ConsoleNameLabel, data: (content as UcDefinition).consoleName });
                this.contentInfo.push({ term: 'Identifier', data: this.node.definitionIdentifier });
                this.contentInfo.push({ term: 'Form Data Repository', data: (content as UcDefinition).bucket });
            }
            // Table
            if (this.node.type === StructureNodeType.FormBucket) {
                const tableContent = content as UcTable;

                this.contentInfo.push({ term: 'Title', data: this.node.definitionLabel });
                this.contentInfo.push({ term: ConsoleNameLabel, data: tableContent.consoleName });
                this.contentInfo.push({ term: 'Identifier', data: tableContent.identifier });
                this.contentInfo.push({ term: 'Source Type', data: this.tableSourceTypeLabel.transform(tableContent.sourceType) });

                if (tableContent.source) {
                    this.contentInfo.push({ term: 'Source', data: tableContent.source });
                }
            }

            // common
            if (isNodeContentPublished(this.node)) {
                this.unpublishedWarningMessage = null;
            } else {
                if (content.lastPublishedAt == null) {
                    this.unpublishedWarningMessage = `This content is unpublished`;
                } else {
                    this.unpublishedWarningMessage = `The current version of this content is unpublished`;
                }
            }
            if (content.publishState) {
                this.contentInfo.push({ term: 'State', data: content.publishState });
            }
            if (content.lastModifiedAt) {
                this.contentInfo.push({ term: 'Last Modified', data: this.dataDisplayService.displayAsString(content.lastModifiedAt) });
            }
            if (content.lastPublishedAt) {
                this.contentInfo.push({ term: 'Last Published', data: this.dataDisplayService.displayAsString(content.lastPublishedAt) });
            }
        }

        this.contentLink = this.getContentLink();
        this.ready = true;
    }

    private getContentLink(): AngularRouterLink | null {
        switch (this.node.type) {
            case StructureNodeType.Collection: return ['..', 'content', 'collections', this.node.definitionIdentifier];
            case StructureNodeType.CollectionItem: return ['..', 'content', 'collections', this.node.definitionIdentifier, this.node.id];
            case StructureNodeType.View: return ['..', 'content', 'views', this.node.id];
            case StructureNodeType.Page: return ['..', 'content', 'pages', this.node.id];
            case StructureNodeType.Form: return ['..', 'forms', this.node.id];
            case StructureNodeType.FormBucket: return ['..', 'tables', this.node.id];
            case StructureNodeType.PdfViewer: return ['/', 'assets', 'media', this.node.id];
        }

        return null;
    }

    private getContentByNode(node: StructureEditorNodeOrRoot | StructureNodeVariation): Promise<UcPage | UcView | UcDefinition | UcCompound> | null {
        switch (node.type) {
            case StructureNodeType.Page:
                return node.id ? this.project.getPage(node.id) : null;
            case StructureNodeType.View:
                return node.id ? this.project.getView(node.id) : null;
            case StructureNodeType.Form:
                return node.definitionIdentifier ? this.project.getForm(node.definitionIdentifier) : null;
            case StructureNodeType.Collection:
                return node.definitionIdentifier ? this.project.collection(node.definitionIdentifier).getDefinition() : null;
            case StructureNodeType.CollectionItem:
                return node.definitionIdentifier && node.id ? this.project.collection(node.definitionIdentifier).getItem(node.id) : null;
            case StructureNodeType.FormBucket:
                return node.id ? this.project.getTable(`${node.id}`) : null;
        }

        return null;
    }

}
