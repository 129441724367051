<uf-autocomplete [options]="options" [control]="control" [autofocus]="autofocus" [(value)]="value"
    (searchChange)="filter($event)" (valueChange)="valueChange.emit($event)" nameProperty="title">
    <ng-template #listBox let-options="options" let-select="select" let-active="active">
        <ul class="uf-list">
            <li *ngFor="let option of options; let i = index" [class.active]="i === active" (click)="select(option)"
                class="uf-list-button">
                <div class="image"
                    *ngIf="option.url && option.type !== 'File' && option.type !== 'Audio' && option.type !== 'Video'">
                    <img [src]="option?.url + '?w=48&h=48&mode=contain'" alt="option.title">
                </div>
                <span class="content">
                    <h4>{{option.consoleName}}</h4>
                    <p>{{option.description}}</p>
                </span>
            </li>
        </ul>
    </ng-template>
</uf-autocomplete>