<div *ngIf="control" [formGroup]="control" class="uf-box flat pad-sm-sides uf-grid row-gap-sm pad-sm-top pad-sm-bottom">

    <ng-template [ngIf]="fieldsControl.showError && fieldsControl.errors?.message">
        <uf-message [content]="fieldsControl.errors?.message" icon="error" class="error col-12" />
    </ng-template>

    <uf-expander class="col-12 uf-box flat">
        <div expanderHeader class="uf-app-bar flat">
            <div class="title primary">Form</div>
            <uc-content-description *ngIf="descriptionControl" [control]="descriptionControl" mode="icon" />
            <ng-template [ngIf]="isFormInvalid" [ngIfElse]="formValid">
                <uf-icon name="error" class="error" />
            </ng-template>
            <ng-template #formValid>
                <a *ngIf="fieldsControl.length" (click)="copyFields()" title="Copy fields" class="uf-action tertiary">
                    <uf-icon name="copy" />
                </a>
                <a (click)="pasteFields()" title="Paste fields" class="uf-action tertiary">
                    <uf-icon name="paste" />
                </a>
            </ng-template>
        </div>
        <div expanderBody class="uf-grid pad-sides pad-sm-top pad-bottom">
            <uc-content-description *ngIf="descriptionControl" [control]="descriptionControl" mode="view"
                class="col-12" />
            <uf-text [formControlName]="definitionKeys.Label" label="Title" class="col-12" />
            <uf-text [formControlName]="definitionKeys.ConsoleName" [label]="consoleNameLabel" class="col-12" />
            <uf-text [formControlName]="definitionKeys.Identifier" [maxLength]="identifierMaxLength" label="Identifier"
                class="col-12" />
            <uf-message *ngIf="showIdentifierWarning" icon="warningSolid" class="x-small warning col-12">
                <p>Identifier is too long</p>
            </uf-message>
            <uf-text [formControlName]="definitionKeys.SequenceNumberFormat" label="Form Number Format"
                placeholder="ABC-######" class="col-12" />
            <ng-container [formGroupName]="definitionKeys.Settings">
                <uf-text [formControlName]="settingsKeys.RequiredSuffix" label="Mark required fields with:"
                    placeholder="*" class="col-12" />
                <uf-text [formControlName]="settingsKeys.OptionalSuffix" label="Mark optional fields with:"
                    placeholder="(Optional)" class="col-12" />
                <uf-checkbox [formControlName]="settingsKeys.ScrollToActiveSection"
                    label="Scroll to first active section" class="col-12" />
                <uf-checkbox [formControlName]="settingsKeys.IsNavigationEnabled" label="Enable navigation"
                    class="col-12" />
            </ng-container>
            <uf-chips [formControlName]="definitionKeys.Tags" [allowCustom]="true" [options]="tagsResults"
                (searchChange)="searchTags($event)" label="Tags" class="col-12" />
            <!-- TODO - Remove ReportableMetaFields -->
            <!-- <uf-chips [formControlName]="definitionKeys.ReportableMetaFields"
                (searchChange)="searchReportableMetaFields($event)" [options]="reportableMetaFieldsResults"
                [allowDuplicates]="false" label="Reportable Metadata" class="col-12"/> -->
        </div>
    </uf-expander>

    <uf-expander class="col-12 uf-box flat">
        <div expanderHeader class="uf-app-bar flat">
            <div class="title primary">Form Data Repository</div>
            <uf-icon *ngIf="isFormDataRepositoryInvalid" name="error" class="error" />
            <a *ngIf="control.get(definitionKeys.Bucket)?.enabled" (click)="editFormDataRepository()" title="Edit"
                class="uf-action tertiary">
                <uf-icon name="edit" />
            </a>
        </div>
        <div expanderBody class="uf-grid pad-sides pad-sm-top pad-bottom">
            <uf-data-display-list [items]="formDataRepositoryInfo" class="small-label col-12" />
            <uf-message *ngIf="showBucketWarning" icon="warningSolid" class="x-small warning col-12">
                <p>Form Data Repository is too long</p>
            </uf-message>
        </div>
    </uf-expander>

</div>