import { Injectable, OnDestroy, inject } from '@angular/core';
import { TableContainerManager, TableInputManager, TableInputs } from '@unifii/components';
import { DataDisplayIconValue, FilterEntry, FilterValue, HierarchyUnitProvider, TableAction, TableConfig, TableConfigColumn, ToastService } from '@unifii/library/common';
import { Subject, Subscription } from 'rxjs';

import { PermissionPrincipalType, UcRole, UcRoles } from 'client';
import { PermissionChangeAction, PermissionsManagerService } from 'components/permissions/permissions-manager.service';

import { RolesDataSource } from './roles-datasource';

@Injectable()
export class RolesTableManager implements TableContainerManager<UcRole, FilterValue, FilterEntry>, OnDestroy {

    tableConfig: TableConfig<UcRole>;
    defaultSort = 'name';
    showSearch = true;
    addActionConfig = true;
    reload = new Subject<void>();
    update = new Subject<TableInputs<FilterValue>>();
    updateItem = new Subject<UcRole | { item: UcRole; trackBy: keyof UcRole }>();
    inputManager: TableInputManager<FilterValue, FilterEntry>;

    private permissionSubscription: Subscription;

    constructor(
        private ucRoles: UcRoles,
        private toastService: ToastService,
        permissionsManager: PermissionsManagerService,
    ) {
        this.inputManager = new TableInputManager([], inject(HierarchyUnitProvider), null, null);

        this.permissionSubscription = permissionsManager.notify.subscribe((info) => {
            if (info.principalType !== PermissionPrincipalType.Role || info.action !== PermissionChangeAction.Edited) {
                return;
            }
            this.reload.next();
        });

        this.tableConfig = {
            id: 'roles',
            columns: this.columns,
            actions: this.actions,
            pageSize: 50,
            selectable: true,
            columnToggles: true,
            rowLink: (role) => role.id ?? [],
        };
    }

    ngOnDestroy(): void {
        this.permissionSubscription.unsubscribe();
    }

    createDataSource(inputs?: TableInputs<FilterValue> | undefined) {
        return new RolesDataSource(this.ucRoles, inputs?.q, inputs?.sort);
    }

    private get columns(): TableConfigColumn<UcRole>[] {
        return [{
            name: 'name',
            label: 'Identifier',
            sortable: true,
        }, {
            name: 'label',
            label: 'Title',
        }, {
            name: 'description',
            label: 'Description',
        }, {
            name: 'permissionsCount',
            label: 'Permissions',
            value: (item) => item.permissionsCount ? {
                icon: 'radioTick',
                colour: 'success',
            } satisfies DataDisplayIconValue : null,
        }];
    }

    private get actions(): TableAction<UcRole>[] {
        return [{
            label: 'Delete',
            icon: 'delete',
            action: (rows) => this.delete(rows.map((r) => r.$implicit)),
        }];
    }

    private async delete(roles: UcRole[]) {
        // Create new reference so array can be modified for printing a meaningful error message
        roles = [...roles];

        try {
            for (let i = (roles.length - 1); i >= 0; i--) {
                const role = roles[i];

                if (!role) {
                    continue;
                }

                await this.ucRoles.delete(role.id as string);
                roles.splice(i, 1);
            }
            this.toastService.success(`Role/s deleted`);
        } catch (e) {
            this.toastService.error(`Failed to delete roles: ${roles.map((r) => r.name).join(', ')}`);
        } finally {
            this.reload.next();
        }
    }

}

