<div class="uf-box">
    <div class="uf-app-bar accent">
        <div class="title">
            {{pageText.title}}
        </div>
        <a [title]="pageText.iconTitle" class="uf-action tertiary">
            <uf-icon [name]="pageText.icon" (click)="switchFilter()" />
        </a>
    </div>
    <div class="uf-grid gap">
        <ng-template [ngIf]="isBasicFilter" [ngIfElse]="advanced">
            <uc-filter-editor *ngIf="basicFiltersControl" [dataProperties]="availableHiddenFilters"
                [parentControl]="form" [filter]="basicFiltersControl.value" [style.marginTop]="0"
                (filterChange)="basicFiltersControl.setValue($event)" class="col-12" />
        </ng-template>
        <ng-template #advanced>
            <uf-textarea *ngIf="advancedFiltersControl" [control]="advancedFiltersControl"
                [value]="advancedFiltersControl.value" (valueChange)="advancedFiltersControl.setValue($event)"
                class="col-12" />
        </ng-template>
    </div>
</div>