import { Component, Input } from '@angular/core';
import { DataPropertyDescriptor, UfControl } from '@unifii/library/common';

@Component({
    selector: 'uc-table-configuration-sort',
    templateUrl: 'table-configuration-sort.html',
})
export class TableConfigurationSortComponent {

    @Input({ required: true }) control: UfControl;
    @Input({ required: true }) sortableColumns: DataPropertyDescriptor[];

}
