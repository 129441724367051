import { ClientDeleteOptions, ClientGetOptions, ClientPostOptions, Table, mergeParams } from '@unifii/sdk';

import { CompoundInfo, DefinitionInfo, TableInfo, UcCollection, UcCompound, UcDefinition, UcPage, UcProject, UcView } from 'client';
import { DefaultPaginationParams } from 'constant';
import { InfoLoader, InfoQueryParams, InfoType } from 'services/table/models';

export class ViewInfoLoader implements InfoLoader {

    type = InfoType.View;

    constructor(private ucProject: UcProject) { }

    list(infoQueryParams: InfoQueryParams, options?: ClientGetOptions): Promise<CompoundInfo[]> {
        const params = mergeParams(DefaultPaginationParams, options?.params, infoQueryParams as Record<string, unknown>);

        return this.ucProject.getViews({ ...options, params });
    }

    get(id: number, options?: ClientGetOptions): Promise<UcView> {
        return this.ucProject.getView(id, options);
    }

    approve(ids: number[], options?: ClientPostOptions): Promise<CompoundInfo[]> {
        return this.ucProject.approveViews(ids, options);
    }

    revert(ids: number[], options?: ClientPostOptions): Promise<CompoundInfo[]> {
        return this.ucProject.revertView(ids, options);
    }

    archive(ids: number[], options?: ClientPostOptions): Promise<CompoundInfo[]> {
        return this.ucProject.archiveView(ids, options);
    }

    delete(id: number, options?: ClientDeleteOptions): Promise<void> {
        return this.ucProject.deleteView(id, options);
    }

}

export class PageInfoLoader implements InfoLoader {

    type = InfoType.Page;

    constructor(private ucProject: UcProject) { }

    list(infoQueryParams: InfoQueryParams, options?: ClientGetOptions): Promise<CompoundInfo[]> {
        const params = mergeParams(DefaultPaginationParams, options?.params, infoQueryParams as Record<string, unknown>);

        return this.ucProject.getPages({ ...options, params });
    }

    get(id: number, options?: ClientGetOptions): Promise<UcPage> {
        return this.ucProject.getPage(id, options);
    }

    approve(ids: number[], options?: ClientPostOptions): Promise<CompoundInfo[]> {
        return this.ucProject.approvePages(ids, options);
    }

    revert(ids: number[], options?: ClientPostOptions): Promise<CompoundInfo[]> {
        return this.ucProject.revertPages(ids, options);
    }

    archive(ids: number[], options?: ClientPostOptions): Promise<CompoundInfo[]> {
        return this.ucProject.archivePages(ids, options);
    }

    delete(id: number, options?: ClientDeleteOptions): Promise<void> {
        return this.ucProject.deletePage(id, options);
    }

}

export class CollectionsInfoLoader implements InfoLoader {

    type = InfoType.Collection;

    constructor(private ucProject: UcProject) { }

    list(infoQueryParams: InfoQueryParams, options?: ClientGetOptions): Promise<DefinitionInfo[]> {
        const params = mergeParams(DefaultPaginationParams, options?.params, swapStateForStatus(infoQueryParams));

        return this.ucProject.getCollections({ ...options, params });
    }

}

export class CollectionCompoundLoader implements InfoLoader {

    type = InfoType.CollectionData;

    constructor(private ucCollection: UcCollection) { }

    list(infoQueryParams: InfoQueryParams, options?: ClientGetOptions): Promise<CompoundInfo[]> {
        return this.ucCollection.get(swapStateForStatus(infoQueryParams), options);
    }

    get(id: number, options?: ClientGetOptions): Promise<UcCompound> {
        return this.ucCollection.getItem(id, options);
    }

    approve(ids: number[], options?: ClientPostOptions): Promise<CompoundInfo[]> {
        return this.ucCollection.approveCollectionItems(ids, options);
    }

    revert(ids: number[], options?: ClientPostOptions): Promise<CompoundInfo[]> {
        return this.ucCollection.revertCollectionItems(ids, options);
    }

    archive(ids: number[], options?: ClientPostOptions): Promise<CompoundInfo[]> {
        return this.ucCollection.archiveCollectionItems(ids, options);
    }

    delete(id: number, options?: ClientDeleteOptions): Promise<void> {
        return this.ucCollection.deleteCollectionItem(id, options);
    }

}

export class FormInfoLoader implements InfoLoader {

    type = InfoType.Form;

    constructor(private ucProject: UcProject) { }

    list(infoQueryParams: InfoQueryParams, options?: ClientGetOptions): Promise<DefinitionInfo[]> {
        const params = mergeParams(DefaultPaginationParams, options?.params, swapStateForStatus(infoQueryParams));

        return this.ucProject.getForms({ ...options, params });
    }

    get(id: number, options?: ClientGetOptions): Promise<UcDefinition> {
        return this.ucProject.getForm(id as any as string, options);
    }

    approve(ids: number[], options?: ClientPostOptions): Promise<DefinitionInfo[]> {
        return this.ucProject.approveForms(ids.map((id) => `${id}`), options);
    }

    revert(ids: number[], options?: ClientPostOptions): Promise<DefinitionInfo[]> {
        return this.ucProject.revertForms(ids.map((id) => `${id}`), options);
    }

    archive(ids: number[], options?: ClientPostOptions): Promise<DefinitionInfo[]> {
        return this.ucProject.archiveForms(ids.map((id) => `${id}`), options);
    }

    delete(id: number, options?: ClientDeleteOptions): Promise<void> {
        return this.ucProject.deleteForm(id as any as string, options);
    }

}

export class TableInfoLoader implements InfoLoader {

    type = InfoType.Table;

    constructor(private ucProject: UcProject) { }

    list(infoQueryParams: InfoQueryParams, options?: ClientGetOptions): Promise<TableInfo[]> {
        const params = mergeParams(DefaultPaginationParams, options?.params, swapStateForStatus(infoQueryParams));

        return this.ucProject.getTables({ ...options, params });
    }

    get(id: number, options?: ClientGetOptions): Promise<Table> {
        return this.ucProject.getTable(id as any as string, options);
    }

    approve(ids: number[], options?: ClientPostOptions): Promise<TableInfo[]> {
        return this.ucProject.approveTables(ids, options);
    }

    revert(ids: number[], options?: ClientPostOptions): Promise<TableInfo[]> {
        return this.ucProject.revertTables(ids.map((id) => `${id}`), options);
    }

    archive(ids: number[], options?: ClientPostOptions): Promise<TableInfo[]> {
        return this.ucProject.archiveTables(ids.map((id) => `${id}`), options);
    }

    delete(id: number, options?: ClientDeleteOptions): Promise<void> {
        return this.ucProject.deleteTable(id as any as string, options);
    }

}

const swapStateForStatus = (params: InfoQueryParams): Record<string, unknown> => {
    // Map state to status
    if (params.state != null) {
        (params as Record<string, unknown>).status = params.state;
        delete params.state;
    }

    return params as Record<string, unknown>;
};
