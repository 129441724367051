import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { DataDisplayListItem, ExpandersService, Modal, ModalData, ModalRuntime } from '@unifii/library/common';
import { FieldType } from '@unifii/sdk';

import { UcField } from 'client';
import { DataSourceTypeLabelPipe, ToDisplayNamePipe } from 'pipes';

import { FormEditorCache } from '../form-editor-cache';
import { FormEditorFunctions } from '../form-editor-functions';

export interface FormSummaryInfoData {
    title: string;
    items: DataDisplayListItem[];
}

export interface FormSummaryInfoModalInfo {
    field?: UcField;
    cache: FormEditorCache;
}

@Component({
    templateUrl: './form-summary-info-modal.html',
    selector: 'uc-form-summary-info-modal',
    providers: [ExpandersService],
})
export class FormSummaryInfoModalComponent implements Modal<FormSummaryInfoModalInfo, void>, OnInit {

    @HostBinding('class.uc-form-card') formDataClass = true;

    data = inject<FormSummaryInfoModalInfo>(ModalData);
    runtime = inject<ModalRuntime<FormSummaryInfoModalInfo, void>>(ModalRuntime);

    protected validators: FormSummaryInfoData[] = [];
    protected variations: FormSummaryInfoData[] = [];
    protected options: FormSummaryInfoData[] = [];
    protected help: string | undefined;

    private displayPipe = inject(ToDisplayNamePipe);
    private dataSourceTypeLabelPipe = inject(DataSourceTypeLabelPipe);
    private readonly nameTerm = 'Name';

    ngOnInit() {
        const { field, cache } = this.data;
        const validators = field?.validators ?? [];
        const variations = field?.variations ?? [];

        if (field?.type !== FieldType.Content && field?.help) {
            this.help = field.help;
        }

        for (const validator of validators) {
            const validatorInfo = FormEditorFunctions.validatorsInfoByType(validator.type, this.displayPipe);

            this.validators.push({
                title: validatorInfo.typeLabel ?? validator.type,
                items: [{
                    term: validatorInfo.valueLabel ?? validator.type,
                    data: validator.value,
                }, {
                    term: 'Message',
                    data: validator.message,
                }],
            });
        }

        for (const variation of variations) {
            this.variations.push({
                title: variation.name,
                items: [{
                    term: this.nameTerm,
                    data: variation.name,
                }, {
                    term: 'Condition',
                    data: variation.condition,
                }],
            });
        }

        if (field) {
            void this.buildOptionsSection(field, cache);
        }
    }

    private async buildOptionsSection(field: UcField, cache: FormEditorCache) {
        const options = field.options ?? [];

        if (field.dataSourceConfig) {
            this.options.push({
                title: 'Data Source',
                items: [{
                    term: 'Type',
                    data: this.dataSourceTypeLabelPipe.transform(field.dataSourceConfig.type),
                },
                {
                    term: this.nameTerm,
                    data: await FormEditorFunctions.getDataSourceName(cache, {
                        type: field.dataSourceConfig.type,
                        id: field.dataSourceConfig.id,
                    }),
                }],
            });
        }

        for (const option of options) {
            this.options.push({
                title: option.name,
                items: [{
                    term: this.nameTerm,
                    data: option.name,
                },
                {
                    term: 'Identifier',
                    data: option.identifier,
                }],
            });
        }
    }

}
