"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataType = void 0;
var DataType;
(function (DataType) {
    // PRIMITIVES
    // eslint-disable-next-line id-denylist
    DataType["Number"] = "Number";
    // eslint-disable-next-line id-denylist
    DataType["Boolean"] = "Boolean";
    // eslint-disable-next-line id-denylist
    DataType["String"] = "String";
    DataType["Phone"] = "Phone";
    DataType["Email"] = "Email";
    DataType["Website"] = "Website";
    DataType["Date"] = "Date";
    DataType["Time"] = "Time";
    DataType["DateTime"] = "DateTime";
    DataType["OffsetDateTime"] = "OffsetDateTime";
    DataType["Signature"] = "Signature";
    // OBJECTS
    DataType["Choice"] = "Choice";
    // eslint-disable-next-line @typescript-eslint/no-shadow
    DataType["ZonedDateTime"] = "ZonedDateTime";
    DataType["Cost"] = "Cost";
    // eslint-disable-next-line @typescript-eslint/no-shadow
    DataType["GeoLocation"] = "GeoLocation";
    // eslint-disable-next-line @typescript-eslint/no-shadow
    DataType["Address"] = "Address";
    DataType["Link"] = "Link";
    // eslint-disable-next-line @typescript-eslint/no-shadow
    DataType["HierarchyStep"] = "HierarchyStep";
    DataType["HierarchyUnit"] = "HierarchyUnit";
    // eslint-disable-next-line @typescript-eslint/no-shadow
    DataType["DataSeed"] = "DataSeed";
    // eslint-disable-next-line @typescript-eslint/no-shadow
    DataType["Claim"] = "Claim";
    // eslint-disable-next-line @typescript-eslint/no-shadow
    DataType["ClaimsRecord"] = "ClaimsRecord";
    // eslint-disable-next-line @typescript-eslint/no-shadow
    DataType["CompanyInfo"] = "CompanyInfo";
    // eslint-disable-next-line @typescript-eslint/no-shadow
    DataType["Manager"] = "Manager";
    // ARRAYS
    DataType["StringArray"] = "StringArray";
    DataType["MultiChoice"] = "MultiChoice";
    DataType["Repeat"] = "Repeat";
    DataType["FileList"] = "FileList";
    DataType["ImageList"] = "ImageList";
    DataType["SoundList"] = "SoundList";
    DataType["VideoList"] = "VideoList";
    // eslint-disable-next-line @typescript-eslint/no-shadow
    DataType["HierarchyPath"] = "HierarchyPath";
    DataType["HierarchyPaths"] = "HierarchyPaths";
})(DataType || (exports.DataType = DataType = {}));
