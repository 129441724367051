<div *ngIf="isMfaOptionalControl || mfaControl" [ngClass]="cssClasses" class="uf-box">
    <div class="uf-app-bar flat accent">
        <div class="title">
            {{ commonTK.MfaLabel | translate }}
        </div>
    </div>
    <div class="uf-grid pad">
        <uf-checkbox *ngIf="isMfaOptionalControl" [label]="commonTK.MfaOptionalLabel | translate"
            [control]="isMfaOptionalControl" class="col-12" />

        <ul *ngIf="mfaControl" [formGroup]="mfaControl" class="uf-list uf-box flat col-12">
            <li *ngIf="mfaControl.get(userMfaInfoKeys.VirtualCode)" class="pad-left pad">
                <uf-virtual-mfa-code-input [formControlName]="userMfaInfoKeys.VirtualCode" (valueChange)="refresh()" />
            </li>
        </ul>
    </div>
</div>