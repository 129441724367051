<uf-panel class="content-pane container">
    <uf-skeleton *ngIf="!form" />
    <div *ngIf="form" class="uf-container-lg">
        <div class="uf-grid row-gap-lg gap-top">
            <uc-table-configuration-details [form]="form" class="col-12" />
            <uc-table-configuration-sort *ngIf="sortControl" [control]="sortControl" [sortableColumns]="sortableColumns"
                class="col-12" />
            <uc-table-columns *ngIf="columnsControl" [availableColumns]="availableColumns" [parentControl]="form"
                [columns]="columnsControl.value" (columnsChange)="columnsControl.setValue($event)" class="col-12" />
            <uc-visible-filters *ngIf="visibleFiltersControl" [availableFilters]="availableVisibleFilters"
                [parentControl]="form" [filters]="visibleFiltersControl.value"
                (filtersChange)="visibleFiltersControl.setValue($event)" class="col-12" />
            <uc-table-configuration-hidden-filters [availableHiddenFilters]="availableHiddenFilters" [form]="form"
                class="col-12" />
        </div>
    </div>
</uf-panel>