<div class="header">
    <button (click)="close()" title="Close" type="button" class="uf-action tertiary">
        <uf-icon name="close" />
    </button>
    <h3>Clone</h3>
</div>

<uf-panel *ngIf="form" class="container">
    <div [formGroup]="form" class="uf-grid pad-sides">
        <div class="col-12">
            <h4>Target</h4>
        </div>
        <uf-select [formControlName]="typeControlKey" [options]="types" (valueChange)="changeType(); reset()"
            placeholder="Select a type" nameProperty="name" class="col-6" />
        <uf-autocomplete *ngIf="typeControl.value" [formControlName]="targetsControlKey" [options]="targets"
            (valueChange)="reset()" (searchChange)="filterTarget($event)" placeholder="Select" nameProperty="name"
            class="col-6" />
        <div class="col-12">
            <h4>Permissions</h4>
            <ul class="uf-list uf-box flat">
                <li *ngFor="let item of items" class="pad-sm">
                    <div class="row center-all">
                        <uf-icon *ngIf="item.cloned" name="successSolid" class="success" />
                        <uf-icon *ngIf="item.error" name="errorSolid" class="error" />
                        <uf-icon *ngIf="!item.error && !item.cloned" name="" />
                        <p class="small pad-sm-left">{{item.pathLabel}}</p>
                    </div>
                    <span *ngIf="item.error" class="error-text">
                        {{item.error.message}}
                    </span>
                </li>
            </ul>
        </div>
    </div>
</uf-panel>

<div class="row space-children pad">
    <button [disabled]="busy" (click)="close()" type="button" class="uf-button tertiary right">Cancel</button>
    <button *ngIf="!cloned" [disabled]="busy" (click)="clone()" type="button" class="uf-button primary">Clone</button>
    <button *ngIf="cloned" [disabled]="busy" (click)="close()" type="button" class="uf-button primary">Close</button>
</div>