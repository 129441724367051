"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompaniesClient = void 0;
const constants_1 = require("../constants");
const functions_1 = require("../functions");
/** Client for v0/companies APIs */
class CompaniesClient {
    constructor(client) {
        this.client = client;
    }
    query(query, options) {
        return this.client.get(this.url(), { ...options, query: (0, functions_1.stringifyQuery)(query) });
    }
    get(id, options) {
        return this.client.get(this.url(id), options);
    }
    save(company, options) {
        if (!company.id) {
            // Add
            return this.client.post(this.url(), { ...options, body: company });
        }
        // Update
        return this.client.put(this.url(company.id), company, options);
    }
    url(...extra) {
        return this.client.buildUrl(constants_1.APIPath.Companies, ...extra);
    }
}
exports.CompaniesClient = CompaniesClient;
