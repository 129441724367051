export * from './node-details';
export * from './node-field.component';
export * from './structure-editor.component';
export * from './structure-model';

import { STRUCTURE_NODE_DETAILS_COMPONENTS } from './node-details';
import { NodeFieldComponent } from './node-field.component';
import { StructureEditorComponent } from './structure-editor.component';

export const STRUCTURE_COMPONENTS = [
    STRUCTURE_NODE_DETAILS_COMPONENTS,
    StructureEditorComponent,
    NodeFieldComponent,
];
