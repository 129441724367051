import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

// TODO replace for iterable and move it into the library
export const flattenControls = (control: AbstractControl, key = 'root'): { key: string; control: AbstractControl }[] => {

    let extracted: { key: string; control: AbstractControl }[] = [{ key, control }];

    if (control instanceof FormArray) {
        const children = control.controls.map((c, i) => flattenControls(c, `${key}[${i}]`));

        extracted = extracted.concat(...children);
    }

    if (control instanceof FormGroup) {
        const children = Object.keys(control.controls).map((k) => {
            const c = control.get(k);

            return c ? flattenControls(c, `${key}.${k}`) : [];
        });

        extracted = extracted.concat(...children);
    }

    return extracted;
};
