import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, FilterComponentRegistry, FilterEntries } from '@unifii/library/common';

import { UcProject } from 'client';
import { CollectionService, collectionServiceFactory } from 'pages/content/collections/collection-service';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { InfoFilterFactory } from 'services/table/info-filter-factory';
import { InfoFilterRegistry } from 'services/table/info-filter-registry';
import { CollectionCompoundLoader } from 'services/table/info-loaders';
import { InfoTableManager } from 'services/table/info-table-manager';
import { InfoLoader, InfoType } from 'services/table/models';
import { TitleService } from 'services/title.service';

const createLoader = (service: CollectionService) => new CollectionCompoundLoader(service.ucCollection);

const createFilters = (filterFactory: InfoFilterFactory) => filterFactory.create(InfoType.CollectionData);

@Component({
    providers: [
        { provide: CollectionService, useFactory: collectionServiceFactory, deps: [ActivatedRoute, UcProject] },
        { provide: FilterComponentRegistry, useClass: InfoFilterRegistry },
        { provide: FilterEntries, useFactory: createFilters, deps: [InfoFilterFactory] },
        { provide: InfoLoader, useFactory: createLoader, deps: [CollectionService] },
        { provide: TableContainerManager, useClass: InfoTableManager },
    ],
    template: `
        <div ufMasterDetail>
            <uf-table-container (addItem)="addItem()" ufSyncRoute class="accent list-md pad-none">
                <uf-breadcrumbs [breadcrumbs]="breadcrumbs" title class="large" />
            </uf-table-container>
        </div>
        <router-outlet />
    `,
})
export class CollectionDataComponent implements OnInit {

    @HostBinding('class.stretch-component') class = true;

    breadcrumbs: Breadcrumb[];

    private service = inject(CollectionService);
    private route = inject(ActivatedRoute);
    private breadcrumbService = inject(BreadcrumbService);
    private manager = inject<InfoTableManager>(TableContainerManager);
    private titleService = inject(TitleService);

    async ngOnInit() {
        await this.service.definitionLoadPromise;
        const consoleName = this.service.definition.consoleName;

        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [consoleName]);
        this.titleService.updateTitle(consoleName);
    }

    addItem() {
        void this.manager.addActionCallback();
    }

}
