class CleanPasswords {
  constructor({
    substr,
    buffer,
    limit,
    trieRoot
  }) {
    this.finalPasswords = [];
    this.substr = substr;
    this.buffer = buffer;
    this.limit = limit;
    this.trieRoot = trieRoot;
  }
  getAllPossibleSubsAtIndex(index) {
    const nodes = [];
    let cur = this.trieRoot;
    for (let i = index; i < this.substr.length; i += 1) {
      const character = this.substr.charAt(i);
      cur = cur.getChild(character);
      if (!cur) {
        break;
      }
      nodes.push(cur);
    }
    return nodes;
  }
  // eslint-disable-next-line complexity,max-statements
  helper({
    onlyFullSub,
    isFullSub,
    index,
    subIndex,
    changes
  }) {
    if (this.finalPasswords.length >= this.limit) {
      return;
    }
    if (index === this.substr.length) {
      if (onlyFullSub === isFullSub) {
        this.finalPasswords.push({
          password: this.buffer.join(''),
          changes
        });
      }
      return;
    }
    // first, exhaust all possible substitutions at this index
    const nodes = [...this.getAllPossibleSubsAtIndex(index)];
    let hasSubs = false;
    // iterate backward to get wider substitutions first
    for (let i = index + nodes.length - 1; i >= index; i -= 1) {
      const cur = nodes[i - index];
      if (cur.isTerminal()) {
        hasSubs = true;
        const subs = cur.subs;
        // eslint-disable-next-line no-restricted-syntax
        for (const sub of subs) {
          this.buffer.push(sub);
          const newSubs = changes.concat({
            i: subIndex,
            letter: sub,
            substitution: cur.parents.join('')
          });
          // recursively build the rest of the string
          this.helper({
            onlyFullSub,
            isFullSub,
            index: i + 1,
            subIndex: subIndex + sub.length,
            changes: newSubs
          });
          // backtrack by ignoring the added postfix
          this.buffer.pop();
          if (this.finalPasswords.length >= this.limit) {
            return;
          }
        }
      }
    }
    // next, generate all combos without doing a substitution at this index
    // if a partial substitution is requested or there are no substitutions at this index
    if (!onlyFullSub || !hasSubs) {
      const firstChar = this.substr.charAt(index);
      this.buffer.push(firstChar);
      this.helper({
        onlyFullSub,
        isFullSub: isFullSub && !hasSubs,
        index: index + 1,
        subIndex: subIndex + 1,
        changes
      });
      this.buffer.pop();
    }
  }
  getAll() {
    // only full substitution
    this.helper({
      onlyFullSub: true,
      isFullSub: true,
      index: 0,
      subIndex: 0,
      changes: []
    });
    // only partial substitution
    this.helper({
      onlyFullSub: false,
      isFullSub: true,
      index: 0,
      subIndex: 0,
      changes: []
    });
    return this.finalPasswords;
  }
}
const getCleanPasswords = (string, limit, trieRoot) => {
  const helper = new CleanPasswords({
    substr: string,
    buffer: [],
    limit,
    trieRoot
  });
  return helper.getAll();
};

export { getCleanPasswords as default };

