"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExternalDataSourcesClient = void 0;
const constants_1 = require("../constants");
/** Client for v0/projects/:id/external-data-sources/:externalDataSourceId APIs */
class ExternalDataSourcesClient {
    constructor(client, projectContentOptions) {
        this.client = client;
        this.projectContentOptions = projectContentOptions;
    }
    query(externalDataSourceId, options) {
        return this.client.get(this.url(externalDataSourceId), options);
    }
    get(externalDataSourceId, itemId, options) {
        return this.client.get(this.url(externalDataSourceId, itemId), options);
    }
    url(externalDataSourceId, ...args) {
        const parts = [
            constants_1.APIPath.Projects,
            this.projectContentOptions.projectId,
            constants_1.APIPath.ExternalDataSources,
            externalDataSourceId,
            ...args,
        ];
        return this.client.buildUrl(...parts);
    }
}
exports.ExternalDataSourcesClient = ExternalDataSourcesClient;
