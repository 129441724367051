"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestManager = void 0;
const date_fns_1 = require("date-fns");
const http_status_codes_1 = require("http-status-codes");
const client_models_1 = require("./client-models");
const functions_1 = require("./functions");
const lock_manager_1 = require("./lock-manager");
const uploader_1 = require("./uploader");
/**
 * RequestManager handles all SDK requests, responsible for:
 *  - Queuing all requests behind a single promise which gets access from storage or via http
 *  - Retrying unauthorized requests when a there is a valid refresh token
 *  - Attaching external interceptor
 */
class RequestManager {
    constructor(options, headers, storage, authRequest, interceptor) {
        this.options = options;
        this.headers = headers;
        this.storage = storage;
        this.authRequest = authRequest;
        this.interceptor = interceptor;
        this.lockManager = (0, lock_manager_1.lockManagerFactory)();
    }
    send(options, retryUnauthorized = true) {
        let pendingRequest = this.sendRequest(options);
        if (options.signal) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            pendingRequest = this.mapAbortedErrorType(pendingRequest);
        }
        if (retryUnauthorized) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            pendingRequest = this.interceptUnauthorized(pendingRequest, options);
        }
        if (this.interceptor) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            return this.interceptor.intercept(pendingRequest);
        }
        return pendingRequest;
    }
    async upload({ file, url, id, onProgress, signal }, retryUnauthorized = true) {
        const accessToken = await this.getAccessToken();
        const appId = this.headers.options.appId ?? undefined;
        const uploader = new uploader_1.Uploader();
        let upload = uploader.upload({ file, url, id, onProgress, signal, accessToken, appId });
        if (retryUnauthorized) {
            upload = this.retryUploadInterceptor(upload, { file, url, id, onProgress, signal });
        }
        if (this.interceptor) {
            return this.interceptor.intercept(upload);
        }
        return upload;
    }
    async sendRequest({ method, url, body, signal, multipart, headers, anonymous, analytics }) {
        const token = await this.getAccessToken();
        if (token == null && !anonymous && this.options.apiSecret == null) {
            throw (0, functions_1.getUfRequestErrorByStatusCode)(http_status_codes_1.StatusCodes.UNAUTHORIZED);
        }
        if (body != null && multipart == null) {
            body = JSON.stringify(body);
        }
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
        const signedHeaders = await this.headers.getSigned({ method, url, headers, body, contentType: undefined, multipart, accessToken: token, analytics });
        const resp = await fetch(url, {
            method,
            headers: signedHeaders,
            /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
            body,
            signal,
        });
        await (0, functions_1.checkResponse)(resp);
        if (method === 'HEAD') {
            return resp.headers;
        }
        return await (0, functions_1.getBody)(resp);
    }
    interceptUnauthorized(pendingRequest, options) {
        return pendingRequest.catch((err) => {
            if (err.type !== client_models_1.ErrorType.Unauthorized) {
                return pendingRequest;
            }
            // try to get token and retry request
            return this.getAccessToken(true)
                .catch(() => null)
                .then((token) => {
                if (token != null || options.anonymous) {
                    return this.send(options, false);
                }
                return pendingRequest;
            });
        });
    }
    mapAbortedErrorType(pendingRequest) {
        return pendingRequest.catch((err) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (err.code === DOMException.ABORT_ERR) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                err.type = DOMException.ABORT_ERR;
            }
            return pendingRequest;
        });
    }
    retryUploadInterceptor(upload, args) {
        return upload.catch((err) => {
            if (err.type !== client_models_1.ErrorType.Unauthorized) {
                return upload;
            }
            // try to get token and retry upload
            return this.getAccessToken(true)
                .catch(() => null)
                .then((token) => {
                if (token != null) {
                    return this.upload(args, false);
                }
                return upload;
            });
        });
    }
    getAccessToken(force = false) {
        if (this.pendingRefresh != null) {
            return this.pendingRefresh;
        }
        const token = this.storage.token;
        if (token == null) {
            return Promise.resolve(undefined);
        }
        if (!this.hasAccessTokenExpired() && !force) {
            return Promise.resolve(token);
        }
        this.pendingRefresh = this.refreshAccessToken(token).finally(() => { this.pendingRefresh = undefined; });
        return this.pendingRefresh;
    }
    async refreshAccessToken(prevToken) {
        let result;
        await this.lockManager.request('UnifiiRefreshToken', async () => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const refresh_token = await this.storage.getRefreshToken();
            if (refresh_token == null) {
                return;
            }
            const currentToken = this.storage.token;
            if (prevToken !== currentToken) {
                result = currentToken ?? undefined;
                return;
            }
            try {
                result = (await this.authRequest.authWithRefreshToken({ refresh_token })).access_token;
            }
            catch (e) {
                // fail silently to token is undefined
            }
        });
        return result;
    }
    hasAccessTokenExpired() {
        const expiresAt = this.storage.expiresAt;
        if (expiresAt == null) {
            return true;
        }
        // now is greater than expiry
        return expiresAt < (0, date_fns_1.formatRFC3339)(new Date());
    }
}
exports.RequestManager = RequestManager;
