<uc-user-access-box [tenantSettings]="settings">
    <ng-container content>

        <uf-message *ngIf="!settings && error; else main" [content]="error" icon="errorSolid" class="error" />

        <ng-template #main>

            <form [formGroup]="form" (ngSubmit)="login()" class="uf-grid">

                <div class="col-12">
                    <h4>{{ settings?.name }}</h4>
                </div>

                <ng-container *ngIf="isUsernamePasswordAuthenticationEnabled">

                    <uf-text [formControlName]="controlKeys.Username" name="username" label="Username"
                        autocomplete="username" validators class="col-12" />

                    <uf-password [formControlName]="controlKeys.Password" [showReveal]="true" name="password"
                        label="Password" autocomplete="password" validators class="col-12" />

                    <div class="col-12 col center-all gap-sm-top">
                        <button [disabled]="inProgress" type="submit" class="uf-button primary">
                            Sign In
                        </button>
                    </div>

                </ng-container>

                <ng-container *ngIf="authProviders?.length">
                    <div *ngIf="isUsernamePasswordAuthenticationEnabled" class="col-12 col center-all">
                        <span>or</span>
                    </div>
                    <div class="col-12 auth-providers col space-children">
                        <ng-container *ngFor="let provider of authProviders">
                            <div class="col">
                                <label *ngIf="provider.providerLoginLabel"
                                    class="small">{{provider.providerLoginLabel}}</label>
                                <button [disabled]="inProgress" (click)="providerSignIn(provider)" type="button"
                                    class="authentication-provider grow">
                                    <img [src]="provider.loginIcon" />
                                    <p class="small">{{provider.loginLabel}}</p>
                                </button>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>

                <uf-message *ngIf="error" [content]="error" icon="errorSolid" class="error col-12" />

            </form>

        </ng-template>

    </ng-container>
</uc-user-access-box>