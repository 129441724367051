import { DataDisplayColour, MenuOption } from '@unifii/library/common';
import { Option, UserStatus } from '@unifii/sdk';

export const PREVIEW_FORM_STORAGE_KEY = 'UfFormPreviewForm';
export const PROJECT_STORAGE_KEY = 'UfFormPreviewProject';
export const USER_PREFERENCES_STORAGE_KEY = 'UfUserPreferences';
export const STRUCTURE_STORAGE_KEY = 'UfStructure';
export const TABLE_SEARCH_MIN_LENGTH = 3;
export const LAST_USED_PROJECT_ID_KEY = 'lastUsedProjectId';

export const DefaultPaginationParams = { offset: 0, limit: 100 };

export const BOOL_OPTIONS: Option[] = [
    { identifier: 'true', name: 'Yes' },
    { identifier: 'false', name: 'No' },
];

export enum UrlSegments {
    Root = '',
    SystemSettings = 'system-settings',
    Integrations = 'integrations',
    IntegrationFeatures = 'features',
    Login = 'login',
    Sso = 'sso',
    FormPreview = 'form-preview',
    Unauthorized = 'unauthorized',
    MyAccount = 'my-account',
    UserManagement = 'user-management',
    Users = 'users',
    IdParam = ':id',
    Devices = 'devices',
    Permissions = 'permissions',
    InheritedPermissions = 'inherited-permissions',
    Roles = 'roles',
    UserClaims = 'user-claims',
    ApiKeys = 'api-keys',
    AuditLog = 'audit-log',
    Hierarchy = 'hierarchy',
    Assets = 'assets',
    Media = 'media',
    NewRecord = 'new',
    Views = 'views',
    Collections = 'collections',
    Projects = 'projects',
    ProjectId = 'projects/:id',
    Structure = 'structure',
    Content = 'content',
    IdDefinition = ':id/definition',
    Pages = 'pages',
    Forms = 'forms',
    FormSummary = ':id/summary',
    FormData = 'form-data',
    Settings = 'settings',
    Preview = 'preview',
    Live = 'live',
    Workflows = 'workflows',
    WorkflowRules = 'rules',
    WorkflowTimers = 'timers',
    WorkflowDataForwarders = 'data-forwarders',
    WorkflowNotifications = 'notifications',
    Tables = 'tables',
    TableDetailPage = 'details-page',
    Publish = 'publish',
    General = 'general',
    Theming = 'theming',
    DataSources = 'data-sources',
    DataTransactions = 'data-transactions',
    AutomaticRegistration = ':type/automatic-registration',
    AuthProviderId = ':type/:id',
    Email = 'email',
    Smtp = 'smtp',
    Apps = 'apps',
    SendNotification = 'send-notification',
    Lab = 'lab',
    StyleGuide = 'style-guide',
    Empty = 'empty',
    Icons = 'icons',
    Lists = 'lists',
    DragList = 'drag-list',
    ReactiveForms = 'reactive-forms',
    TemplateForm = 'template-form',
    TemplateTable = 'template-table',
    TemplateTabs = 'template-tabs',
    Table = 'table',
    ErrorHandling = 'error-handling',
    PasswordChange = 'password-change',
    Mfa = 'mfa',
    Wildcard = '**',
}

export const USER_STATUS_COLOUR: Record<UserStatus, DataDisplayColour> = {
    [UserStatus.Active]: 'success',
    [UserStatus.Pending]: 'warning',
    [UserStatus.Inactive]: 'default',
};

export const ConsoleNameLabel = 'Console Name';
export const ConsoleNameProperty = 'consoleName';
export const ConsoleNameContentProperty = '_consoleName';
export const ConsoleNameRequiredMessage = 'Console Name is required';
export const PageViewEmptyTablesMessage = `Looks like you're all caught up! Nothing to see here at the moment.`;

export const SortIcons = {
    sortDown: 'sortDown',
    sortUp: 'sortUp',
};

export interface IdentifierMenuOption<T> extends MenuOption {
    identifier: keyof T;
}
