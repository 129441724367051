<div class="header">
    <button (click)="close()" type="button" class="uf-action tertiary" title="Close">
        <uf-icon name="close" />
    </button>
    <h3>{{data.title}}</h3>
</div>

<div class="content">
    <uf-search [search]="query" [autofocus]="true" (searchChange)="filter($event)" />
</div>

<uf-panel [bottomThreshold]="20" (scrollBottom)="onScrolledToEnd()">
    <ul class="media-select">
        <li *ngIf="!busy && query?.length && !items.length">
            Sorry, there was no result for your search.
        </li>
        <li *ngFor="let item of items, let i = index">
            <a [ngClass]="{active: item.selected}" (click)="select(i)">
                <div class="uc-image--no-padding"
                    *ngIf="item.url && item.type !== 'File' && item.type !== 'Audio' && item.type !== 'Video'">
                    <img [src]="item?.url + '?w=48&h=48&mode=contain'" alt="item.title">
                </div>
                <span class="padded--horizontal">
                    <h4>{{item.consoleName}}</h4>
                    <p>{{item.description}}</p>
                </span>
            </a>
        </li>
    </ul>
</uf-panel>

<div class="row space-children pad">
    <button (click)="close()" type="button" class="uf-button tertiary right">Close</button>
    <button (click)="save()" type="button" class="uf-button primary">Add</button>
</div>