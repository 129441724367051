import { Injectable, inject } from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { ModalService } from '@unifii/library/common';
import { filter } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavigationService {

    private router = inject(Router);
    private modalService = inject(ModalService);

    init() {
        this.router.events.pipe(
            filter((e): e is NavigationEnd | NavigationCancel => (e instanceof NavigationEnd || e instanceof NavigationCancel)),
            ).subscribe(() => {
                    this.modalService.closeAll();
            });
    }

}
