<ng-container *ngIf="!error && ready && definition && compound">
    <uc-builder-header />
    <div class="content">

        <div class="data-container uf-box flat">
            <div class="uf-app-bar flat">
                <span class="title">Data</span>
                <uc-expander-controls *ngIf="definition.fields?.length" />
            </div>
            <uf-panel class="container">
                <uf-form #form [definition]="displayDefinition" [config]="config" [(formData)]="compound">
                    <div header class="uf-grid pad-sides pad-top">
                        <ng-template [ngIf]="rootControl">
                            <uf-text *ngIf="rootControl.controls._title; let titleControl"
                                [control]="titleControl | asUfControl" [(value)]="compound._title" label="Title"
                                class="col-12" />
                            <uf-text *ngIf="rootControl.controls.consoleName; let consoleNameControl"
                                [control]="consoleNameControl | asUfControl" [label]="consoleNameLabel"
                                [(value)]="compound._consoleName" class="col-12" />
                        </ng-template>
                    </div>
                </uf-form>
            </uf-panel>
        </div>

        <uf-panel class="preview-container uf-box flat">
            <div class="uf-app-bar flat accent">
                <span class="title">Preview</span>
            </div>
            <uf-panel *ngIf="displayDefinition && displayCompound" class="container">
                <uf-collection-item [definition]="displayDefinition" [compound]="displayCompound" />
            </uf-panel>
        </uf-panel>

    </div>
</ng-container>

<uc-error-message [error]="error" />