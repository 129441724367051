<div class="grid">
    <uf-text *ngIf="control.controls.title; let titleControl" [control]="titleControl | asUfControl"
        [(value)]="page._title" label="Title" class="col-1of1" />

    <uf-text *ngIf="control.controls.consoleName; let consoleNameControl" [control]="consoleNameControl | asUfControl"
        [label]="consoleNameLabel" [(value)]="page._consoleName" class="col-1of1" />

    <uf-text *ngIf="control.controls.identifier; let identifierControl" [control]="identifierControl | asUfControl"
        [maxLength]="maxLength" [(value)]="page.identifier" label="Identifier" class="col-1of1" />

    <uf-chips *ngIf="control.controls.tags; let tagsControl" [control]="tagsControl | asUfControl" [allowCustom]="true"
        [options]="filteredTags" [(value)]="page.tags" (searchChange)="filterTags($event)"
        (valueChange)="builderService.refreshTags()" label="Tags" class="col-1of1" />
</div>