"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenStorage = exports.Interceptor = exports.RequestAnalyticsOrigin = exports.ClientContentType = exports.Formats = exports.PermissionAction = exports.ErrorType = void 0;
var ErrorType;
(function (ErrorType) {
    ErrorType[ErrorType["Unknown"] = 0] = "Unknown";
    ErrorType[ErrorType["Server"] = 1] = "Server";
    ErrorType[ErrorType["Validation"] = 2] = "Validation";
    ErrorType[ErrorType["Unauthorized"] = 3] = "Unauthorized";
    ErrorType[ErrorType["NotFound"] = 4] = "NotFound";
    ErrorType[ErrorType["Connection"] = 5] = "Connection";
    ErrorType[ErrorType["Conflict"] = 6] = "Conflict";
    ErrorType[ErrorType["Forbidden"] = 7] = "Forbidden";
    ErrorType[ErrorType["NullReference"] = 8] = "NullReference"; // used in Discover errors.ts and error.service.ts, remove that reference and drop this type
})(ErrorType || (exports.ErrorType = ErrorType = {}));
var PermissionAction;
(function (PermissionAction) {
    PermissionAction["List"] = "List";
    PermissionAction["Read"] = "Read";
    PermissionAction["Retrieve"] = "Retrieve";
    PermissionAction["Add"] = "Add";
    PermissionAction["Invite"] = "Invite";
    PermissionAction["Update"] = "Update";
    PermissionAction["Delete"] = "Delete";
})(PermissionAction || (exports.PermissionAction = PermissionAction = {}));
var Formats;
(function (Formats) {
    Formats["Json"] = "json";
    Formats["Csv"] = "csv";
    Formats["Xlsx"] = "xlsx";
})(Formats || (exports.Formats = Formats = {}));
var ClientContentType;
(function (ClientContentType) {
    ClientContentType["ApplicationJson"] = "application/json";
    ClientContentType["ApplicationPdf"] = "application/pdf";
    ClientContentType["ApplicationXWwwFormUrlencoded"] = "application/x-www-form-urlencoded";
})(ClientContentType || (exports.ClientContentType = ClientContentType = {}));
var RequestAnalyticsOrigin;
(function (RequestAnalyticsOrigin) {
    RequestAnalyticsOrigin["Table"] = "table";
    RequestAnalyticsOrigin["Form"] = "form";
    RequestAnalyticsOrigin["Report"] = "report";
})(RequestAnalyticsOrigin || (exports.RequestAnalyticsOrigin = RequestAnalyticsOrigin = {}));
class Interceptor {
}
exports.Interceptor = Interceptor;
/** InjectionToken like */
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
class TokenStorage {
}
exports.TokenStorage = TokenStorage;
