import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '@unifii/library/common';
import { Client, ContentClient, ProjectContentOptions, ProjectContentOptionsInterface, ProjectInfo, TenantClient } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';
import { ProjectPublisher, PublishArgs, PublishInfo } from 'services/project-publisher';
import { TitleService } from 'services/title.service';

export const publishedContentFactory = (client: Client, tenantClient: TenantClient, projectOptions: ProjectContentOptionsInterface) => {
    const options: ProjectContentOptionsInterface = {
        projectId: `${projectOptions.projectId}`,
        preview: false,
    };

    return new ContentClient(client, tenantClient, options);
};

interface Artifact {
    name: string;
    filename: string;
    createdAt: string;
}

@Component({
    templateUrl: './publish.html',
    styleUrls: ['./publish.less'],
    providers: [
        { provide: ContentClient, useFactory: publishedContentFactory, deps: [Client, TenantClient, ProjectContentOptions] },
    ],
})
export class PublishComponent implements OnDestroy, OnInit {

    @HostBinding('class.stretch-component') class = true;

    status: PublishInfo;
    artifact: Artifact | undefined;
    downloadInProgress = false;
    downloadError: Error | null;

    breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route);

    private publishEvents: Subscription;

    constructor(
        private client: Client,
        private context: ContextService,
        private modalService: ModalService,
        private contentClient: ContentClient,
        private publisher: ProjectPublisher,
        @Inject(ProjectContentOptions) private projectOptions: ProjectContentOptionsInterface,
        private breadcrumbService: BreadcrumbService,
        private route: ActivatedRoute,
        private titleService: TitleService,
    ) {
        this.publishEvents = this.publisher.event.subscribe((event) => {
            this.status = event;
            void this.updateArtifact();
        });

        void this.publisher.update();
    }

    ngOnInit() {
        this.titleService.updateTitle(`${this.context.project?.name} | Publish`, true);
    }

    ngOnDestroy() {
        this.publishEvents.unsubscribe();
    }

    async publish({ isPreview }: PublishArgs = {}) {

        const consent = await this.userConsent({ isPreview });

        if (!consent) {
            return;
        }

        void this.publisher.publish({ isPreview });
    }

    async getAppPackage(version: number) {
        const url = this.client.buildUrl('projects', '' + this.projectOptions.projectId, 'versions', '' + version, 'artifacts', (this.artifact as Artifact).name);
        const fetchRequest = {
            method: 'GET',
            headers: new Headers({
                Authorization: 'Bearer ' + this.client.token,
            }),
        };

        this.downloadError = null;
        this.downloadInProgress = true;

        try {
            const response = await fetch(url, fetchRequest);
            const data = await response.blob();
            const fileUrl = window.URL.createObjectURL(data);
            const a = document.createElement('a');

            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = fileUrl;
            a.download = `app-package-${this.projectOptions.projectId}-${version}-stable.zip`;
            a.click();
            window.URL.revokeObjectURL(fileUrl);
            a.remove();
        } catch (e) {
            this.downloadError = new Error('Download error');
        } finally {
            this.downloadInProgress = false;
        }
    }

    get inProgress(): boolean {
        return this.status.pending != null || this.downloadInProgress;
    }

    get stablePublishInProgress(): boolean {
        return this.status.pending != null && this.status.pending.preview == null;
    }

    get stablePublishError(): Error | undefined {
        if (this.status.failure?.version && this.status.failure.version.preview == null) {
            return this.status.failure.error;
        }

        return undefined;
    }

    get previewPublishError(): Error | undefined {
        if (this.status.failure?.version?.preview != null) {
            return this.status.failure.error;
        }

        return undefined;
    }

    get statusError(): Error | undefined {
        if (this.status.failure && this.status.preview == null && this.status.stable == null) {
            return this.status.failure.error;
        }

        return undefined;
    }

    private async updateArtifact() {

        if (!(this.context.project as ProjectInfo).offline) {
            return;
        }

        try {
            const version = await this.contentClient.getLatestVersion();

            if (version.artifacts && version.artifacts.length) {
                this.artifact = version.artifacts[0];
            }
        } catch (error) { /** */ }
    }

    private userConsent({ isPreview }: PublishArgs = {}): Promise<boolean | undefined> {

        return this.modalService.openConfirm({
            title: 'Publish',
            message: this.getConsentMessage({ isPreview }),
            confirmLabel: 'Publish',
            cancelLabel: `Don't Publish`,
        });
    }

    private getConsentMessage({ isPreview }: PublishArgs = {}): string {
        const nextVersion = this.publisher.getNextVersion({ isPreview });

        if (nextVersion.preview == null) {
            const version = nextVersion.version > 1 ? nextVersion.version : 'Stable';

            return `Publish ${version}?`;

        }

        if (this.status.preview == null) {
            return `Publish Preview?`;
        }

        return `Publish ${nextVersion.version}-preview.${nextVersion.preview}`;
    }

}
