<ng-container *ngIf="control">

    <!-- Home -->
    <div *ngIf="isHome" [class.accent]="isSelected" (click)="select($event)" class="uf-app-bar flat clickable">
        <uf-icon [name]="icon" />
        <div *ngIf="isHomeEmpty; else homeConfigured" class="title primary">Select a home page</div>
        <ng-template #homeConfigured>
            <div class="title primary">{{ node.name || 'Home' }}</div>
            <uf-icon *ngIf="control.invalid" name="error" class="error" title="This node configuration is invalid" />
            <uf-icon *ngIf="!isPublished" [title]="notPublishedMessage" name="unpublished" class="icon-warning" />
            <uf-icon *ngIf="isAccessRestricted" name="lock" class="icon-warning"
                title="This node access is restricted" />
            <uf-icon *ngIf="hasVariations" name="variation" title="This node has variations" />
            <a *ngIf="control.enabled" [title]="status.showHiddenNodes ? 'Show hidden nodes' : 'Hide hidden nodes'"
                (click)="status.showHiddenNodes = !status.showHiddenNodes" restrict="SuperUser" class="uf-action">
                <uf-icon [name]="status.showHiddenNodes ? 'view' : 'viewHidden'" />
            </a>
        </ng-template>
        <button [class.tertiary]="!isSelected" [class.primary]="isSelected" (click)="expand($event)" type="button"
            title="Expand All" class="uf-action">
            <uf-icon name="arrowDown" />
        </button>
        <button [class.tertiary]="!isSelected" [class.primary]="isSelected" (click)="collapse($event)" type="button"
            class="uf-action" title="Collapse All">
            <uf-icon name="arrowUp" />
        </button>
        <button *ngIf="!isHomeEmpty && !control.disabled" (click)="remove($event)" type="button" class="uf-action"
            title="Delete">
            <uf-icon name="delete" />
        </button>
    </div>

    <!-- Leaf node -->
    <div *ngIf="!isHome && !children.length" [class.uf-box]="!isSelected" [class.accent]="isSelected"
        (click)="select($event)" class="uf-app-bar flat draggable gap-sm-bottom">
        <uf-icon [name]="icon" />
        <div class="title primary">{{node.name}}</div>
        <uf-icon *ngIf="control.invalid" name="error" class="error" title="This node configuration is invalid" />
        <uf-icon *ngIf="!isPublished" [title]="notPublishedMessage" name="unpublished"
            class="icon-warning margin--horizontal" />
        <uf-icon *ngIf="isAccessRestricted" name="lock" class="icon-warning" title="This node access is restricted" />
        <uf-icon *ngIf="node.isHidden" name="viewHidden" class="icon-warning" title="This node is hidden" />
        <uf-icon *ngIf="control.disabled" [name]="node.isHidden ? 'viewHidden' : 'view'"
            [title]="node.isHidden ? 'Hidden' : 'Visible'" restrict="SuperUser" />

        <button *ngIf="!control.disabled" (click)="remove($event)" type="button" class="uf-action" title="Delete">
            <uf-icon name="delete" />
        </button>
    </div>

    <!-- Parent node -->
    <uf-expander *ngIf="!isHome && !!children.length" class="uf-box flat draggable gap-sm-bottom">
        <div [class.accent]="isSelected" (click)="select($event)" expanderHeader class="uf-app-bar flat draggable">
            <uf-icon [name]="icon" />
            <div class="title primary">{{node.name}}</div>
            <uf-icon *ngIf="control.invalid" name="error" class="error" title="This node configuration is invalid" />
            <uf-icon *ngIf="!isPublished" [title]="notPublishedMessage" name="unpublished"
                class="icon-warning margin--horizontal" />
            <uf-icon *ngIf="isAccessRestricted" name="lock" class="icon-warning" />
            <uf-icon *ngIf="node.isHidden" name="viewHidden" class="icon-warning" title="This node is hidden" />
            <uf-icon *ngIf="control.disabled" [name]="node.isHidden ? 'viewHidden' : 'view'"
                [title]="node.isHidden ? 'Hidden' : 'Visible'" restrict="SuperUser" />

            <button *ngIf="!control.disabled" (click)="remove($event)" title="Delete" type="button" class="uf-action">
                <uf-icon name="delete" />
            </button>
        </div>
        <uc-drag-list [parent]="children" [items]="children.controls" [canDrop]="service.canDrop"
            [convertAdded]="service.addConverter" [drop]="service.drop" expanderBody class="gap-left gap-sm-top">
            <uc-node-field *ngFor="let child of children.controls | asUfControlsGroups" [dragDisabled]="child.disabled"
                [nestable]="true" [control]="child" [isParentHidden]="showAsHidden" class="gap-sm-bottom" dragItem />
        </uc-drag-list>
    </uf-expander>

</ng-container>