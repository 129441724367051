import { Injectable, inject } from '@angular/core';
import { ContextProvider } from '@unifii/library/common';
import { UserInfo, mapUserToUserContext } from '@unifii/sdk';

import { ContextService } from './context.service';

@Injectable()
export class UcContextProvider implements ContextProvider {

    private contextService = inject(ContextService);

    get() {
        const userInfo = Object.assign({}, (this.contextService.account ?? {
            username: 'user',
            roles: [],
            claims: [],
            isMfaOptional: false,
            isMfaEnabled: false,
        }), {
            isExternal: false,
            hasPassword: true,
            isActive: true,
        }) satisfies UserInfo;

        const userContext = mapUserToUserContext(userInfo);

        return { user: userContext };
    }

}
