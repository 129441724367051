import { Component, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, DataDisplayListItem, ModalService, ToastService, UfControlGroup, UfFormBuilder } from '@unifii/library/common';
import { UfError } from '@unifii/sdk';
import { UserProvisioningCache } from '@unifii/user-provisioning';
import { Subscription } from 'rxjs';

import { UcPermissionsClient, UcRole, UcRoles } from 'client';
import { SaveAndClose, useDefaultErrorMessage } from 'components';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { TitleService } from 'services/title.service';

import { RoleModalComponent } from './role-modal.component';
import { RolesTableManager } from './roles-table-manager';

enum RoleControlKeys {
    Label = 'label',
    Description = 'description',
}

@Component({
    templateUrl: './role-details.html',
    styleUrls: ['./role-details.less'],
    providers: [{
        provide: UcPermissionsClient,
        useFactory: (ucRoles: UcRoles, route: ActivatedRoute) => ucRoles.permissions(route.snapshot.params.id),
        deps: [UcRoles, ActivatedRoute],
    }],
})
export class RoleDetailsComponent implements OnDestroy, OnInit {

    @HostBinding('class.stretch-component') stretchComponentClass = true;

    protected error?: UfError;
    protected readonly controlKeys = RoleControlKeys;
    protected readonly saveOptions = [SaveAndClose];
    protected role: UcRole;
    protected roleInfo?: DataDisplayListItem[];
    protected breadcrumbs: Breadcrumb[] = [];
    protected form: UfControlGroup | undefined;

    private subscriptions = new Subscription();
    private ufb = inject(UfFormBuilder);
    private ucRoles = inject(UcRoles);
    private toastService = inject(ToastService);
    private route = inject(ActivatedRoute);
    private breadcrumbService = inject(BreadcrumbService);
    private userProvisioningCache = inject(UserProvisioningCache);
    private tableManager = inject<RolesTableManager>(TableContainerManager);
    private modalService = inject(ModalService);
    private titleService = inject(TitleService);

    async ngOnInit() {
        try {
            this.role = await this.ucRoles.getDetails(this.route.snapshot.params.id);
            this.updateRoleInfo();
        } catch (e) {
            this.error = useDefaultErrorMessage(e);

            return;
        }
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [this.role.name]);
        this.form = this.ufb.group({
            [RoleControlKeys.Label]: this.role.label,
            [RoleControlKeys.Description]: this.role.description,
        });

        this.titleService.updateTitle(this.role.name);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    protected async edit() {
        const updatedRole = await this.modalService.openMedium(RoleModalComponent, { role: this.role });

        if (!updatedRole) {
            return;
        }

        try {
            this.role = await this.ucRoles.save(Object.assign({}, this.role, updatedRole));

            this.updateRoleInfo();
            this.titleService.updateTitle(this.role.name);
            this.tableManager.updateItem.next(this.role);
            this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [this.role.name]);
            this.toastService.success(`Role ${this.role.name} saved`);
            this.userProvisioningCache.clear();

            await this.userProvisioningCache.init();

        } catch (e) {
            this.toastService.error('Failed to save role');
            console.error(e);
        }
    }

    private updateRoleInfo() {
        this.roleInfo = [
            { term: 'Description', data: this.role.description },
            { term: 'Title', data: this.role.label },
        ];
    }

}
