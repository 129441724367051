import { Injectable, inject } from '@angular/core';
import { HierarchyUnitProvider } from '@unifii/library/common';
import { ErrorType, HierarchyChildrenSearchOptions, HierarchySearchOptions, HierarchyUnitExtended, HierarchyUnitWithChildCount, HierarchyUnitWithPath, UfRequestError, ensureUfError } from '@unifii/sdk';

import { UcHierarchy } from './hierarchy';

@Injectable()
export class UcHierarchyUnitProvider implements HierarchyUnitProvider {

    private hierarchy = inject(UcHierarchy);

    getUnit(id: string): Promise<HierarchyUnitExtended | undefined> {
        try {
            return this.hierarchy.getUnit(id);
        } catch (e) {
            if ((e as UfRequestError).type === ErrorType.NotFound) {
                return Promise.resolve(undefined);
            } else {
                throw e;
            }
        }
    }

    getUnits(ids: string[]): Promise<HierarchyUnitWithPath[]> {
        return this.hierarchy.getUnits(ids);
    }

    async getChildren(id: string, params?: HierarchyChildrenSearchOptions): Promise<HierarchyUnitWithChildCount[]> {
        try {
            return await this.hierarchy.getUnitChildren(id, params);
        } catch (error) {
            if (ensureUfError(error).type === ErrorType.NotFound) {
                return Promise.resolve([]);
            } else {
                throw error;
            }
        }
    }

    search(params?: HierarchySearchOptions): Promise<HierarchyUnitWithPath[]> {
        return this.hierarchy.search(params);
    }

}
