import { MenuOption } from '@unifii/library/common';
import { isNotNull } from '@unifii/sdk';

import { SortIcons } from 'constant';

export const isSortUp = (option: MenuOption, options: MenuOption[]): boolean => {
    const sortingUp = option.icon === SortIcons.sortDown;

    for (const menuOption of options) {
        menuOption.icon = (menuOption.label === option.label ? (sortingUp ? SortIcons.sortUp : SortIcons.sortDown) : undefined);
    }

    return sortingUp;
};

export const sortByProperty = <T>(sortingUp: boolean, property: keyof T, childrenProperty?: keyof T): (item1: T, item2: T) => 1 | -1 => {
    return (item1: T, item2: T) => {

        if (childrenProperty && Array.isArray(item1[childrenProperty])) {
            (item1[childrenProperty] as T[]).sort(sortByProperty(sortingUp, property, childrenProperty));
        }

        const item1Property = item1[property];
        const item2Property = item2[property];

        return isNotNull(item1Property) && isNotNull(item2Property)
            && item1Property > item2Property ? sortingUp ? -1 : 1 : sortingUp ? 1 : -1;
    };
};
