<uf-drop-menu [options]="menuOptions" (selected)="onSort($event)" title="Sort By" class="uf-action right gap-bottom"
    icon="sort" />
<table class="uf-table inputs accent clickable small fixed">
    <thead>
        <tr>
            <th>Source Data</th>
            <th>Target Data</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let targetField of targetFields; trackBy: targetFieldTrackBy">
            <ng-container *ngIf="getMappableControl(targetField.identifier); let control">
                <td (click)="inputArgClick(control)">
                    <div class="row space-children--small wrap">
                        <ng-container *ngTemplateOutlet="field; context: {
                                icon: getSourceFieldIcon(control.value.field),
                                label: getSourceFieldLabel(control.value.field),
                                field: control.value.field,
                                expression: control.value.expression
                            }" />
                        <span *ngIf="control.showError" class="uf-lozenge error">{{
                            control.errors?.message
                            }}</span>
                    </div>
                </td>
                <td (click)="inputArgClick(control)">
                    <div class="row space-children--small wrap">
                        <ng-container *ngTemplateOutlet="field; context: {
                                icon: getTargetFieldIcon(control.value.identifier),
                                label: getTargetFieldLabel(control.value.identifier),
                                field: control.value.identifier
                                }" />
                        <span *ngIf="control.value?.isRequired" class="suffix">*</span>
                    </div>
                </td>
            </ng-container>
        </tr>
    </tbody>
</table>

<div *ngIf="!control || control.controls.length === 0" class="col center-all pad-top pad-sides">
    <uf-icon name="alert" class="x-large accent" />
    <h3 class="gap-none-bottom gap-sm-top">No Mappable Fields</h3>
    <p class="gap-sm-top">No Mappable fields for target</p>
</div>

<ng-template #field let-icon='icon' let-field="field" let-label="label" let-expression="expression">
    <uf-icon *ngIf="icon" [name]="icon" />
    <span *ngIf="label">{{label}}</span>
    <span *ngIf="label">(<code>{{field}}</code>)</span>
    <span *ngIf="!label">{{ field }}</span>
    <span *ngIf="expression"><code>{{ expression }}</code></span>
</ng-template>