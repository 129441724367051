<div class="uf-app-bar">
    <h3>
        <ng-template [ngIf]="data.edited" [ngIfElse]="title">
            Conflict Detected
        </ng-template>
    </h3>
</div>
<div class="uf-grid pad gaps">
    <div class="col-12">
        <p>
            <ng-template [ngIf]="data.edited" [ngIfElse]="message">
                This item has been edited elsewhere and is no longer valid. To avoid a conflict it can't be submitted
            </ng-template>
        </p>
    </div>
</div>
<div class="uf-form-actions">
    <ng-template [ngIf]="data.edited" [ngIfElse]="buttons">
        <a (click)="runtime.close()" class="uf-button tertiary">
            Close
        </a>
        <a (click)="apply('Discard')" class="uf-button primary">
            Discard Changes & Reload
        </a>
        <a (click)="apply('OpenNewTab')" class="uf-button primary">
            Open Latest Version in New Tab
        </a>
    </ng-template>
</div>

<ng-template #title>
    New Version Detected
</ng-template>
<ng-template #message>
    This item has been edited elsewhere and is no longer valid.
</ng-template>
<ng-template #buttons>
    <a (click)="apply('Discard')" class="uf-button primary">
        Reload Page
    </a>
</ng-template>