<uc-user-access-box [tenantSettings]="undefined">
    <ng-container content>
        <uf-virtual-mfa *ngIf="mfaStatus" [label]="label" [mfaStatus]="mfaStatus"
            [setVirtualMfaCode]="setVirtualMfaCode.bind(this)" [verifyToken]="verifyToken.bind(this)" />
        <div class="col center-all">
            <button (click)="logout()" type="button" class="uf-button tertiary">
                {{sharedTermsTK.ActionLogout | translate}}
            </button>
        </div>
        <uf-message *ngIf="error" [content]="error" icon="errorSolid" class="error" />
    </ng-container>
</uc-user-access-box>