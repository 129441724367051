import { Component, HostBinding } from '@angular/core';
import { TableContainerManager } from '@unifii/components';
import { FilterEntries } from '@unifii/library/common';

import { createFilterEntries } from 'pages/audit-log/audit-log-filter-factory';
import { AuditLogTableManager } from 'pages/audit-log/audit-log-table-manager';

@Component({
    selector: 'uc-adit-log',
    providers: [
        { provide: FilterEntries, useFactory: createFilterEntries },
        { provide: TableContainerManager, useClass: AuditLogTableManager },
    ],
    template: `
        <div ufMasterDetail>
            <uf-table-container ufSyncRoute  class="accent list-md" />
        </div>
        <router-outlet />
    `,
})
export class AuditLogComponent {

    @HostBinding('class.stretch-component') stretchClass = true;

}
