import { Inject, Injectable, OnDestroy } from '@angular/core';
import { TableContainerManager, TableInputManager, TableInputs } from '@unifii/components';
import { DataDisplayImageValue, DataDisplayService, FilterEntries, FilterEntry, FilterValue, HierarchyUnitProvider, TableConfig, TableConfigColumn } from '@unifii/library/common';
import { DataType, ImageMode } from '@unifii/sdk';
import { Subject, Subscription } from 'rxjs';

import { Media, MediaType, SystemRole, UcMedia } from 'client';
import { ConsoleNameLabel, TABLE_SEARCH_MIN_LENGTH } from 'constant';
import { FileTypePipe } from 'pipes/file-type.pipe';
import { ContextService } from 'services/context.service';

import { MediaDataSource } from './media-datasource';

export enum MediaMetadataIdentifiers {
    Url = 'url',
    Title = 'title',
    Filename = 'filename',
    UploadedAt = 'uploadedAt',
    Size = 'size',
    FileSize = 'fileSize',
    FileType = 'fileType',
    ConsoleName = 'consoleName',
}

@Injectable()
export class MediaTableManager implements TableContainerManager<Media, FilterValue, FilterEntry>, OnDestroy {

    tableConfig: TableConfig<Media>;
    showSearch = true;
    searchMinLength = TABLE_SEARCH_MIN_LENGTH;
    addActionConfig = this.context.checkRoles(SystemRole.AssetManager);
    defaultSort = 'title';
    reload = new Subject<void>();
    update = new Subject<TableInputs<FilterValue>>();
    updateItem = new Subject<Media | { item: Media; trackBy: keyof Media }>();
    inputManager: TableInputManager<FilterValue, FilterEntry>;

    private items: Media[] = [];
    private connection?: Subscription;

    constructor(
        @Inject(FilterEntries) filterEntries: FilterEntry[],
        @Inject(HierarchyUnitProvider) hierarchyUnitProvider: HierarchyUnitProvider,
        @Inject(DataDisplayService) private dataDisplayService: DataDisplayService,
        private ucMedia: UcMedia,
        private fileTypePipe: FileTypePipe,
        private context: ContextService,
    ) {
        this.inputManager = new TableInputManager(filterEntries, hierarchyUnitProvider, null, null);

        this.tableConfig = {
            id: 'media',
            columns: this.columns,
            pageSize: 50,
            columnToggles: true,
            row: {
                link: (item) => `${item.id}`,
                image: (item) => this.getUrl(item, 214, 120),
                label: (item) => item.consoleName ?? item.filename,
            },
        };
    }

    ngOnDestroy() {
        this.connection?.unsubscribe();
    }

    createDataSource(inputs: TableInputs<FilterValue> | undefined) {
        const params = this.inputManager.serializeInputs(inputs ?? {});

        if (params.sortBy) {
            params.sort = params.sortBy;
            delete params.sortBy;
        }

        const dataSource = new MediaDataSource(this.ucMedia, params);

        this.connection?.unsubscribe();

        this.items = [];
        this.connection = dataSource.connect().subscribe((items) => this.items.push(...(items.data ?? [])));

        return dataSource;
    }

    getNextItem(id?: number): Media | undefined {
        const itemIndex = this.items.findIndex((item) => item.id === id);

        if (itemIndex < 0) {
            return;
        }

        return this.items[itemIndex + 1];
    }

    private get columns(): TableConfigColumn<Media>[] {
        return [{
            name: MediaMetadataIdentifiers.Url,
            label: 'Thumbnail',
            value: (item) => ({ imageUrl: this.getUrl(item, 32) } satisfies DataDisplayImageValue),
            hidden: true,
        }, {
            name: MediaMetadataIdentifiers.Title,
            label: 'Title',
            hidden: true,
        }
        , {
            name: MediaMetadataIdentifiers.ConsoleName,
            label: ConsoleNameLabel,
            hidden: true,
        }, {
            name: MediaMetadataIdentifiers.Filename,
            label: 'File Name',
            hidden: true,
        }, {
            name: MediaMetadataIdentifiers.UploadedAt,
            label: 'Uploaded',
            value: (item) => this.dataDisplayService.displayAsString(item.uploadedAt),
        }, {
            name: MediaMetadataIdentifiers.Size,
            label: 'Size',
            value: (item) => this.getSize(item),
            hidden: true,
        }, {
            name: MediaMetadataIdentifiers.FileSize,
            label: 'File Size',
            hidden: true,
            value: (item) => this.dataDisplayService.displayAsString(item.filesize, { type: DataType.Number, format: 'bytes' }),
        }, {
            name: MediaMetadataIdentifiers.FileType,
            label: 'File Type',
            value: (item) => this.fileTypePipe.transform(item.filename),
        }];
    }

    private getSize(media: Media) {
        switch (media.type) {
            case MediaType.Image:
                return media.width && media.height ? media.width + 'px x ' + media.height + 'px' : '';
            case MediaType.Audio:
            case MediaType.Video:
            case MediaType.File:
                return media.length ? media.length : '';
            default:
                return null;
        }
    }

    private getUrl(media: Media, width: number, height?: number): string {
        const assetBaseUrl = '/assets/svg';

        switch (media.type) {
            case MediaType.Image: return this.ucMedia.buildImageUrl(media, { width, height, mode: ImageMode.Contain }) ?? '';
            case MediaType.Audio: return assetBaseUrl + '/icon-audio.svg';
            case MediaType.Video: return assetBaseUrl + '/icon-video.svg';
            default: return assetBaseUrl + '/icon-file.svg';
        }
    }

}
