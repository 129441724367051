"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableDetailTemplate = exports.MessageColour = exports.CellTemplateType = exports.OriginIdentifiers = void 0;
/**
 * Describes what generated the form data:
 *
 * User - Revision generated by actual user
 * Unifii - Revision generated by Unifii (Worker)
 * ApiKey - Revision generated via API using an Api Key
 */
var OriginIdentifiers;
(function (OriginIdentifiers) {
    OriginIdentifiers["ApiKey"] = "apiKey";
    OriginIdentifiers["Unifii"] = "unifii";
    OriginIdentifiers["User"] = "user";
})(OriginIdentifiers || (exports.OriginIdentifiers = OriginIdentifiers = {}));
var CellTemplateType;
(function (CellTemplateType) {
    CellTemplateType["Lozenge"] = "lozenge";
    CellTemplateType["Icon"] = "icon";
    CellTemplateType["Image"] = "image";
})(CellTemplateType || (exports.CellTemplateType = CellTemplateType = {}));
var MessageColour;
(function (MessageColour) {
    MessageColour["Accent"] = "accent";
    MessageColour["Info"] = "info";
    MessageColour["Warning"] = "warning";
    MessageColour["Error"] = "error";
    MessageColour["Success"] = "success";
})(MessageColour || (exports.MessageColour = MessageColour = {}));
var TableDetailTemplate;
(function (TableDetailTemplate) {
    TableDetailTemplate["PageView"] = "PageView";
    TableDetailTemplate["PageViewHideEmptyTables"] = "PageViewHideEmptyTables";
    TableDetailTemplate["MenuView"] = "MenuView";
})(TableDetailTemplate || (exports.TableDetailTemplate = TableDetailTemplate = {}));
