import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, DataDescriptorBucketType, DataPropertyInfo, FieldTypeIcon, StepFieldMetadataIdentifiers, dataSourceToSourceConfig, getIcon, parseDataSource } from '@unifii/library/common';
import { DataSourceType, FieldType, Schema, SchemaDataSource, SchemaField } from '@unifii/sdk';

export interface BucketSettingsFieldInfo {
    path: string; // concatenated identifier eg. 'text' | '_parent' | '_parent.id'
    identifier: string; // identifier for display eg. 'id'
    type: FieldType;
    label: string;
    shortLabel?: string;
    icon?: string;
    fields: BucketSettingsFieldInfo[];
    position?: number;
}

export const createBucketSettingsTree = (schemaMetaDataPropertyInfos: DataPropertyInfo[], schema: Schema, translateService: TranslateService): BucketSettingsFieldInfo[] => {

    const fieldInfos: BucketSettingsFieldInfo[] = [];

    fieldInfos.push(...getMetadataEntries(schemaMetaDataPropertyInfos));

    for (const field of schema.fields) {
        fieldInfos.push(createBucketSettingsTreeEntry(field, translateService));
    }

    return fieldInfos;

};

const getMetadataEntries = (schemaMetaDataPropertyInfos: DataPropertyInfo[]): BucketSettingsFieldInfo[] => {

    // deep copy for sort
    let metaDataPropertyInfos = JSON.parse(JSON.stringify(schemaMetaDataPropertyInfos)) as DataPropertyInfo[];

    const metaMap = new Map<string, BucketSettingsFieldInfo>();

    // add link
    metaDataPropertyInfos.push({ identifier: '_link', type: FieldType.Text, label: 'Link' });

    metaDataPropertyInfos = metaDataPropertyInfos.sort((a, b) => a.identifier.split('.').length > b.identifier.split('.').length ? 1: 0);

    for (const property of metaDataPropertyInfos) {

        const { identifier: path, type, label } = property;

        if (path.includes('.')) {
            const [parent, identifier] = path.split('.', 2);

            if (!parent || !identifier) {
                continue;
            }

            const parentEntry = metaMap.get(parent);

            if (!parentEntry) {
                continue;
            }

            parentEntry.fields.push(
                {
                    path,
                    identifier,
                    type,
                    label,
                    icon: getIcon(identifier, type, DataDescriptorBucketType),
                    fields: [],
                },
            );

            continue;
        }

        metaMap.set(path, {
            path,
            identifier: path,
            type,
            label,
            icon: getIcon(path, type, DataDescriptorBucketType),
            fields: [],
        });
    }

    return [...metaMap.values()];
};

const createBucketSettingsTreeEntry = (schemaField: SchemaField, translateService: TranslateService, parentFieldPath?: string): BucketSettingsFieldInfo => {

    const { identifier, type, label, shortLabel } = schemaField;
    const path = parentFieldPath ? `${parentFieldPath}.${identifier}` : identifier;

    const fields: BucketSettingsFieldInfo[] = [];
    const dataSource = parseDataSource(schemaField.dataSourceConfig ?? schemaField.dataSource) as SchemaDataSource | undefined;

    if (dataSource?.outputFields) {

        Object.entries(dataSource.outputFields).forEach(([outputFieldIdentifier, outputField]) => {

            fields.push({
                path: `${path}.${outputFieldIdentifier}`,
                identifier: outputFieldIdentifier,
                type: outputField.type,
                label: outputField.label,
                icon: FieldTypeIcon.get(outputField.type),
                fields: [],
            });
        });
    }

    if (type === FieldType.Step) {
        fields.push(
            {
                path: `${path}.${StepFieldMetadataIdentifiers.LastViewedAt}`,
                identifier: StepFieldMetadataIdentifiers.LastViewedAt,
                type: FieldType.DateTime,
                label: translateService.instant(CommonTranslationKey.StepTypeLastViewedAtLabel),
                icon: FieldTypeIcon.get(FieldType.DateTime),
                fields: [],
            },
            {
                path: `${path}.${StepFieldMetadataIdentifiers.LastCompletedAt}`,
                identifier: StepFieldMetadataIdentifiers.LastCompletedAt,
                type: FieldType.DateTime,
                label: translateService.instant(CommonTranslationKey.StepTypeLastCompletedAtLabel),
                icon: FieldTypeIcon.get(FieldType.DateTime),
                fields: [],
            },
        );
    }

    for (const field of schemaField.fields ?? []) {
        fields.push(createBucketSettingsTreeEntry(field, translateService, path));
    }

    return {
        path,
        identifier,
        type,
        label,
        shortLabel,
        icon: getIcon(identifier, type, DataDescriptorBucketType),
        fields,
    };
};

export function* schemaFieldIterator(schemaFields?: SchemaField[], parentFieldPath?: string): Iterable<{schemaField: SchemaField; path: string}> {

    for (const schemaField of schemaFields ?? []) {
        const { identifier } = schemaField;
        const path = parentFieldPath ? `${parentFieldPath}.${identifier}` : identifier;

        yield { schemaField, path };

        const dataSource = parseDataSource(schemaField.dataSourceConfig ?? schemaField.dataSource) as SchemaDataSource | undefined;

        if (dataSource) {
            const sourceConfig = dataSourceToSourceConfig(dataSource);

            switch (sourceConfig.type) {
                case DataSourceType.UserClaims:
                case DataSourceType.Users:
                case DataSourceType.Company:
                case DataSourceType.Collection:
                case DataSourceType.Bucket:
                    for (const { type, to, label, isReportable } of sourceConfig.mappings) {
                        yield{
                            schemaField: {
                                type,
                                identifier: to,
                                label,
                                isReportable,
                            },
                            path: `${path}.${to}`,
                        };
                    }
            }
        }

        yield *schemaFieldIterator(schemaField.fields, path);
    }
}
