"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExternalDataTransactionsClient = void 0;
const constants_1 = require("../constants");
/** Client for v0/projects/:id/external-data-transactions/:externalDataTransactionId APIs */
class ExternalDataTransactionsClient {
    constructor(client, projectOptions) {
        this.client = client;
        this.projectOptions = projectOptions;
    }
    post(externalDataTransactionId, itemId, options) {
        return this.client.post(this.url(externalDataTransactionId, itemId), options);
    }
    url(externalDataTransactionId, ...args) {
        const parts = [
            constants_1.APIPath.Projects,
            this.projectOptions.projectId,
            constants_1.APIPath.ExternalDataTransactions,
            externalDataTransactionId,
            ...args,
        ];
        return this.client.buildUrl(...parts);
    }
}
exports.ExternalDataTransactionsClient = ExternalDataTransactionsClient;
