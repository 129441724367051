import { Directive, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';

@Directive()
export abstract class TabsPage implements OnInit, OnDestroy {

    @HostBinding('class.stretch-component') stretch = true;
    @HostBinding('class.hide-tabs') hideTabs = true;

    route: ActivatedRoute;
    router: Router;
    abstract edited: boolean;

    protected subscriptions = new Subscription();

    constructor() {
        this.route = inject(ActivatedRoute);
        this.router = inject(Router);

        this.updateTabVisibility();

        // hide first level tabs when we're at the user level
        this.subscriptions.add(this.router.events.pipe(
            filter((e) => e instanceof NavigationEnd))
            .subscribe(() => {
                this.updateTabVisibility();
            }));
    }

    ngOnInit() {
        this.updateTabVisibility();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    private updateTabVisibility() {
        this.hideTabs = !!(this.route.children.length && this.route.children[0]?.children.length);
    }

}
