import { UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { DefinitionPublishState, Structure, StructureNode, StructureNodeType, StructureNodeVariation, objectKeys } from '@unifii/sdk';

import { UcStructure, UcStructureNode } from 'client';
import { ArrayHelper } from 'helpers/array-helper';

import { StructureNodeControlKeys } from './structure-control-keys';
import { StructureEditorStructure, StructureEditorStructureNode } from './structure-model';

/** Find the max nodeId in the structure and return the next one free to be used */
export const getNextAvailableNodeId = (structure: UcStructure): number => {

    let lastUsed: number;

    if (structure.lastNodeId) {
        lastUsed = parseInt(structure.lastNodeId);
    } else {
        let ids = ArrayHelper.flatTree(structure)
            .filter((n) => n.nodeId)
            .map((n) => parseInt(n.nodeId));

        if (ids.length === 0) {
            ids = [0];
        }
        lastUsed = Math.max.apply(null, ids);
    }

    return lastUsed + 1;
};

/** Fix inconsistencies and errors */
export const structureCleanUp = (structure: UcStructure) => {

    // Root node fixes
    delete (structure as any).recordName;
    structure.nodeId = '0'; // Default

    // All nodes
    const nodes = ArrayHelper.flatTree(structure) as UcStructureNode[];

    // Apply nodeIds where missing and update lastNodeId
    let nextNodeId = getNextAvailableNodeId(structure);

    for (const node of nodes) {
        // general attributes
        node.roles = node.roles ?? [];
        delete (node as any).recordName;
        node.isHidden = !!(node as any).isHidden;

        // only Dashboards have buckets
        if (node.type !== StructureNodeType.Dashboard) {
            delete node.bucketOptions;
        }

        // nodeId
        if ((node as any).nodeId == null) {
            node.nodeId = `${nextNodeId}`;
            nextNodeId++;
        }
    }

    structure.lastNodeId = `${nextNodeId - 1}`;
};

export const isStructureNodeRoot = (node: Pick<StructureNode, 'nodeId'>): node is StructureEditorStructure =>
    node.nodeId === '0';

export const isNodeLinkedToContent = (node: Pick<StructureNodeVariation, 'type'>): boolean =>
    [
        StructureNodeType.Page,
        StructureNodeType.View,
        StructureNodeType.PdfViewer,
        StructureNodeType.Form,
        StructureNodeType.Collection,
        StructureNodeType.CollectionItem,
        StructureNodeType.FormBucket,
    ].includes(node.type);

/** The node content is published or doesn't need to be published */
export const isNodeContentPublished = (node: Pick<StructureNodeVariation, 'type' | 'publishState'>): boolean =>
    !isNodeLinkedToContent(node) || node.type === StructureNodeType.PdfViewer || node.publishState === DefinitionPublishState.Published;

export const structureNodeHasVariations = (node: Pick<Structure, 'nodeId' | 'variations'>): boolean =>
    isStructureNodeRoot(node) && !!node.variations.length;

export const isNodeAccessRestricted = (node: Pick<StructureNodeVariation, 'roles'>): boolean =>
    !!node.roles?.length;

export const canCopyStructureNode = (node: Pick<StructureEditorStructureNode, 'type' | 'nodeId' | 'children'>): boolean => {
    return !isStructureNodeRoot(node)
        && node.type !== StructureNodeType.Custom
        && !node.children.length;
};

export const structurePruneEmptyAttributes = (structure: UcStructure) => {
    const nodes: StructureEditorStructureNode[] = ArrayHelper.flatTree(structure);

    for (const node of nodes) {
        objectKeys(node).forEach((k) => {
            const value = node[k];

            if ((Array.isArray(value) && !value.length) || ValidatorFunctions.isEmpty(value)) {
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete node[k];
            }

            if (node.isHidden === false) {
                delete node.isHidden;
            }
        });
    }
};

export const isANodeControl = (control: UfControlGroup): boolean =>
    control.controls[StructureNodeControlKeys.NodeId] != null &&
    control.controls[StructureNodeControlKeys.NodeId].value !== '0' &&
    control.controls[StructureNodeControlKeys.Type] != null;

