"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodeType = void 0;
var NodeType;
(function (NodeType) {
    NodeType[NodeType["Combinator"] = 0] = "Combinator";
    NodeType[NodeType["Operator"] = 1] = "Operator";
    NodeType[NodeType["Value"] = 2] = "Value";
    NodeType[NodeType["Identifier"] = 3] = "Identifier";
    NodeType[NodeType["Expression"] = 4] = "Expression"; // Expression should be evaluated before being inputted as a query
})(NodeType || (exports.NodeType = NodeType = {}));
