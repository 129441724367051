import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { Subscription } from 'rxjs';

import { SystemRole } from 'client';
import { BuilderService } from 'components/compound-builder/builder.service';
import { ConsoleNameLabel, ConsoleNameRequiredMessage } from 'constant';
import { ContentDefinitionIdentifierValidators, IdentifierFunctions } from 'helpers/helpers';
import { ContextService } from 'services/context.service';

enum ViewControlKeys {
    Title = 'title',
    Identifier = 'identifier',
    ConsoleName = 'consoleName'
}

@Component({
    selector: 'uc-view-settings',
    templateUrl: './view-settings.html',
})
export class ViewSettingsComponent implements OnInit, OnDestroy {

    protected control = new UfControlGroup({
        [ViewControlKeys.Title]: new UfControl(ValidatorFunctions.required('Title is required.')),
        [ViewControlKeys.Identifier]: new UfControl(ContentDefinitionIdentifierValidators),
        [ViewControlKeys.ConsoleName]: new UfControl(ValidatorFunctions.required(ConsoleNameRequiredMessage)),
    });

    protected readonly consoleNameLabel = ConsoleNameLabel;
    protected readonly identifierMaxLength = IdentifierFunctions.WARNING_IDENTIFIER_MAX_LENGTH;
    protected builderService = inject(BuilderService);

    private subscriptions = new Subscription();
    private context = inject(ContextService);

    ngOnInit() {

        // ProjectManager or published definition
        if (!this.context.checkRoles(SystemRole.ProjectManager) || this.builderService.compound.lastPublishedAt != null) {
            const identifierControl = this.control.get(ViewControlKeys.Identifier) as UfControl;

            identifierControl.disable();
        }

        this.subscriptions.add(this.control.valueChanges.subscribe(() => this.valueChange()));

        this.subscriptions.add(this.builderService.submitted.subscribe(() => {
            this.control.setSubmitted(true);
        }));

        // New compound
        if (this.builderService.compound.lastModifiedAt == null) {
            this.subscriptions.add((this.control.controls.title as UfControl).valueChanges.subscribe(this.autofillFields));
        }

        /**
         * // TODO remove when builder validation is refactored,
         * validation is backwards, now children control groups
         * should attach themselves to parent control which should validate on submit
         */
        setTimeout(() => this.valueChange(true), 0);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    private autofillFields = (value: string | undefined) => {

        const consoleName = value;
        let identifier = '';

        if (value) {
            identifier = IdentifierFunctions.kebabize(value)
                .substring(0, IdentifierFunctions.IDENTIFIER_MAX_LENGTH);
        }

        if (!this.control.get(ViewControlKeys.ConsoleName)?.dirty) {
            this.control.get(ViewControlKeys.ConsoleName)?.setValue(consoleName, { onlySelf: false, emitResult: true });
        }

        if (!this.control.get(ViewControlKeys.Identifier)?.dirty) {
            this.control.get(ViewControlKeys.Identifier)?.setValue(identifier, { onlySelf: false, emitResult: true });
        }
    };

    private valueChange(skipEdit?: boolean) {

        /**
         * This is backwards submit should validate form then get errors
         */
        const errors = Object.keys(this.control.controls).map((key) => {
            const control = this.control.get(key) as UfControl;

            if (control.errors == null) {
                return null;
            }

            return control.errors.message;
        }).filter((err) => err != null).map((e) => e.message);

        this.builderService.setErrors(this.builderService.definition, errors, 'all');

        if (!skipEdit) {
            this.builderService.fieldEdit.next({ subject: this.builderService.definition, atomic: false });
        }
    }

}
