import { Injectable, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { hasLengthAtLeast } from '@unifii/sdk';
import { Subscription, filter } from 'rxjs';

/**
 * @description
 * This service helps grandparent components knowing when their grandchildren is destroyed
 * so they can do things like update the browser tab, fix breadcrumbs, etc.
 * it's specific to grandchildren, because there's specific situations when we have a tabs page, where the
 * tab page also has a tab or table, when you enter the third level and close it, we need to let the grandparent know it has been destroyed
 * IE: General -> Apps -> App Detail page
 */
@Injectable()
export class OnGrandChildNavigationEndService implements OnDestroy {

    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private subscriptions = new Subscription();

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    /**
     * @description
     * This method registers a callback which will be called when a component's children is destroyed
     */
    register(callback: () => void) {
        this.subscriptions.add(this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
        ).subscribe(() => {
            if (!this.hasGrandChildrenActive()) {
                callback();
            }
        }));

        // if the component calling it doesn't have a grandchildren active, it updates the title
        if (!this.hasGrandChildrenActive()) {
            callback();
        }
    }

    /**
     * @description
     * This method check if the grandchildren component is still active
     */
    private hasGrandChildrenActive() {
        return Array.isArray(this.route.children) && hasLengthAtLeast(this.route.children, 1) && hasLengthAtLeast(this.route.children[0].children, 1);
    }

}
