"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.amendUserAddStatusRemoveIsActiveUNIFII7598 = exports.mapClaimsToClaimsRecordUNIFII7599 = void 0;
const content_types_1 = require("../content-types");
const models_1 = require("../models");
const data_type_functions_1 = require("./data-type-functions");
const type_guard_functions_1 = require("./type-guard-functions");
const users_functions_1 = require("./users-functions");
/**
 * v0 UserInfo claims is an array of Claim.
 * At runtime they need to be transformed to a record in order to be compatible with the expression syntax.
 * This function will be removed once BE has implemented the conversion for v0/users APIs
 * @deprecated
 */
const mapClaimsToClaimsRecordUNIFII7599 = (claims, claimsInfo) => {
    const record = (0, users_functions_1.mapClaimsToClaimsRecord)(claims);
    for (const [identifier, value] of Object.entries(record)) {
        if (Array.isArray(value)) {
            continue;
        }
        const claimInfo = claimsInfo[`claims.${identifier}`];
        switch (claimInfo?.type) {
            case content_types_1.FieldType.Bool:
                record[identifier] = (0, data_type_functions_1.coerceDataToTarget)(value, { type: models_1.DataType.Boolean }) ?? value;
                break;
            case content_types_1.FieldType.Number:
                record[identifier] = (0, data_type_functions_1.coerceDataToTarget)(value, { type: models_1.DataType.Number }) ?? value;
                break;
            case content_types_1.FieldType.MultiChoice:
            case content_types_1.FieldType.TextArray:
                record[identifier] = [`${value}`];
                break;
        }
    }
    return record;
};
exports.mapClaimsToClaimsRecordUNIFII7599 = mapClaimsToClaimsRecordUNIFII7599;
/**
 * Currently UserInfo "status" is a projection of "isActive" and "lastActivationReason"
 * This function amend the UserInfo object by adding the projected status as attribute
 * This function will be removed once BE has implemented the projection for v0/users APIs
 * @deprecated
 */
const amendUserAddStatusRemoveIsActiveUNIFII7598 = (user) => {
    if (!(0, type_guard_functions_1.isDictionary)(user)) {
        return;
    }
    if (user.status == null && (0, type_guard_functions_1.isBoolean)(user.isActive) && (0, type_guard_functions_1.isOptionalType)(user.lastActivationReason, type_guard_functions_1.isString)) {
        user.status = (0, users_functions_1.getUserStatus)({ isActive: user.isActive, lastActivationReason: user.lastActivationReason });
        delete user.isActive;
    }
};
exports.amendUserAddStatusRemoveIsActiveUNIFII7598 = amendUserAddStatusRemoveIsActiveUNIFII7598;
