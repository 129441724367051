import { TableDataSource } from '@unifii/library/common';

import { AppInfo, UcApps } from 'client';

export class AppsDatasource extends TableDataSource<AppInfo> {

    filtered = false;
    sorted = false;

    constructor(private ucApps: UcApps) {
        super();
    }

    load() {
        this.ucApps.get().then((apps) => {
            this.stream.next({ data: apps });
        }, (error) => {
            this.stream.next({ error });
        });
    }

}
