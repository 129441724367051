<div *ngIf="form" class="uf-box gap">
    <div class="uf-app-bar flat accent">
        <div class="title">
            Field Mapping
        </div>
    </div>
    <table class="uf-table inputs">
        <thead>
            <tr>
                <th class="table-col-3">Unifii User Field</th>
                <th>Provider's User Field</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let key of userMappingsKeys; let i = index">
                <td>{{key}}</td>
                <td class="input-cell">
                    <uf-autocomplete *ngIf="fieldMappings.get(key); let control" [control]="control | asUfControl"
                        [options]="allMappingFieldsOptions[key]" [allowCustom]="allowCustom(key)"
                        (searchChange)="findFieldMapping(key, $event)" placeholder="Select a source"
                        nameProperty="display" class="table-cell" />

                </td>
            </tr>
        </tbody>
    </table>
    <uf-error [control]="fieldMappings" />
</div>

<div class="uf-box gap">
    <div class="uf-app-bar accent flat">
        <div class="title">
            Data Mapping
        </div>
    </div>

    <uf-expander [dragDisabled]="true" [isExpanded]="false" dragItem>
        <div expanderHeader class="uf-app-bar flat">
            <div class="title">
                Filters
            </div>
        </div>
        <div expanderBody>
            <uc-auth-provider-mapping-filters *ngIf="filters" [filters]="filters" [customGroups]="customGroups"
                [sourceClaims]="sourceClaims" [type]="type" />
        </div>
    </uf-expander>

    <table class="uf-table inputs clickable">
        <thead>
            <tr>
                <th>Provider Conditions</th>
                <th>Unifii User Actions</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of filteredRows; let i = index" (click)="rowClick(row)" class="body-row">
                <td>{{row.conditionDescription}}</td>
                <td>{{row.actionDescription}}</td>
                <td class="input-cell">
                    <button (click)="$event.stopPropagation(); deleteMapping(row)" type="button"
                        class="uf-action tertiary right" title="Delete">
                        <uf-icon name="delete" />
                    </button>
                </td>
            </tr>
        </tbody>
    </table>

    <button (click)="addProviderValue()" type="button" class="uf-button right gap-top gap-right gap-bottom">
        Add Mapping
    </button>

    <ng-template [ngIf]="filteredRows.length === 0">
        <div class="col center-all pad">
            <uf-icon name="big_empty" class="x-large accent" />
            <h3 class="gap-none-bottom gap-sm-top">{{ commonTK.TableFeedbackDataFilteredEmptyTitle | translate }}
            </h3>
            <ng-template [ngIf]="rows.length === 0" [ngIfElse]="filterEmpty">
                <p class="gap-sm-top">{{commonTK.TableFeedbackDataEmptyDescription | translate}}</p>
            </ng-template>
            <ng-template #filterEmpty>
                <p class="gap-sm-top">{{commonTK.TableFeedbackDataFilteredEmptyDescription | translate}}</p>
            </ng-template>
        </div>
    </ng-template>

</div>