import { Injectable, Type } from '@angular/core';
import { FilterEntry, IFilterComponent, UfFilterComponentRegistry } from '@unifii/library/common';

import { CustomChoiceFilterComponent } from 'components';

@Injectable()
export class MediaFilterRegistry extends UfFilterComponentRegistry {

    override get(entry: FilterEntry): Type<IFilterComponent> | undefined {
        if (entry.identifier === 'pending') {
            return CustomChoiceFilterComponent;
        }

        return super.get(entry);
    }

}
