import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { ExpandersService, MessageLevel, ModalService, RuntimeDefinition, RuntimeDefinitionAdapter, ToastService } from '@unifii/library/common';
import { DisplayService } from '@unifii/library/smart-forms/display';
import { Compound, CompoundType, Definition, Field, FieldType, UfError, ensureUfRequestError } from '@unifii/sdk';
import { Subject, debounceTime } from 'rxjs';

import { BuilderField, CompoundInfo, SystemRole, UcDefinition, UcProject, UcView } from 'client';
import { ContentSettings, EditMode, LinkSearchComponent, LinkSearchConfig, LinkSearchType, ModalSearchData, SaveOption, SaveOptionType, useDefaultErrorMessage } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { BuilderBasic } from 'components/compound-builder/builder-basic';
import { BuilderCompoundSubjects } from 'components/compound-builder/builder-models';
import { BuilderEventInfo, BuilderService } from 'components/compound-builder/builder.service';
import { FieldDetailHelper, IdentifierFunctions } from 'helpers/helpers';
import { ViewsComponent } from 'pages/content/views/views.component';
import { appendSuffixCopy, cleanDefinitionToBeDuplicated } from 'pages/utils';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';
import { LimitService } from 'services/limit.service';
import { UcTableManager } from 'services/table/models';
import { TitleService } from 'services/title.service';

@Component({
    templateUrl: './view-builder.html',
    styleUrls: ['./../../../styles/pages/builder.less'],
    providers: [
        BuilderService,
        ExpandersService,
        {
            provide: ContentSettings,
            useFactory: (context: ContextService) => ({
                canEditContent: true,
                canEditDefinition: context.checkRoles(SystemRole.ProjectManager),
            }),
            deps: [ContextService],
        },
    ],
})
export class ViewBuilderComponent extends BuilderBasic implements OnInit, OnDestroy {

    type = CompoundType.View;
    subject = BuilderCompoundSubjects.DEFINITION;

    protected ready: boolean;
    protected definition: Definition;
    protected displayDefinition?: RuntimeDefinition;
    protected displayCompound?: Compound;
    protected selectedField?: BuilderField;
    protected error?: UfError;

    private lastInserted: BuilderField | null;
    private _compound: UcView;
    private compoundChanged = new Subject<UcView>();

    constructor(
        builderService: BuilderService,
        modalService: ModalService,
        protected parent: ViewsComponent,
        protected override router: Router,
        protected override route: ActivatedRoute,
        private ucProject: UcProject,
        private toastService: ToastService,
        private displayService: DisplayService,
        private limitService: LimitService,
        @Inject(ContentSettings) public contentSettings: ContentSettings,
        private breadcrumbService: BreadcrumbService,
        private builderHeaderService: BuilderHeaderService,
        private runtimeDefinitionAdapter: RuntimeDefinitionAdapter,
        @Optional() @Inject(TableContainerManager) protected override tableManager: UcTableManager<CompoundInfo> | null,
        private titleService: TitleService,
    ) {
        super(builderService, modalService, route, tableManager);
    }

    async ngOnInit() {
        let view: UcView;

        try {
            this.builderHeaderService.init();
            this.subscriptions.add(this.approveClicked.subscribe((id) => void this.approve(id)));

            // Load data
            view = await this.load(this.editMode, this.route.snapshot.params);
        } catch (e) {
            this.error = useDefaultErrorMessage(e);

            return;
        }

        // Copy console name inside the compound (for UI purpose)
        this.compound = view;
        this.definition = view._definition as Definition;

        // Register event handlers
        this.addSubscribers();

        // Init builder service
        this.builderService.init(this, this.definition, this.compound);

        this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe((saveOption) => void this.save(saveOption)));
        this.buildHeaderConfig(view);
    }

    protected set compound(v: UcView) {
        if (v === this._compound) {
            return;
        }

        this._compound = v;
        this.compoundChanged.next(this.compound);
    }

    protected get compound(): UcView {
        return this._compound;
    }

    override selectField(i: BuilderEventInfo = { subject: null, atomic: false }) {
        this.selectedField = !i ? null : i.subject;
        this.builderService.fieldSelected.next(i);
    }

    override removeField(i: BuilderEventInfo) {
        const position = this.getFieldPosition(i.subject);

        if (position) {
            position.parent[this.builderService.childrenProperty].splice(position.index, 1);
            this.builderService.fieldRemoved.next(i);
        }
    }

    protected fieldBuilderChange(event: BuilderEventInfo) {
        if (event.source === 'nested') {
            this.refreshNgForElement();
        }
    }

    // DragList START
    protected canDrop = async(element: any): Promise<boolean> => {

        if (!element.type || (element.type && (FieldType as any)[element.type] == null)) {
            // Not a field (element from right panel like Validator, Option....)
            return false;
        }

        if (!element.reference) {
            // An actual field and not it's reference from the left tools list
            return true;
        }

        if (element.type === FieldType.LinkList) {
            // LinkList (select a single collection)
            const result = await this.modalService.openMedium(LinkSearchComponent, {
                title: 'Select Collection',
                multiSelect: false,
                minQuantitySelected: 1,
                ucProject: this.ucProject,
                type: LinkSearchType.Collection,
            } as LinkSearchConfig);

            if (!result?.length) {
                return false;
            }

            element.field = {
                label: result[0].name,
                type: element.type,
                compoundType: CompoundType.Collection,
                definitionIdentifier: result[0].identifier,
            };

            return true;

        }

        if (element.type === FieldType.Link && element.compoundType === CompoundType.Collection) {

            const data: LinkSearchConfig = {
                title: 'Select Collections',
                multiSelect: true,
                ucProject: this.ucProject,
                type: LinkSearchType.Collection,
            };

            // Link of type Collection (select a list of collections)
            const result = await this.modalService.openMedium<ModalSearchData, any[]>(LinkSearchComponent, data);

            if (!result) {
                return false;
            }

            element.field = {
                label: element.label,
                type: element.type,
                compoundType: CompoundType.Collection,
            };

            if (result.length) {
                element.field.types = result.map((c) => c.identifier);
            }

            return true;

        }

        if ([FieldType.Text, FieldType.MultiText].includes(element.type)) {
            element.field = {
                label: element.label,
                type: element.type,
                isTranslatable: true,
            };

            return true;
        }

        // No dialog needed
        return true;
    };

    protected convertFieldRef(ref: any) {
        if (ref.field) {
            return ref.field;
        }

        const res: any = { type: ref.type, label: ref.label };

        if (ref.compoundType) {
            res.compoundType = ref.compoundType;
        }

        return res;
    }

    protected inserted(field: BuilderField) {
        // Use service to generate a unique identifier
        field.isNew = true;

        field.identifier = IdentifierFunctions.safeFieldIdentifier(
            field,
            this.builderService.definition.fields,
            field.definitionIdentifier ?? field.type,
        );

        this.lastInserted = field;
        const parent = this.builderService.builder.getFieldPosition(field)?.parent as Field | undefined;
        const fm = FieldDetailHelper.getMetadata(field, this.builderService.builder.type, parent);

        if (fm.hasArrayValues === true) {
            this.compound[field.identifier] = [];
        }

        /** *
         * Set default values for complex inputs otherwise they will display as blank
         * as empty visible fields is a valid setting
         */
        if (field.type === FieldType.Address) {
            field.visibleFields = ['address1', 'address2', 'suburb', 'state', 'postcode', 'map'];
        }

        if (field.type === FieldType.GeoLocation) {
            field.visibleFields = ['lat', 'lng', 'map'];
        }

        // Notify field inserted
        this.builderService.fieldAdded.next({ subject: field, atomic: true });
    }

    protected moved(field: BuilderField) {
        this.builderService.fieldMoved.next({ subject: field, atomic: true });
    }

    private async load(mode: EditMode, params: Params) {

        if (mode === EditMode.New) {
            return {
                _consoleName: '',
                _definition: {
                    compoundType: CompoundType.View,
                    identifier: '',
                    label: '',
                    _consoleName: '',
                    version: 0,
                    fields: [],
                },
            };
        }

        const view = await this.ucProject.getView(+params.id);

        if (mode !== EditMode.Duplicate) {
            return view;
        }

        const duplicatedView = cleanDefinitionToBeDuplicated(view) as UcView;

        delete view._definition.id;

        view._title = appendSuffixCopy({ label: view._title });
        view._consoleName = appendSuffixCopy({ label: view._consoleName });
        view._definition.identifier = appendSuffixCopy({ label: view._definition.identifier, noWhiteSpace: true });
        view._definition.label = '';
        view._definition.version = 0;
        view._definition.lastModifiedAt = undefined;
        view._definition.lastPublishedAt = undefined;

        return duplicatedView;
    }

    private addSubscribers() {

        // React to changes and prepare definition and compound for display
        this.subscriptions.add(this.compoundChanged.pipe(debounceTime(500)).subscribe(() => void this.updatePreview()));

        // React to builderService ready
        this.subscriptions.add(this.builderService.ready.subscribe(() => {
            this.saveStatus();
            this.builderService.memento.edited = false;
            this.ready = true;
            this.builderService.fieldSelect.next(null);
        }));

        this.subscriptions.add(this.builderService.fieldSelected.subscribe(() => {
            this.compoundChanged.next(this.compound);
            this.builderService.memento.setLastAtomic();
        }));

        this.subscriptions.add(this.builderService.fieldAdded.subscribe((i) => {
            this.selectField(i);
            this.saveStatus(i);
        }));

        this.subscriptions.add(this.builderService.fieldMoved.subscribe((i) => {
            this.saveStatus(i);
        }));

        this.subscriptions.add(this.builderService.fieldRemoved.subscribe((i) => {
            this.builderService.fieldSelect.next(null);
            this.saveStatus(i);
        }));

        this.subscriptions.add(this.builderService.fieldEdit.subscribe((i) => {
            this.saveStatus(i);
            this.builderService.fieldEdited.next(i);
        }));

        this.subscriptions.add(this.builderService.fieldReady.subscribe((i) => {
            if (this.lastInserted === i.subject) {
                this.selectField(i);
                this.lastInserted = null;
            }
        }));
    }

    private async save(saveOption?: SaveOption) {
        // Notify the other components of a builder save/submit action
        this.builderService.submit.next(null);

        // Validate builder status
        if (!this.builderService.isValid()) {
            this.builderService.markFieldsSubmitted();
            this.builderHeaderService.notify.next({ level: MessageLevel.Error, title: 'Error', message: 'Unable to save. There are errors in your View.' });

            return;
        }

        const view = this.compound;
        const definition = this.builderService.cleanDefinition(this.definition as UcDefinition);

        view._assetRefs = this.builderService.getAssetsInView(view);

        definition.label = view._title ?? '';

        // Copy definition inside view
        view._definition = definition;

        try {
            // Save the view
            this.builderService.busy.next(true);
            this.compound = await this.ucProject.saveView(view);

            // Notify user of the save success
            this.toastService.success('View saved!');

            if (saveOption?.id === SaveOptionType.New &&
                !(await this.limitService.canAddViews())) {
                saveOption = undefined;
            }

            this.saved(this.compound, saveOption);
            this.builderService.init(this, this.definition, this.compound);

            this.buildHeaderConfig(this.compound);
        } catch (error) {
            const message =
                (error as any).message ??
                (error as any).data?.message ??
                'Oops... something went wrong with saving your form';

            this.builderHeaderService.notify.next({ level: MessageLevel.Error, title: 'Error', message });

        } finally {
            this.builderService.busy.next(false);
        }
    }

    /**
     * Temporary fix for fields with nested fields, by design only some
     * inputs on field inputs react to change, fields with nested visible fields
     * will only render once
     */
    private refreshNgForElement() {
        if (!this.selectedField) {
            return;
        }

        const index = this.definition.fields.findIndex((f) => f === this.selectedField);

        if (index < 0) {
            return;
        }

        this.definition.fields.splice(index, 1);

        setTimeout(() => {
            (this.definition.fields as Field[]).splice(index, 0, this.selectedField as Field);
        }, 0);
    }

    private async updatePreview() {

        /**
         * No need for an async call if definition doesn't contain a LinkList
         * note - Links need to be converted to Compounds before rendered
         */
        if (this.definition.fields.find((field) => field.type === FieldType.LinkList) == null) {
            this.displayDefinition = await this.runtimeDefinitionAdapter.transform(this.definition);
            this.displayCompound = this.compound;

            return;
        }

        const displayContent = await this.displayService.renderCompound(this.definition as Definition, this.compound);

        this.displayDefinition = displayContent.definition;
        this.displayCompound = displayContent.compound;
    }

    private saveStatus(i: BuilderEventInfo = { subject: null, atomic: true }) {
        this.builderService.memento.save(this.builderService.definition, i.atomic);
        this.builderService.memento.edited = true;
        // Force preview panel refresh
        this.compoundChanged.next(this.compound);

        if (this.ready) {
            this.builderHeaderService.config.edited = true;
        }
    }

    private buildHeaderConfig(view: UcView) {
        const title = this.mapContentItemName(view._consoleName);

        this.builderHeaderService.buildConfig({
            ...view,
            title: view._title,
            cancelRoute: ['../'],
            saveOptions: this.saveOptions,
            publishState: view.publishState,
            restrictSave: 'ProjectManager,ContentEditor',
            breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route, [title]),
        });

        this.titleService.updateTitle(title);
    }

    private async approve(id?: number) {
        if (!id) {
            return;
        }
        try {
            const view = await this.ucProject.approveView(id);

            this.buildHeaderConfig(view);
            // it's using any here, because it's the same type,
            // the method save in builder-basic uses. otherwise we would have to create a mapping function
            this.tableManager?.updateItem.next(view as unknown as CompoundInfo);
            this.back();
        } catch (e) {
            const ufError = ensureUfRequestError(e, 'Error approving view');

            this.toastService.error(ufError.message);
        }
    }

}
