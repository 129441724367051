import { Component, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { CompoundType } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { BuilderEventInfo, BuilderService } from './builder.service';

@Component({
    selector: 'uc-builder-fields-header',
    templateUrl: './builder-fields-header.html',
    styleUrls: ['./builder-fields-header.less'],
})
export class BuilderFieldsHeaderComponent implements OnInit, OnDestroy {

    @HostBinding('class.builder-fields-header') headerClass = true;
    ready = false;
    isSelected = true;
    isValid = true;

    private subscriptions = new Subscription();
    private builderService = inject(BuilderService);

    ngOnInit() {

        this.subscriptions.add(this.builderService.ready.subscribe(() => (this.ready = true)));

        this.subscriptions.add(this.builderService.fieldSelected.subscribe((i: BuilderEventInfo = { subject: null }) => {

            this.isSelected = i.subject == null;
            // Validate if user selected a field without filling in all required metadata
            this.isValid = this.builderService.isValid(this.builderService.definition);
        }));

        this.subscriptions.add(this.builderService.fieldEdited.subscribe((i) =>
            this.refreshErrorsStatus(i),
        ));

        this.subscriptions.add(this.builderService.fieldRefreshed.subscribe((i) =>
            this.refreshErrorsStatus(i),
        ));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    get title() {
        if (this.builderService.definition) {
            switch (this.builderService.builder.type) {
                case CompoundType.Page: return this.builderService.definition._title || 'Untitled';
                case CompoundType.Form: return this.builderService.definition.label || 'Untitled';
                case CompoundType.Collection: return this.builderService.definition.label || 'Untitled';
                case CompoundType.View: return this.builderService.compound._title || 'Untitled';
                default: return this.builderService.definition.label || 'Untitled';
            }
        }
    }

    onSelect() {
        this.builderService.fieldSelect.next(null);
    }

    private isMe(builderEvent: BuilderEventInfo = { subject: null }): boolean {
        return builderEvent.subject != null && builderEvent.subject === this.builderService.definition;
    }

    private refreshErrorsStatus(builderEvent: BuilderEventInfo) {

        if (this.isMe(builderEvent)) {
            // console.log('BuilderEventInfo update errors status');
            this.isValid = this.builderService.isValid(this.builderService.definition);
        }
    }

}
