import { Dictionary, StructureNodeVariation, TableDetailTemplate } from '@unifii/sdk';

import { UcStructure, UcStructureNode } from 'client';

export interface StructureNodeArgControlValue {
    key: string;
    value: { value: string | null; isExpression: boolean };
}

export interface StructureEditorStructureNodeVariation extends StructureNodeVariation {
    template?: `${TableDetailTemplate}`;
}

export interface StructureEditorStructureNode extends Omit<UcStructureNode, 'args' | 'children'> {
    children: StructureEditorStructureNode[];
    args: StructureNodeArgControlValue[];
    template?: `${TableDetailTemplate}`;
}

export interface StructureEditorStructure extends Omit<UcStructure, 'args' | 'children' | 'variations'> {
    children: StructureEditorStructureNode[];
    args: StructureNodeArgControlValue[];
    template?: `${TableDetailTemplate}`;
    variations: StructureNodeVariation[];
    isHidden: false;
}

export type StructureEditorNodeOrRoot = StructureEditorStructure | StructureEditorStructureNode;

export const isStructureEditorNodeOrRoot = (node: StructureEditorNodeOrRoot | StructureNodeVariation): node is StructureEditorNodeOrRoot =>
    (node as unknown as Dictionary<unknown>).children != null;
