import { ChangeDetectorRef, Component, Input, OnInit, inject } from '@angular/core';
import { CommonTranslationKey, UfControl, UfControlGroup } from '@unifii/library/common';
import { UserKeys, UserMfaInfoKeys } from '@unifii/user-provisioning';

@Component({
    selector: 'uc-user-mfa',
    templateUrl: 'user-mfa.html',
})
export class UserMfaComponent implements OnInit {

    @Input({ required: true }) form: UfControlGroup;
    @Input() cssClasses: string | string[] | undefined | null;

    protected readonly commonTK = CommonTranslationKey;
    protected readonly userMfaInfoKeys = UserMfaInfoKeys;
    protected isMfaOptionalControl?: UfControl;
    protected mfaControl?: UfControlGroup;

    private cdr = inject(ChangeDetectorRef);

    ngOnInit() {
        this.isMfaOptionalControl = this.form.get(UserKeys.IsMfaOptional) as UfControl | undefined;
        this.mfaControl = this.form.get(UserKeys.Mfa) as UfControlGroup | undefined;
    }

    // value change occurs after async call
    protected refresh() {
        this.cdr.detectChanges();
    }

}
