"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentClient = exports.PublishedContent = void 0;
const client_types_1 = require("../client-types");
const constants_1 = require("../constants");
const functions_1 = require("../functions");
/** InjectionToken like */
// eslint-disable-next-line @typescript-eslint/no-extraneous-class, @typescript-eslint/no-unsafe-declaration-merging
class PublishedContent {
}
exports.PublishedContent = PublishedContent;
class ContentClient {
    constructor(client, tenantClient, options) {
        this.client = client;
        this.tenantClient = tenantClient;
        this.options = options;
        if (this.options.version) {
            this.contentVersionHeader = new Headers({ [client_types_1.HeaderKeys.XUnifiiContentVersion]: this.options.version });
        }
    }
    // PublishedContent implementation START
    getView(identifier, options) {
        return this.client.get(this.url(constants_1.APIPath.Views, identifier), this.mergeOptions(options));
    }
    getViewDefinition(identifier, options) {
        return this.client.get(this.url(constants_1.APIPath.Views, identifier, constants_1.APIPath.ViewsDefinition), this.mergeOptions(options));
    }
    getPage(identifier, options) {
        return this.client.get(this.url(constants_1.APIPath.Pages, identifier), this.mergeOptions(options));
    }
    getCollectionDefinition(identifier, options) {
        return this.client.get(this.url(constants_1.APIPath.Collections, identifier, constants_1.APIPath.CollectionsDefinition), this.mergeOptions(options));
    }
    getCollections(options) {
        return this.client.get(this.url(constants_1.APIPath.Collections), this.mergeOptions(options));
    }
    queryCollection(identifier, query, options) {
        return this.client.get(this.url(constants_1.APIPath.Collections, identifier), this.mergeOptions(options, query));
    }
    getCollectionItem(identifier, id, options) {
        return this.client.get(this.url(constants_1.APIPath.Collections, identifier, id), this.mergeOptions(options));
    }
    getBucket(identifier, options) {
        return this.client.get(this.url(constants_1.APIPath.Buckets, identifier), this.mergeOptions(options));
    }
    queryForms(query, options) {
        return this.client.get(this.url(constants_1.APIPath.Forms), this.mergeOptions(options, query));
    }
    getForm(identifier, version, options) {
        let parts = [identifier];
        // only add versions for prod
        if (!this.options.preview && version != null) {
            parts = parts.concat(constants_1.APIPath.FormsVersions, `${version}`);
        }
        return this.client.get(this.url(constants_1.APIPath.Forms, ...parts), this.mergeOptions(options));
    }
    queryTables(query, options) {
        return this.client.get(this.url(constants_1.APIPath.Tables), this.mergeOptions(options, query));
    }
    getTable(identifier, options) {
        return this.client.get(this.url(constants_1.APIPath.Tables, identifier), this.mergeOptions(options));
    }
    getStructure(options) {
        return this.client.get(this.url(constants_1.APIPath.Structure), this.mergeOptions(options));
    }
    getIdentifiers(options) {
        return this.client.get(this.url(constants_1.APIPath.Identifiers), this.mergeOptions(options));
    }
    async getAssetUrl(id) {
        const { url } = await this.tenantClient.getAsset(id);
        return url;
    }
    queryPages(query, options) {
        return this.client.get(this.url(constants_1.APIPath.Pages), this.mergeOptions(options, query));
    }
    // PublishedContent implementation END
    createVersionedContent(version) {
        return new ContentClient(this.client, this.tenantClient, {
            preview: this.options.preview,
            projectId: this.options.projectId,
            version,
        });
    }
    queryViews(query, options) {
        return this.client.get(this.url(constants_1.APIPath.Views), this.mergeOptions(options, query));
    }
    async getFormVersions(identifier, options) {
        if (this.options.preview) {
            // Preview do not support versions history
            return [await this.getForm(identifier, undefined, options)];
        }
        return this.client.get(this.url(constants_1.APIPath.Forms, identifier, constants_1.APIPath.FormsVersions), this.mergeOptions(options));
    }
    getVersion(version, options) {
        return this.client.get(this.versionsUrl(version), this.mergeOptions(options));
    }
    getLatestVersion(artifact, options) {
        const params = { preview: this.options.preview, artifact };
        return this.client.get(this.versionsUrl(constants_1.APIPath.Latest), this.mergeOptions(options, undefined, params));
    }
    getArtifactUrl(version, artifact) {
        return this.versionsUrl(version, constants_1.APIPath.Artifacts, artifact);
    }
    getArtifactPatchUrl(version, artifact, fromVersion) {
        return `${this.versionsUrl(version, constants_1.APIPath.Artifacts, artifact, constants_1.APIPath.Diff)}?${constants_1.QueryParams.Form}=${encodeURI(fromVersion)}`;
    }
    buildImageUrl(imageProfile, options) {
        return (0, functions_1.buildImageUrl)(imageProfile, options);
    }
    mergeOptions(options, query, params) {
        return {
            ...options,
            headers: (0, functions_1.mergeHeaders)(options?.headers, this.contentVersionHeader),
            query: (0, functions_1.stringifyQuery)(query),
            params: { ...options?.params, ...params },
        };
    }
    versionsUrl(...extra) {
        return this.client.buildUrl(constants_1.APIPath.Projects, this.options.projectId, constants_1.APIPath.Versions, ...extra);
    }
    url(...extra) {
        const projectParts = [constants_1.APIPath.Projects, this.options.projectId];
        if (this.options.preview) {
            projectParts.push(constants_1.APIPath.Preview);
        }
        return this.client.buildUrl(...projectParts, ...extra);
    }
}
exports.ContentClient = ContentClient;
