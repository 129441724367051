"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildImageUrl = exports.ImageMode = void 0;
const constants_1 = require("../constants");
const type_guard_functions_1 = require("./type-guard-functions");
var ImageMode;
(function (ImageMode) {
    ImageMode["Cover"] = "cover";
    ImageMode["Contain"] = "contain";
    ImageMode["Fill"] = "fill";
    ImageMode["Inside"] = "inside";
    ImageMode["Outside"] = "outside";
})(ImageMode || (exports.ImageMode = ImageMode = {}));
/** default image url builder for use in console and discover */
const buildImageUrl = (imageProfile, options) => {
    if (!imageProfile.url) {
        return;
    }
    const url = new URL(imageProfile.url);
    if (imageProfile.crop) {
        url.searchParams.set(constants_1.QueryParams.ImageCrop, `${imageProfile.crop.x}, ${imageProfile.crop.y}, ${imageProfile.crop.x + imageProfile.crop.width}, ${imageProfile.crop.y + imageProfile.crop.height}`);
    }
    if (options?.width && options.height === undefined) {
        const bracketedWidth = Math.ceil(options.width / 256) * 256;
        url.searchParams.set(constants_1.QueryParams.ImageWidth, bracketedWidth.toString());
    }
    if (options?.height && options.width === undefined) {
        const bracketedHeight = Math.ceil(options.height / 256) * 256;
        url.searchParams.set(constants_1.QueryParams.ImageHeight, bracketedHeight.toString());
    }
    if (options?.width && options.height) {
        url.searchParams.set(constants_1.QueryParams.ImageWidth, options.width.toString());
        url.searchParams.set(constants_1.QueryParams.ImageHeight, options.height.toString());
    }
    if (isImageOptionsWithMode(options)) {
        url.searchParams.set(constants_1.QueryParams.ImageMode, options.mode.toString());
    }
    return url.toString();
};
exports.buildImageUrl = buildImageUrl;
const isImageOptionsWithMode = (data) => (0, type_guard_functions_1.isDictionary)(data) &&
    Object.keys(data).length === 3 &&
    (0, type_guard_functions_1.isNumber)(data.width) &&
    (0, type_guard_functions_1.isNumber)(data.height) &&
    (0, type_guard_functions_1.isValueOfStringEnumType)(ImageMode)(data.mode);
