<div *ngIf="!error" class="header-pane uf-app-bar transparent">
    <uc-builder-header />
</div>

<uf-panel *ngIf="!error" class="content-pane">
    <div class="uf-container-lg">
        <div class="pad">

            <uf-skeleton *ngIf="!root" class="gap-none" />

            <div *ngIf="root" [formGroup]="root" class="uf-grid">
                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">
                            Details
                        </div>
                    </div>
                    <div class="uf-grid pad">
                        <uf-text [formControlName]="controlKeys.ConsoleName" [label]="consoleNameLabel" class="col-12">
                            <span class="suffix">*</span>
                        </uf-text>
                    </div>
                </div>

                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">
                            Event
                        </div>
                    </div>
                    <div class="uf-grid pad">
                        <uf-select [formControlName]="controlKeys.EventType" [options]="eventOptions"
                            (valueChange)="eventChange($event)" label="Event" valueProperty="_id"
                            nameProperty="_display" class="col-12">
                            <span class="suffix">*</span>
                        </uf-select>
                        <ng-container [formGroup]="condition">
                            <uf-autocomplete *ngIf="eventType?.value === eventTypes.FormSubmitted"
                                [formControlName]="controlKeys.Bucket" [options]="buckets"
                                (searchChange)="searchBuckets($event)" (valueChange)="bucketChange($event)"
                                label="Form Data Repository" class="col-12">
                                <span class="suffix">*</span>
                            </uf-autocomplete>
                            <uf-message *ngIf="bucketError" [content]="bucketError.message" icon="error"
                                class="error col-12" />
                            <uf-chips *ngIf="transitions?.enabled" [formControlName]="controlKeys.Transitions"
                                [options]="filteredTransitions" (searchChange)="searchTransitions($event)"
                                label="Workflow Transition" nameProperty="description" class="col-12">
                                <span class="suffix">*</span>
                            </uf-chips>
                        </ng-container>
                        <uf-autocomplete *ngIf="timer?.enabled" [formControlName]="controlKeys.Timer"
                            [options]="filteredTimers" (searchChange)="searchTimer($event)" label="Timer"
                            nameProperty="consoleName" class="col-12">
                            <span class="suffix">*</span>
                        </uf-autocomplete>
                        <uf-autocomplete *ngIf="integration?.enabled" [formControlName]="controlKeys.Integration"
                            [options]="integrations" (searchChange)="searchIntegrations($event)"
                            (valueChange)="integrationChange($event)" label="Integration" nameProperty="name"
                            class="col-12">
                            <span class="suffix">*</span>
                        </uf-autocomplete>
                        <uf-autocomplete *ngIf="feature?.enabled" [formControlName]="controlKeys.Feature"
                            [options]="filteredFeatures" (searchChange)="searchFeatures($event)" label="Feature"
                            nameProperty="name" class="col-12">
                            <span class="suffix">*</span>
                        </uf-autocomplete>
                        <ng-container *ngIf="condition?.get(controlKeys.Roles)?.enabled" [formGroup]="condition">
                            <uf-chips [options]="filteredRoles" [formControlName]="controlKeys.Roles"
                                (valueChange)="searchRoles('')" (searchChange)="searchRoles($event)" class="col-12"
                                label="Roles" />
                        </ng-container>
                    </div>
                </div>

                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">
                            Condition
                        </div>
                    </div>
                    <div [formGroup]="condition" class="uf-grid pad">
                        <uf-textarea [formControlName]="controlKeys.Expression" label="Expression" class="col-12" />
                        <uf-chips *ngIf="forms.enabled && formOptions.length > 1" [formControlName]="controlKeys.Forms"
                            [options]="filteredFormOptions" (searchChange)="searchForms($event)" nameProperty="_display"
                            valueProperty="_id" label="Forms" class="col-12" />
                        <uf-checkbox *ngIf="condition?.get(controlKeys.Roles)?.enabled"
                            [formControlName]="controlKeys.IncludeExternal" label="Include External Users"
                            class="col-12" />
                        <uf-checkbox *ngIf="condition?.get(controlKeys.Roles)?.enabled"
                            [formControlName]="controlKeys.IncludeNewUser" label="Include New Users" class="col-12" />
                    </div>
                </div>

                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">
                            Activities
                        </div>
                    </div>

                    <div *ngFor="let activity of activities.controls | asUfControlsGroups; let i = index"
                        class="uf-box">
                        <uf-expander [isExpanded]="true">
                            <div expanderHeader class="uf-app-bar flat">
                                <div class="title">
                                    {{activity.get(controlKeys.Type)?.value}}
                                </div>
                                <button (click)="deleteActivity(i)" type="button" title="Delete" class="uf-action">
                                    <uf-icon name="delete" />
                                </button>
                            </div>
                            <div expanderBody class="uf-grid pad">
                                <ng-container [ngSwitch]="activity.get(controlKeys.Type)?.value">

                                    <ng-template [ngSwitchCase]="activityType.Timer">
                                        <uf-autocomplete *ngIf="activity.get(controlKeys.Activity) as activityControl"
                                            [control]="activityControl | asUfControl" [options]="filteredTimers"
                                            (searchChange)="searchTimers($event, activity.value)"
                                            (valueChange)="activityChange(activity, $event, i)" label="Timer"
                                            nameProperty="consoleName" valueProperty="id" placeholder="Select a Timer"
                                            class="col-11">
                                            <span class="suffix">*</span>
                                        </uf-autocomplete>
                                    </ng-template>

                                    <ng-template [ngSwitchCase]="activityType.Notification">
                                        <uf-autocomplete *ngIf="activity.get(controlKeys.Activity) as activityControl"
                                            [options]="filteredNotifications" [control]="activityControl | asUfControl"
                                            (searchChange)="searchNotifications($event, activity.value)"
                                            (valueChange)="activityChange(activity, $event, i)" label="Notification"
                                            nameProperty="consoleName" valueProperty="id"
                                            placeholder="Select a Notification" class="col-11">
                                            <span class="suffix">*</span>
                                        </uf-autocomplete>
                                    </ng-template>

                                    <ng-template [ngSwitchCase]="activityType.DataForwarder">
                                        <uf-autocomplete *ngIf="activity.get(controlKeys.Activity) as activityControl"
                                            [control]="activityControl | asUfControl" [options]="filteredDataForwarders"
                                            (searchChange)="searchDataForwarders($event, activity.value)"
                                            (valueChange)="activityChange(activity, $event, i)" label="Data Forwarder"
                                            nameProperty="consoleName" valueProperty="id"
                                            placeholder="Select a Data Forwarder" class="col-11">
                                            <span class="suffix">*</span>
                                        </uf-autocomplete>
                                    </ng-template>

                                    <ng-template [ngSwitchCase]="activityType.FormData">
                                        <uf-autocomplete *ngIf="activity.get(controlKeys.Activity) as activityControl"
                                            [control]="activityControl | asUfControl" [options]="filteredFormDatas"
                                            (searchChange)="searchFormData($event, activity.value)"
                                            (valueChange)="activityChange(activity, $event, i)" label="Form Data"
                                            nameProperty="consoleName" valueProperty="id"
                                            placeholder="Select a Form Data" class="col-11">
                                            <span class="suffix">*</span>
                                        </uf-autocomplete>
                                    </ng-template>

                                    <a *ngIf="activity.get(controlKeys.Id)?.value"
                                        [routerLink]="[openActivity(activity)]" target="_blank"
                                        class="uf-action tertiary col-1 center">
                                        <uf-icon name="open" />
                                    </a>

                                    <uf-data-display-list *ngIf="activityData[i]" [items]="activityData[i]"
                                        class="col-12 columns" />
                                </ng-container>
                            </div>
                        </uf-expander>
                    </div>
                    <div class="pad border-top">
                        <uf-error [control]="activities" />
                        <button [options]="activityOptions" [disabled]="activityOptions.length === 0"
                            (optionSelected)="addActivity($event);" listBox nameProperty="name" type="button"
                            class="uf-button right">
                            Add Activity
                            <uf-icon name="more" class="gap-none" />
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>

</uf-panel>

<uc-error-message [error]="error" />